<abp-modal [(visible)]="openReserveModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::ReserveAssets' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="reserve-modal-dates gap-3">
      <div class="d-flex mb-3 d-flex">
        <label for="fromNow" class="reserve-modal-label reserve-label">{{
          'RegisterService::StartingDate' | abpLocalization
        }}</label>
        <div class="d-flex align-items-center gap-2">
          <input
            role="button"
            id="fromNow"
            type="radio"
            [checked]="fromNowSelected"
            name="startDate"
            class="input-clickable"
            (change)="selectFromNow($event)"
          />
          <label for="fromNow" class="reserve-modal-label reserve-label">{{
            'RegisterService::FromNowOn' | abpLocalization
          }}</label>
        </div>
        <div class="d-flex align-items-center gap-2">
          <input
            role="button"
            id="dateFrom"
            type="radio"
            [checked]="dateFromVisable"
            name="startDate"
            class="input-clickable"
            (change)="selectDateFrom($event)"
          />
          <label for="dateFrom" class="reserve-modal-label reserve-label">{{
            'RegisterService::DateFrom' | abpLocalization
          }}</label>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 reserve-fields" *ngIf="dateFromVisable">
        <label class="form-label reserve-modal-label" for="date-from">
          {{ 'RegisterService::DateFrom' | abpLocalization }}
        </label>
        <div class="input-group date-selector" validationTarget>
          <input
            id="date-from"
            class="form-control date-inputs"
            readonly
            ngbDatepicker
            #dateFromInput
            #dateFromPicker="ngbDatepicker"
            (click)="dateFromPicker.open()"
            (keyup.space)="dateFromPicker.open()"
            (ngModelChange)="selectDateFrom()"
            (dateSelect)="changeExpectedReturnMinDate($event)"
            [(ngModel)]="dateFrom"
            [minDate]="today"
          />
          <div *ngIf="dateFromInput.value" class="input-group-append">
            <button
              class="btn btn-link px-2"
              type="button"
              [title]="'AbpUi::Clear' | abpLocalization"
              (click)="
                dateFromPicker.writeValue(null);
                dateFromPicker.manualDateChange(null, true);
                selectFromNow()
              "
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 reserve-fields">
        <label class="form-label reserve-modal-label" for="expected-return">
          {{ 'RegisterService::ExpectedReturn' | abpLocalization }}
          <app-is-required *ngIf="dateFromVisable"></app-is-required>
        </label>
        <div class="input-group date-selector" validationTarget>
          <input
            id="expected-return"
            class="form-control date-inputs"
            readonly
            ngbDatepicker
            #expectedReturnInput
            #expectedReturnPicker="ngbDatepicker"
            (click)="expectedReturnPicker.open()"
            (keyup.space)="expectedReturnPicker.open()"
            [(ngModel)]="dateTo"
            [minDate]="expectedReturnDateMin"
          />
          <div *ngIf="expectedReturnInput.value" class="input-group-append">
            <button
              class="btn btn-link px-2"
              type="button"
              [title]="'AbpUi::Clear' | abpLocalization"
              (click)="
                expectedReturnPicker.writeValue(null);
                expectedReturnPicker.manualDateChange(null, true)
              "
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="target-location d-flex gap-3 mb-3 align-items-center">
        <label for="secondary-jobsite reserve-modal-label" class="reserve-label">{{
          'RegisterService::TargetLocation' | abpLocalization
        }}</label>
        <div id="secondary-jobsite" class="radio-label-wrapper">
          <div class="d-flex align-items-center gap-2" role="button">
            <input
              role="button"
              type="radio"
              name="target"
              class="input-clickable"
              id="home"
              [(ngModel)]="selectedLocationDefinition"
              [value]="LocationDefinition.HOME"
              (change)="definitionChanged(LocationDefinition.HOME); list.get()"
            />
            <label role="button" for="home">{{ 'RegisterService::Home' | abpLocalization }}</label>
          </div>
          <div class="d-flex align-items-center gap-2" role="button">
            <input
              role="button"
              type="radio"
              name="target"
              class="input-clickable"
              id="secondary"
              [(ngModel)]="selectedLocationDefinition"
              [value]="LocationDefinition.SECONDARY"
              (change)="definitionChanged(LocationDefinition.SECONDARY); list.get()"
            />
            <label role="button" for="secondary">{{
              'RegisterService::Secondary' | abpLocalization
            }}</label>
          </div>
          <div class="d-flex align-items-center gap-2" role="button">
            <input
              role="button"
              type="radio"
              name="target"
              class="input-clickable"
              id="jobsite"
              [(ngModel)]="selectedLocationDefinition"
              [value]="LocationDefinition.JOBSITE"
              (change)="definitionChanged(LocationDefinition.JOBSITE); list.get()"
            />
            <label role="button" for="jobsite">{{
              'RegisterService::Jobsite' | abpLocalization
            }}</label>
          </div>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <label class="form-label reserve-modal-label" for="locationList">
          {{ 'RegisterService::LocationList' | abpLocalization }}
          <app-is-required></app-is-required>
        </label>
        <div class="list d-flex flex-column">
          <app-locations-tree
            [isLoading]="isLoading"
            [readonly]="true"
            [selectedNode]="selectedLocation"
            (selectedNodeChange)="selectLocation($event)"
            [locationDefinition]="selectedLocationDefinition"
            [displayHeaders]="false"
          >
          </app-locations-tree>
        </div>
      </div>
    </div>

    <div class="flex gap-2" *ngIf="showMessage">
      <label class="text-black-base font-medium">
        {{ 'ServicingService::TotalSelected' | abpLocalization }}:
      </label>

      <label class="form-label"> {{ assets.length }} </label>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="checkIsApplyDisabled() || isBusy"
      (click)="reserve()"
    >
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
