<div *ngIf="allowAttach" class="text-lg-end pt-2">
  <button class="btn btn-primary btn-sm mb-2" type="button" (click)="create()">
    <i class="fa fa-plus me-1" aria-hidden="true"></i>
    {{ 'ServicingService::NewAttachment' | abpLocalization }}
  </button>
</div>

<div *ngFor="let item of mappedData">
  <div class="mb-3">
    <app-accordion
      [allowDelete]="allowDelete"
      (getDocument)="onGetDocument($event)"
      (delete)="onDelete($event)"
      [data]="item"
    ></app-accordion>
  </div>
</div>

<abp-modal [busy]="isModalBusy" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ (attachedToId ? 'AbpUi::Edit' : 'ServicingService::NewAttachment') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <div>
      <app-file-attacher
        [allow-multiple]="true"
        [ownerType]="ownerType"
        [ownerId]="attachedToId"
        [attachedToId]="attachedToId"
        [attachedToEntity]="attachedToEntity"
        [isModalBusy]="isModalBusy"
        (submitted)="onAttachDocument($event)"
      ></app-file-attacher>
    </div>
  </ng-template>
</abp-modal>
