import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const JOBSITE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/jobsites',
        iconClass: 'fas fa-building',
        name: 'Jobsites',
        parentName: 'AdministrationService::Permission:Jobsite',
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Jobsite',
      },
      {
        path: '/inspections/live',
        iconClass: 'fas fa-tachometer-alt',
        name: 'RegisterService::LiveCompliance',
        parentName: 'AdministrationService::Permission:Jobsite',
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Jobsite',
      },
      {
        path: '/inspections/jobsite',
        iconClass: 'fas fa-file-signature',
        name: 'ServicingService::CompletedEvents',
        parentName: 'AdministrationService::Permission:Jobsite',
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Jobsite',
      },
    ]);
  };
}
