<a role="button" (click)="toggleModal()">
  <i class="bi bi-journal-code"></i>
</a>

<abp-modal [(visible)]="isModalOpen" [options]="{ size: 'lg' }">
  <ng-template #abpHeader>
    <h3>
      {{ 'API Spec' | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <iframe
      [src]="docUrl"
      width="100%"
      height="900"
      seamless
      target="_top"
      title="Swagger UI"
      class="border-0"
    ></iframe>
  </ng-template>

  <!-- <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" (click)="submitForm()" [disabled]="form?.invalid">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template> -->
</abp-modal>
