<div class="mb-3">
  <ngx-datatable [rows]="data.items" [count]="data.totalCount" [list]="list" default>
    <ngx-datatable-column [sortable]="false" [width]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="picture-preview">
          <img
            [src]="
              asset.pictureUrl
                ? (assetService.getPictureUrl(asset.id) | authImage | async)
                : 'assets/images/no-picture.jpg'
            "
          />
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'ServicingService::EventType' | abpLocalization }}"
      prop="inspectionTypeName"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.inspectionTypeName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'ServicingService::Date' | abpLocalization }}"
      prop="doneOn"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.doneOn | date }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'ServicingService::Operator' | abpLocalization }}"
      prop="operatorName"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.operatorName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'ServicingService::Notes' | abpLocalization }}"
      prop="notes"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.notes }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'ServicingService::Status' | abpLocalization }}"
      prop="inspectionStatusName"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.inspectionStatusName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'ServicingService::ReportNumber' | abpLocalization }}"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.reportNumber }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'ServicingService::DueOn' | abpLocalization }}"
      prop="dueOn"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.dueOn | date }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'ServicingService::EventLocation' | abpLocalization }}"
      prop="targetLocationName"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.targetLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="175" [width]="175" [sortable]="false">
      <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
        <div class="btn-group me-3">
          <button type="button" class="btn btn-light btn-sm" (click)="onView(row)">
            <i class="fa fa-eye me-1" aria-hidden="true"></i>
          </button>
          <div class="btn-group" role="group" container="body" ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="btn btn-light btn-sm dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              ngbDropdownToggle
            >
              <i class="fa fa-ellipsis-v me-1" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu>
              <button
                type="button"
                ngbDropdownItem
                *abpPermission="'ServicingService.Inspections'"
                (click)="onGetReport(row)"
              >
                {{ 'CertReportService::GetReport' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                *abpPermission="'ServicingService.Events.Create'"
                (click)="onRepeat(row)"
              >
                {{ 'AbpUi::Repeat' | abpLocalization }}
              </button>
              <button
                *ngIf="isDeletable(row)"
                type="button"
                ngbDropdownItem
                (click)="onDelete(row)"
              >
                {{ 'AbpUi::Delete' | abpLocalization }}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
