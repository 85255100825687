import { ABP, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, switchMap } from 'rxjs/operators';
import { GetQueueItemsInput, QueueItemDto } from '@proxy/queue-service/queue';
import { QueueService } from '@proxy/queue-service/queue/queue.service';
import { InspectionService } from '@proxy/servicing-service/inspections/inspection.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { AssetService } from '@register/proxy/register-service/assets';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@/store/models/state.model';
import { UpdateQueueSizeAction } from '@/store/actions/queue.actions';
import { SiteService } from '@proxy/register-service/sites';
import { AssetDetailsDto, AssetType } from '@proxy/register-service/assets';
import { LocationDefinition } from '@register/proxy/register-service/locations';
import { LocationTreeNode } from '@/locations/models/location-tree-node.model';

@Component({
  selector: 'app-asset-queue',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit {
  data: PagedResultDto<QueueItemDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetQueueItemsInput;
  form: FormGroup;
  isFiltersHidden = true;
  isBusy = false;
  selected: QueueItemDto[] = [];
  dataHistory: QueueItemDto[] = [];
  isModalOpen = false;
  pendingPageChange = false;
  isAssetActive: boolean = false;
  modalType: string = '';
  SelectionType = SelectionType;
  convertedData: AssetDetailsDto[];
  convertedSelectedData: string[];
  categoryName: string;
  locationDefinition = LocationDefinition;
  homeLocationName: string;
  secondaryLocationName: string;
  jobsiteLocationName: string;
  locations: string[] = [];
  topLevels: string[] = [];

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: QueueService,
    public readonly siteService: SiteService,
    public readonly assetService: AssetService,
    private confirmation: ConfirmationService,
    private router: Router,
    private store: Store<State>,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        term: query.filter,
      });
    const setData = (list: PagedResultDto<QueueItemDto>) => {
      this.data = list;
      this.store.dispatch(new UpdateQueueSizeAction(this.data.totalCount));
      this.convertedData = this.data.items.map(this.convertToAssetDetails);
      this.selectAllRows(true);
      if (this.pendingPageChange) {
        this.pendingPageChange = false;
        let newSelected = [...this.selected];

        if (!this.data.items.some(e => this.selected.some(sel => sel.id === e.id))) {
          newSelected = newSelected.concat(
            this.data.items.filter(e => this.locations.includes(e.homeLocationId))
          );
        }

        this.selected = newSelected.filter(
          (item, index, self) => index === self.findIndex(i => i.id === item.id)
        );
      }
    };

    this.list.hookToQuery(getData).subscribe(setData);
    this.store.dispatch(new UpdateQueueSizeAction(this.data.totalCount));
  }

  private convertToAssetDetails(item: QueueItemDto): AssetDetailsDto {
    return {
      id: item.assetId,
      categoryFullName: '',
      categoryName: '',
      categoryNickname: '',
      categoryPath: '',
      categoryPictureUrl: '',
      categoryId: '',
      smartCodeUrl: '',
      isSmartCodeChanged: true,
      code: item.assetCode,
      isActive: item.isActive,
      isFixed: null,
      assetType: null,
      siteId: '',
      homeLocationId: item.homeLocationId,
      homeLocationName: item.homeLocationName,
      secondaryLocationId: item.secondaryLocationId,
      secondaryLocationName: item.secondaryLocationName,
      jobsiteGroupIds: item.jobsiteGroupIds,
      jobsiteIds: item.jobsiteIds,
      jobsiteId: item.jobsiteId,
      jobsiteNames: item.jobsiteNames,
    };
  }

  getSelectedIds() {
    this.service.getSelectedIds({ ...this.filters, locationGroupIds: this.topLevels }).subscribe({
      next: items => {
        const locationIds = this.dataHistory.map(e => e.assetId);
        const filteredItems = items.filter(e => !locationIds.includes(e));
        this.convertedSelectedData = [
          ...new Set([...this.convertedSelectedData, ...filteredItems]),
        ];
      },
    });
  }

  onInspect(record: QueueItemDto) {
    this.router.navigate(['/inspections/create'], { queryParams: { assetId: record.assetId } });
  }

  isSelected(row: QueueItemDto): boolean {
    return this.selected.some(e => e.id === row.id);
  }

  isAllRowsSelected(): boolean {
    if (!this.data.items.length || !this.selected.length) {
      return false;
    }
    const allSelected = this.data.items.every(item =>
      this.selected.some(selectedItem => selectedItem.id === item.id)
    );
    return allSelected;
  }

  selectAllRows(select: boolean): void {
    if (select) {
      const allItems = this.data.items.filter(
        item => !this.selected.some(selectedItem => selectedItem.id === item.id)
      );
      this.selected = [...this.selected, ...allItems];
    } else {
      this.selected = this.selected.filter(
        selectedItem => !this.data.items.some(item => item.id === selectedItem.id)
      );
    }
  }

  isTopLevel(row: QueueItemDto): boolean {
    return row.homeLocationName.split(',').length === 1;
  }

  onAssetStateChange(ids: string[], state?: boolean) {
    this.assetService.changeState({ ids: ids, isActive: state }).subscribe(p => p);
  }

  selectParents(event: any, name: string, id: string, groupId: string) {
    const filteredItems = this.data.items.filter(
      e => e.homeLocationName.split(',')[0] === name.split(',')[0]
    );
    const selectedLocationIds = filteredItems.map(e => e.homeLocationId);
    if (event.target.checked) {
      this.topLevels = [...this.topLevels, groupId || id];
      this.locations = [...this.locations, ...selectedLocationIds];
      this.selected = [...this.selected, ...filteredItems].filter(
        (asset, index, self) => index === self.findIndex(a => a.id === asset.id)
      );
    } else {
      this.topLevels = [...this.topLevels].filter(e => e !== id && e !== groupId);
      this.locations = [...this.locations].filter(e => !selectedLocationIds.includes(e));
      this.selected = [...this.selected].filter(
        e => e.homeLocationName.split(',')[0] !== name.split(',')[0]
      );
    }
  }

  onMakeActive() {
    const ids = this.selected.map(asset => asset.assetId);

    this.confirmation
      .warn('QueueService::ActivateConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [ids.length.toString()],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.assetService.changeState({ ids: ids, isActive: true }))
      )
      .subscribe(
        () => {},
        error => {
          console.error('Error activating assets', error);
        }
      );
  }

  onMakeInActive() {
    const ids = this.selected.map(asset => asset.assetId);
    this.confirmation
      .warn('QueueService::InactivateConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [ids.length.toString()],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.assetService.changeState({ ids: ids, isActive: false }))
      )
      .subscribe(
        () => {},
        error => {
          console.error('Error activating assets', error);
        }
      );
  }

  onModalOpen(modalType: string) {
    const isAllowed = this.selected.some(e => e.components || e.isParent);
    if (isAllowed) {
      this.confirmation.error('QueueService::BulkMoveMessage', 'QueueService::BulkAction', {
        cancelText: 'OK',
        hideYesBtn: true,
      });
      return;
    }
    this.convertedSelectedData = this.selected.map(e => e.assetId);
    this.topLevels.length && this.getSelectedIds();

    this.modalType = modalType;
    this.isModalOpen = true;
  }

  resetData() {
    this.selected = [];
    this.dataHistory = [];
    this.locations = [];
    this.topLevels = [];
  }

  onModalClose() {
    this.modalType = '';
    this.isModalOpen = false;
    this.resetData();

    this.list.get();
  }

  onDequeue(record?: QueueItemDto) {
    this.confirmation
      .warn('QueueService::DeleteConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() =>
          record
            ? this.service.dequeue(record.id)
            : this.service.clear(this.selected.map(x => x.id))
        )
      )
      .subscribe(() => {
        this.list.get();
        this.store.dispatch(new UpdateQueueSizeAction(this.data.totalCount - 1));
        this.cdr.detectChanges();
      });
  }

  onClear() {
    this.confirmation
      .warn('QueueService::DeleteConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.clear([]))
      )
      .subscribe(() => {
        this.list.get();
        this.store.dispatch(new UpdateQueueSizeAction(0));
      });
  }

  onPageChange() {
    this.dataHistory = [...this.dataHistory, ...this.data.items].filter(
      (asset, index, self) => index === self.findIndex(a => a.id === asset.id)
    );

    this.pendingPageChange = true;
  }

  onSelectSubAssets(event: any, item: QueueItemDto) {
    const index = this.selected.findIndex(subAsset => subAsset.id === item.id);

    if (event.target.checked) {
      if (index === -1) {
        this.selected.push(item);
      }
    } else {
      if (index !== -1) {
        this.selected.splice(index, 1);
        if (!this.selected) {
          this.resetData();
          return;
        }
        if (this.locations.length) {
          const ids = this.selected.map(e => e.homeLocationId);
          this.locations = [...this.locations].filter(e => ids.includes(e));
        }
      }
    }
  }

  onSelect({ selected }: any): void {
    if (this.pendingPageChange) return;
    const items = [...selected].filter(
      (asset, index, self) => index === self.findIndex(a => a.id === asset.id)
    );
    this.selected = items;
    if (!items.length) {
      this.resetData();
      return;
    }
    if (this.locations.length) {
      const ids = items.map(e => e.homeLocationId);
      this.locations = [...this.locations].filter(e => ids.includes(e));
    }
  }

  inspectAll() {
    const allAssetsInQueue = this.data.items;

    this.router.navigate(['/inspections/create'], {
      queryParams: { selected: allAssetsInQueue },
    });
  }

  onView(asset: QueueItemDto) {
    this.router.navigate(['/assets/view/' + asset.assetId], {});
  }

  clearFilters() {
    this.filters = {} as GetQueueItemsInput;
    this.clearLocationFilters();
    this.categoryName = null;

    this.list.get();
  }

  setLocationId(location: LocationTreeNode) {
    if (location) {
      switch (location.locationDefinition) {
        case this.locationDefinition.HOME:
          this.filters.homeLocationId = location.id;
          break;
        case this.locationDefinition.SECONDARY:
          this.filters.secondaryLocationId = location.id;
          break;
        case this.locationDefinition.JOBSITE:
          this.filters.jobsiteLocationId = location.id;
          break;
      }
      this.setLocationName(location.locationDefinition, location.name);
    } else {
      this.clearLocationFilters(location.locationDefinition);
    }

    this.list.get();
  }

  setCategoryFilter(event) {
    this.filters.categoryPath = event?.path;
    this.categoryName = event?.fullName;

    this.list.get();
  }

  private setLocationName(locationDefinition: LocationDefinition, name: string) {
    switch (locationDefinition) {
      case this.locationDefinition.HOME:
        this.homeLocationName = name;
        break;
      case this.locationDefinition.SECONDARY:
        this.secondaryLocationName = name;
        break;
      case this.locationDefinition.JOBSITE:
        this.jobsiteLocationName = name;
        break;
    }
  }

  private clearLocationFilters(definition: LocationDefinition = null) {
    this.homeLocationName = null;
    this.secondaryLocationName = null;
    this.jobsiteLocationName = null;
    switch (definition) {
      case this.locationDefinition.HOME:
        this.filters.homeLocationId = null;
        break;

      case this.locationDefinition.SECONDARY:
        this.filters.secondaryLocationId = null;
        break;

      case this.locationDefinition.JOBSITE:
        this.filters.jobsiteLocationId = null;
        break;

      default:
        this.filters.homeLocationId = null;
        this.filters.secondaryLocationId = null;
        this.filters.jobsiteLocationId = null;
        break;
    }
  }

  deallocationButtonAvailability() {
    let foundDifference = false;
    let allEmpty = true;

    for (let index = 1; index < this.selected.length; index++) {
      const currentJobsites = JSON.stringify(this.selected[index].jobsiteNames);
      const previousJobsites = JSON.stringify(this.selected[index - 1].jobsiteNames);

      if (currentJobsites !== previousJobsites) {
        foundDifference = true;
        break;
      }
    }

    if (!foundDifference) {
      for (const item of this.selected) {
        if (item.jobsiteNames && item.jobsiteNames.length > 0) {
          allEmpty = false;
          break;
        }
      }
    }

    if (!allEmpty) {
      if (this.selected.length === 0) {
        allEmpty = true;
      }
    }

    const disableButton = this.selected.every(item => item.isDeallocated);

    return foundDifference || allEmpty || disableButton;
  }
}
