<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        #accordionButton
        class="accordion-button collapsed"
        type="button"
        (click)="toggleAccordion()"
        aria-expanded="false"
        aria-controls="collapseOne"
      >
        <p class="accordion-button-txt">
          {{
            (data[0].title
              ? data[0].title + ' (' + data.length + ')'
              : 'Links' + ' (' + data.length + ')') +
              (data[0].expirationDate ? ' ' + data[0].expirationDate : '')
          }}
        </p>
      </button>
    </h2>
    <div
      #accordionCollapse
      id="collapseOne"
      class="accordion-collapse collapse"
      data-bs-parent="#accordionExample"
    >
      <div class="accordion-body d-flex justify-content-between" *ngFor="let item of data">
        <a href="javascript:;" (click)="onGetDocument(item)" class="d-flex flex-column dots">
          <span class="txt">{{ item.fileName }}</span>
        </a>
        <div class="btn-group flex-btn-group-container">
          <button class="btn btn-light btn-sm" (click)="onGetDocument(item)">
            <i [class]="isHttpLink(item) ? 'fas fa-external-link-alt' : 'fas fa-file-download'"></i>
          </button>
          <button *ngIf="allowDelete" class="btn btn-light btn-sm" (click)="onDelete(item)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
