import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { JoinPipe } from './pipes/join.pipe';
import { AuthImagePipe } from './pipes/auth-image.pipe';
import { CategoriesTreeComponent } from './categories-tree/categories-tree.component';
import { TreeModule } from '@abp/ng.components/tree';
import { CategoryPickerComponent } from './category-picker/category-picker.component';
import { LocationsTreeComponent } from './locations-tree/locations-tree.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { GroupButtonsComponent } from './group-buttons/group-buttons.component';
import { MoveToTopComponent } from './move-to-top/move-to-top.component';
import { ConsumablesTreeComponent } from './consumables-tree/consumables-tree.component';
import { LocationsComponent } from './locations/locations.component';
import { CostCentrePickerComponent } from './cost-centre-picker/cost-centre-picker.component';
import { CostCentreTreeComponent } from './cost-centre-tree/cost-centre-tree.component';
import { ColumnFieldsComponent } from './column-fields/column-fields.component';

@NgModule({
  declarations: [
    AuthImagePipe,
    CategoriesTreeComponent,
    CategoryPickerComponent,
    GroupButtonsComponent,
    JoinPipe,
    LocationPickerComponent,
    LocationsTreeComponent,
    MoveToTopComponent,
    ConsumablesTreeComponent,
    LocationsComponent,
    CostCentrePickerComponent,
    CostCentreTreeComponent,
    ColumnFieldsComponent,
  ],
  imports: [
    CommercialUiModule,
    CoreModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    ThemeLeptonXModule,
    ThemeSharedModule,
    TreeModule,
  ],
  exports: [
    AuthImagePipe,
    CategoriesTreeComponent,
    CategoryPickerComponent,
    CommercialUiModule,
    CoreModule,
    GroupButtonsComponent,
    JoinPipe,
    LocationsComponent,
    LocationPickerComponent,
    LocationsTreeComponent,
    MoveToTopComponent,
    NgbDropdownModule,
    NgxValidateCoreModule,
    ThemeLeptonXModule,
    ConsumablesTreeComponent,
    CostCentrePickerComponent,
    ThemeSharedModule,
    CostCentreTreeComponent,
    ColumnFieldsComponent,
  ],
  providers: [],
})
export class SharedModule {}
