<abp-modal [(visible)]="openModal" (disappear)="modalClose.emit(false)" [options]="{ size: 'xl' }">
  <ng-template #abpHeader>
    <div class="flex flex-col gap-1">
      <h3 class="font-semibold !text-[18px]">
        {{ 'RegisterService::SelectRequest' | abpLocalization }}
      </h3>
    </div>
  </ng-template>

  <ng-template #abpBody>
    <div class="gap-3">
      <app-request-table
        [assetId]="assetId"
        [isPageTemporary]="isPageTemporary"
        [tenantName]="tenantName"
        [pinOrKeyringPassword]="pinOrKeyringPassword"
        (requestUpdated)="onUpdate($event)"
      />
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
