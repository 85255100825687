import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  TrackByFunction,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { map, shareReplay, switchAll } from 'rxjs/operators';

import {
  ABP,
  ConfigStateService,
  getShortDateFormat,
  getShortDateShortTimeFormat,
  getShortTimeFormat,
  ListService,
  PermissionService,
} from '@abp/ng.core';

import {
  ENTITY_PROP_TYPE_CLASSES,
  EntityActionList,
  EntityProp,
  ePropType,
  EXTENSIONS_IDENTIFIER,
  ExtensionsService,
  PROP_DATA_STREAM,
  PropData,
} from '@abp/ng.theme.shared/extensions';
import { StaffService } from '@proxy/servicing-service/staffs/staff.service';

const DEFAULT_ACTIONS_COLUMN_WIDTH = 150;

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTableComponent<R = any> implements OnChanges {
  protected _actionsText!: string;

  @Input() set actionsText(value: string) {
    this._actionsText = value;
  }

  get actionsText(): string {
    return this._actionsText ?? (this.actionList.length > 1 ? 'AbpUi::Actions' : '');
  }

  @Input() data!: R[];
  @Input() list!: ListService;
  @Input() recordsTotal!: number;

  @Input() set actionsColumnWidth(width: number) {
    this.setColumnWidths(width ? Number(width) : undefined);
  }

  @Input() actionsTemplate?: TemplateRef<any>;
  @Output() tableActivate = new EventEmitter();

  hasAtLeastOnePermittedAction: boolean;
  readonly columnWidths!: number[];
  readonly propList: EntityProp[] = [];
  readonly actionList: EntityActionList<R>;
  readonly trackByFn: TrackByFunction<EntityProp<R>> = (_, item) => item.name;

  locale = inject(LOCALE_ID);
  private config = inject(ConfigStateService);
  entityPropTypeClasses = inject(ENTITY_PROP_TYPE_CLASSES);
  #injector = inject(Injector);
  getInjected = this.#injector.get.bind(this.#injector);
  permissionService = this.#injector.get(PermissionService);

  constructor(public staffService: StaffService) {
    const extensions = this.#injector.get(ExtensionsService);
    const name = this.#injector.get(EXTENSIONS_IDENTIFIER);

    const propList = extensions.entityProps.get(name).props;

    const highOrders = ['userName', 'email', 'pin', 'keyringPassword'];
    const hiddenColumns = ['lastModificationTime', 'lockoutEnabled'];

    this.propList = propList
      .toArray()
      .filter(e => !hiddenColumns.includes(e.name))
      .sort((a, b) => {
        const aIndex = highOrders.indexOf(a.name);
        const bIndex = highOrders.indexOf(b.name);

        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
        }

        if (aIndex !== -1) return -1;
        if (bIndex !== -1) return 1;

        return 0;
      });
    this.actionList = extensions['entityActions'].get(name)
      .actions as unknown as EntityActionList<R>;

    this.hasAtLeastOnePermittedAction =
      this.permissionService.filterItemsByPolicy(
        this.actionList.toArray().map(action => ({ requiredPolicy: action.permission }))
      ).length > 0;
    this.setColumnWidths(DEFAULT_ACTIONS_COLUMN_WIDTH);
  }

  private setColumnWidths(actionsColumn: number | undefined) {
    const widths = [actionsColumn];
    this.propList.forEach(prop => {
      widths.push(prop.columnWidth);
    });
    (this.columnWidths as any) = widths;
  }
  private qrCodeCache: Map<string, Observable<string | ArrayBuffer>> = new Map();
  private getDate(value: Date | undefined, format: string | undefined) {
    return value && format ? formatDate(value, format, this.locale) : '';
  }

  private getIcon(value: boolean) {
    return value
      ? '<div class="text-success"><i class="fa fa-check" aria-hidden="true"></i></div>'
      : '<div class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></div>';
  }

  private getEnum(rowValue: any, list: Array<ABP.Option<any>>) {
    if (!list || list.length < 1) return rowValue;
    const { key } = list.find(({ value }) => value === rowValue) || {};
    return key;
  }

  getContent(prop: EntityProp<R>, data: PropData): Observable<string> {
    return prop.valueResolver(data).pipe(
      map(value => {
        switch (prop.type) {
          case ePropType.Boolean:
            return this.getIcon(!!value);
          case ePropType.Date:
            return this.getDate(value as any, getShortDateFormat(this.config));
          case ePropType.Time:
            return this.getDate(value as any, getShortTimeFormat(this.config));
          case ePropType.DateTime:
            return this.getDate(value as any, getShortDateShortTimeFormat(this.config));
          case ePropType.Enum:
            return this.getEnum(value, prop.enumList || []);
          default:
            return value;
        }
      })
    );
  }

  getQrCode(keyringPassword: string): Observable<string | ArrayBuffer> {
    if (!this.qrCodeCache.has(keyringPassword)) {
      const qrCode$ = this.staffService.getKeyringPasswordQr(keyringPassword).pipe(
        map((response: Blob) => {
          const reader = new FileReader();
          const fileReaderObs = new Observable<string | ArrayBuffer>(observer => {
            reader.onloadend = () => {
              observer.next(reader.result as string | ArrayBuffer);
              observer.complete();
            };
            reader.readAsDataURL(response);
          });
          return fileReaderObs;
        }),
        switchAll(),
        shareReplay(1) // Cache the result
      );
      this.qrCodeCache.set(keyringPassword, qrCode$);
    }
    return this.qrCodeCache.get(keyringPassword)!;
  }

  fstx(e: any, b: any) {
    console.log(e, b);
  }

  ngOnChanges({ data }: SimpleChanges) {
    if (!data?.currentValue) return;

    // if (data.currentValue.length < 1) {
    //   this.list.totalCount = this.recordsTotal;
    // }

    this.data = data.currentValue.map((record: any, index: number) => {
      this.propList.forEach(prop => {
        const propData = { getInjected: this.getInjected, record, index } as any;
        const value = this.getContent(prop, propData);

        const propKey = `_${prop.name}`;
        record[propKey] = {
          visible: prop.visible(propData),
          value,
        };
        if (prop.component) {
          record[propKey].injector = Injector.create({
            providers: [
              {
                provide: PROP_DATA_STREAM,
                useValue: value,
              },
            ],
            parent: this.#injector,
          });
          record[propKey].component = prop.component;
        }
      });

      return record;
    });
  }

  isVisibleActions(row: R) {
    return this.hasAtLeastOnePermittedAction;
  }
}
