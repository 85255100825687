<div [formGroup]="form" class="d-flex" *ngIf="!editing">
  <div
    class="value-wrapper"
    *ngFor="let value of values"
    (click)="selectValue(value)"
    [ngClass]="{ picked: value === pickedValue }"
  >
    {{ value }}
  </div>
</div>
<label *ngIf="editing">{{ this.form.get('assetType').value }}</label>
