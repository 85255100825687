import { ApplicationInfo, EnvironmentService } from '@abp/ng.core';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  // template: `
  //   <a href="/"><div class="lpx-brand-logo"></div></a>
  // `,
  template: `
    <a href="/">
      <img
        *ngIf="appInfo.logoUrl; else appName"
        [src]="appInfo.logoUrl"
        [alt]="appInfo.name"
        [ngStyle]="{ minWidth: '40px' }"
        width="50%"
        height="auto"
      />
    </a>
    <ng-template #appName>
      {{ appInfo.name }}
    </ng-template>
  `,
  styles: [],
})
export class LogoComponent implements AfterViewInit {
  constructor(private elRef: ElementRef, private environment: EnvironmentService) {}

  ngAfterViewInit(): void {
    var logoUrl = 'https://abp.io/assets/svg/abp-logo.svg';
    var logoReverseUrl = 'https://abp.io/assets/svg/abp-logo-light.svg';
    // this.elRef.nativeElement.style.setProperty('--logo', `url(${logoUrl})`);
    // this.elRef.nativeElement.style.setProperty('--logo-reverse', `url(${logoReverseUrl})`);
  }

  get appInfo(): ApplicationInfo {
    this.environment.getEnvironment().application.logoUrl = '/assets/images/logo/SitePlant.png';
    return this.environment.getEnvironment().application;
  }

  // constructor(private route: ActivatedRoute, private elRef: ElementRef){}
  // ngAfterViewInit() {
  //     this.route.queryParams.subscribe(params => {
  //         const tenant = params['Tenant']
  //         const logoUrl = "YOUR LOGO URL";
  //         this.elRef.nativeElement.style.setProperty('--logo-reverse', `url(${logoUrl})`);
  //     })
  // }

  // this.actions.pipe(ofActionSuccessful(SetStyle)).subscribe(() => {
  //   document.documentElement.style.setProperty(
  //     '--logo',
  //     `url('logo url here')`,
  //   );

  //   document.documentElement.style.setProperty(
  //     '--logo-reverse',
  //     `url('reverse logo url here')`,
  //   );
  // });
}
