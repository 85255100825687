import { Component } from '@angular/core';

@Component({
  selector: 'app-is-required',
  templateUrl: './is-required.component.html',
  styleUrls: ['./is-required.component.scss']
})
export class IsRequiredComponent {

}
