import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AssetType, HirePeriodRange, hirePeriodRangeOptions } from '@proxy/register-service/assets';

@Component({
  selector: 'app-group-buttons',
  templateUrl: './group-buttons.component.html',
  styleUrls: ['./group-buttons.component.scss'],
})
export class GroupButtonsComponent implements OnInit {
  @Input() values: string[];
  @Input() value: AssetType;
  @Input() form: FormGroup;
  @Input() editing: boolean;
  pickedValue: AssetType;

  ngOnInit(): void {
    this.form.get('assetType').valueChanges.subscribe(value => {
      if (value === null) {
        this.selectValue(AssetType.OWNED);
      }
    });
    if (!this.form.get('assetType').value) {
      this.pickedValue = AssetType.OWNED;
      this.form.get('assetType').setValue(this.pickedValue);
    } else {
      this.selectValue(this.value);
    }
  }

  selectValue(value: AssetType): void {
    this.form.get('assetType').setValue(value);
    this.pickedValue = value;
    let currentDate = new Date();
    let weekLater = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

    if (this.pickedValue === AssetType.HIRED) {
      this.form.get('dateHired').setValue(currentDate.toLocalISOString());
      this.form.get('hireType').setValue(HirePeriodRange.WEEKLY);
      this.form.get('nextHireDate').setValue(weekLater.toLocalISOString());
    }
  }
}
