<div class="checkbox-grid">
  <div *ngFor="let item of keys | keyvalue : originalOrder" class="form-check mb-3">
    <input
      type="checkbox"
      class="form-check-input"
      [id]="item.key"
      (change)="onSelect($event, item)"
      [checked]="item.value"
    />
    <label class="form-check-label" [attr.for]="item.key">{{
      columnTranslationKey[item.key] | abpLocalization
    }}</label>
  </div>
</div>
