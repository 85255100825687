<abp-modal [(visible)]="isModalOpen" (disappear)="onCancel()">
  <ng-template #abpHeader>
    <h5>
      {{ 'RegisterService::CostCentre' | abpLocalization }}
    </h5>
  </ng-template>

  <ng-template #abpBody>
    <div class="mb-3">
      <input
        (input)="onSearchTermChange($event.target.value)"
        [placeholder]="'AbpUi::Search' | abpLocalization"
        class="form-control"
      />
    </div>
    <app-cost-centre-tree
      [isLoading]="isLoading"
      [data]="data"
      [readonly]="true"
      [(selectedNode)]="selected"
    />
  </ng-template>

  <ng-template #abpFooter>
    <button
      type="button"
      class="btn bg-success text-white"
      (click)="onSelect()"
      [disabled]="isLoading || (!selected && !selectedId) || selectedId === selected?.id"
    >
      {{ 'RegisterService::Set' | abpLocalization }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
