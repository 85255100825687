import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const INSPECTIONS_INSPECTION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      // {
      //   path: '/inspections/pending',
      //   iconClass: 'fas fa-file',
      //   name: 'ServicingService::Menu:PendingInspections',
      //   parentName: 'ServicingService::Menu:ServicingService',
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'ServicingService.Inspections',
      //   order: 1,
      // },
      {
        path: '/inspections/completed',
        iconClass: 'fas fa-file-signature',
        name: 'ServicingService::Menu:CompletedEvents',
        parentName: 'ServicingService::Menu:ServicingService',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.Events.Page',
        order: 5
      },
      {
        path: '/inspections/live',
        iconClass: 'fas fa-tachometer-alt',
        name: 'RegisterService::LiveCompliance',
        parentName: 'AdministrationService::Permission:Jobsite',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.Events.Page',
      },
      {
        path: '/inspections/jobsite',
        iconClass: 'fas fa-file-signature',
        name: 'ServicingService::CompletedEvents',
        parentName: 'AdministrationService::Permission:Jobsite',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.Events.Page',
      },
      // {
      //   path: '/inspections/upcoming',
      //   iconClass: 'fas fa-file-import',
      //   name: 'ServicingService::Menu:UpcomingEvents',
      //   parentName: 'ServicingService::Menu:ServicingService',
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'ServicingService.Inspections',
      //   order: 10
      // }
    ]);
  };
}
