import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { ModuleWithProviders, NgModule, NgModuleFactory } from '@angular/core';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { UserComponent } from './user/user.component';
import { IdentityModule } from '@volo/abp.ng.identity';
import { PermissionManagementModule } from '@abp/ng.permission-management';
// import { IdentityExtensionsGuard } from '@volo/abp.ng.identity';

// @NgModule({
//   declarations: [UserComponent],
//   imports: [
//     // SiteRoutingModule,
//     CoreModule,
//     ThemeSharedModule,
//     CommercialUiModule,
//     NgxValidateCoreModule,
//     NgbCollapseModule,
//     NgbDatepickerModule,
//     NgbDropdownModule,
//     PageModule,
//     IdentityModule
//   ],
// })
// export class UserModule {}
// import { PageModule } from '@abp/ng.components/page';
// import { TreeModule } from '@abp/ng.components/tree';
// import { CoreModule, LazyModuleFactory } from '@abp/ng.core';
// import { PermissionManagementModule } from '@abp/ng.permission-management';
// import { ThemeSharedModule } from '@abp/ng.theme.shared';
// import { UiExtensionsModule } from '@abp/ng.theme.shared/extensions';
// import { ModuleWithProviders, NgModule, NgModuleFactory } from '@angular/core';
// import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxValidateCoreModule } from '@ngx-validate/core';
// import { AdvancedEntityFiltersModule, CommercialUiModule } from '@volo/abp.commercial.ng.ui';
// import { ClaimModalComponent } from './components/claim-modal/claim-modal.component';
// import { ClaimsComponent } from './components/claims/claims.component';
// import { AbstractOrganizationUnitComponent } from './components/organization-units/abstract-organization-unit/abstract-organization-unit.component';
// import { OrganizationMembersModalBodyComponent } from './components/organization-units/organization-members/organization-members-modal-body.component';
// import { OrganizationMembersComponent } from './components/organization-units/organization-members/organization-members.component';
// import { OrganizationRolesModalBodyComponent } from './components/organization-units/organization-roles/organization-roles-modal-body.component';
// import { OrganizationRolesComponent } from './components/organization-units/organization-roles/organization-roles.component';
// import { OrganizationUnitsComponent } from './components/organization-units/organization-units.component';
// import { RolesComponent } from './components/roles/roles.component';
// import { SecurityLogsComponent } from './components/security-logs/security-logs.component';
// import { UsersComponent } from './components/users/users.component';
// import { IdentityExtensionsGuard } from './guards/extensions.guard';
// import { IdentityRoutingModule } from './identity-routing.module';
// import { IdentityConfigOptions } from '@volo/abp.ng.identity';
// import {
//   IDENTITY_CREATE_FORM_PROP_CONTRIBUTORS,
//   IDENTITY_EDIT_FORM_PROP_CONTRIBUTORS,
//   IDENTITY_ENTITY_ACTION_CONTRIBUTORS,
//   IDENTITY_ENTITY_PROP_CONTRIBUTORS,
//   IDENTITY_TOOLBAR_ACTION_CONTRIBUTORS,
// } from '@volo/abp.ng.identity';
// import { UiExtensionsModule } from '@abp/ng.theme.shared/extensions';
// import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from '@abp/ng.components/tree';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@/shared/shared.module';
import { EventTypesComponent } from './user/event-types/event-types.component';
import { UserTableComponent } from './user/user-table/user-table.component';

@NgModule({
  declarations: [
    UserComponent,
    EventTypesComponent,
    UserTableComponent,
    // RolesComponent,
    // UsersComponent,
    // ClaimModalComponent,
    // ClaimsComponent,
    // OrganizationUnitsComponent,
    // OrganizationMembersComponent,
    // OrganizationMembersModalBodyComponent,
    // OrganizationRolesComponent,
    // OrganizationRolesModalBodyComponent,
    // AbstractOrganizationUnitComponent,
    // SecurityLogsComponent,
  ],
  imports: [
    // ThemeLeptonModule,
    // UiExtensionsModule,
    FormsModule,
    CommercialUiModule,
    CoreModule,
    IdentityModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    SharedModule,
    NgxValidateCoreModule,
    PageModule,
    PermissionManagementModule,
    ThemeSharedModule,
    TreeModule,
  ],
  exports: [
    UserComponent,
    // RolesComponent,
    // UsersComponent,
    // ClaimModalComponent,
    // ClaimsComponent,
    // OrganizationUnitsComponent,
    // OrganizationMembersComponent,
    // OrganizationMembersModalBodyComponent,
    // OrganizationRolesComponent,
    // OrganizationRolesModalBodyComponent,
    // AbstractOrganizationUnitComponent,
    // SecurityLogsComponent,
  ],
})
export class UserModule {
  // static forChild(options: IdentityConfigOptions = {}): ModuleWithProviders<UserModule> {
  //   return {
  //     ngModule: UserModule,
  //     providers: [
  //       {
  //         provide: IDENTITY_ENTITY_ACTION_CONTRIBUTORS,
  //         useValue: options.entityActionContributors,
  //       },
  //       {
  //         provide: IDENTITY_TOOLBAR_ACTION_CONTRIBUTORS,
  //         useValue: options.toolbarActionContributors,
  //       },
  //       {
  //         provide: IDENTITY_ENTITY_PROP_CONTRIBUTORS,
  //         useValue: options.entityPropContributors,
  //       },
  //       {
  //         provide: IDENTITY_CREATE_FORM_PROP_CONTRIBUTORS,
  //         useValue: options.createFormPropContributors,
  //       },
  //       {
  //         provide: IDENTITY_EDIT_FORM_PROP_CONTRIBUTORS,
  //         useValue: options.editFormPropContributors,
  //       },
  //       IdentityExtensionsGuard,
  //     ],
  //   };
  // }
  // static forLazy(options: IdentityConfigOptions = {}): NgModuleFactory<IdentityModule> {
  //   return new LazyModuleFactory(IdentityModule.forChild(options));
  // }
}
