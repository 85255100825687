<abp-modal [(visible)]="isOpen" (disappear)="isOpenChange.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::Location' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <app-locations
      [isLoading]="isBusy"
      [readonly]="true"
      [hideSelect]="true"
      [(selectedLocation)]="selectedLocation"
      (handleSelect)="selectLocation($event)"
    >
    </app-locations>
  </ng-template>
  <ng-template #abpFooter>
    <button
      type="button"
      class="btn bg-success text-white"
      (click)="submit()"
      *ngIf="selectedLocation"
      [disabled]="isBusy"
    >
      {{ 'ServicingService::Select' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
