<abp-modal [(visible)]="openIsActiveModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::MakeAssets' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="col-12 col-sm-auto">
      <div class="mb-3">
        <select
          name="isActive"
          id="isActiveDropdown"
          class="form-select form-control"
          [(ngModel)]="isActiveSelected"
        >
          <option [ngValue]="true">{{ 'AbpUi::Active' | abpLocalization }}</option>
          <option [ngValue]="false">{{ 'AbpUi::Inactive' | abpLocalization }}</option>
        </select>
      </div>
    </div>
    <div class="flex gap-2" *ngIf="showMessage">
      <label class="text-black-base font-medium">
        {{ 'ServicingService::TotalSelected' | abpLocalization }}:
      </label>

      <label class="form-label"> {{ assets.length }} </label>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" (click)="toggleIsActive()" [disabled]="isBusy">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
