import {
  ABP,
  ConfigStateService,
  ListService,
  PagedResultDto,
  getShortDateFormat,
} from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AssetDetailsDto, AssetService } from '@proxy/register-service/assets';
import { LocationDto, LocationService } from '@proxy/register-service/locations';
import { SiteService } from '@proxy/register-service/sites';
import { today, convertDateToNgbDatepickerFromat } from '@proxy/shared/getToday';
import { LocationDefinition } from '@register/proxy/register-service/locations';

@Component({
  selector: 'app-reserve-modal',
  templateUrl: './reserve-modal.component.html',
  styleUrls: ['./reserve-modal.component.scss'],
})
export class ReserveModalComponent implements OnInit {
  @Input() openReserveModal: boolean;
  @Input() asset: AssetDetailsDto;
  @Input() assets: string[];
  @Input() showMessage = false;
  @Output() modalClose = new EventEmitter<boolean>();

  data: PagedResultDto<LocationDto> = {
    items: [],
    totalCount: 0,
  };

  assetIds: string[] = [];
  dateFrom: Date = new Date();
  dateFromVisable: boolean;
  dateTo: Date = null;
  disableApply: boolean = true;
  fromNowSelected: boolean = true;
  getShortDateFormat = getShortDateFormat;
  isBusy: boolean = false;
  LocationDefinition = LocationDefinition;
  selectedLocation: LocationDto = null;
  selectedLocationDefinition: LocationDefinition = LocationDefinition.SECONDARY;
  selectedLocations: LocationDto[] = [];
  selectedLocationsIds: string[] = [];
  today: NgbDateStruct = today;
  expectedReturnDateMin: NgbDateStruct = today;

  constructor(
    public readonly assetService: AssetService,
    public readonly configState: ConfigStateService,
    public readonly list: ListService,
    public readonly service: LocationService,
    public readonly siteService: SiteService,
    public readonly toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.assetIds = this.assets;

    const getData = (query: ABP.PageQueryParams) => {
      return this.service.getList({
        ...query,
        locationDefinition: this.selectedLocationDefinition,
        parentId: this.assetService.defaultGUID,
        term: query.filter,
        maxResultCount: 100,
        skipCount: 0,
      });
    };

    const setData = (list: PagedResultDto<LocationDto>) => {
      this.data = list;
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  definitionChanged(locationDefinition: LocationDefinition) {
    this.selectedLocationDefinition = locationDefinition;
    this.selectedLocation = null;
    this.selectedLocations = [];
    this.selectedLocationsIds = [];
    this.disableApply = true;
  }

  toggleLocation(location: LocationDto) {
    const index = this.selectedLocations.indexOf(location);
    if (index === -1) {
      this.selectedLocations.push(location);
      this.selectedLocationsIds.push(location.id);
    } else {
      this.selectedLocations.splice(index, 1);
      this.selectedLocationsIds.splice(index, 1);
    }
  }

  selectLocation(location: LocationDto) {
    this.selectedLocation = location;
    this.selectedLocationsIds = [this.selectedLocation.id];
    this.disableApply = false;
  }

  selectFromNow() {
    this.fromNowSelected = true;
    this.dateFromVisable = false;
    this.dateTo = null;
    this.dateFrom = new Date();
  }

  selectDateFrom() {
    this.fromNowSelected = false;
    this.dateFromVisable = true;
    this.changeExpectedReturnMinDate(today);
  }

  changeExpectedReturnMinDate(date: NgbDateStruct) {
    this.expectedReturnDateMin = date;
  }

  private convertToISOString(date: Date): string {
    return date.toLocalISOString();
  }

  reserve() {
    this.isBusy = true;
    if (this.asset) {
      this.assetIds = [this.asset.id];
    }

    const reservationData = this.buildReservationData();

    this.assetService.reserve({ ...reservationData, subAssets: [], missingIds: [] }).subscribe({
      next: () => {
        this.modalClose.emit(true);
        this.toasterService.success('RegisterService::Successful');
      },
      error: () => {
        this.isBusy = false;
      },
    });
  }

  private buildReservationData(): {
    assetIds: string[];
    locationIds: string[];
    from: string;
    to: string | null;
  } {
    const from = this.convertToISOString(new Date(this.dateFrom));
    const to = this.dateTo ? this.convertToISOString(new Date(this.dateTo)) : null;

    return {
      assetIds: this.assetIds,
      locationIds: this.selectedLocationsIds,
      from,
      to,
    };
  }
  checkIsApplyDisabled() {
    if (!this.selectedLocation) {
      return true;
    }
    if (!this.dateFromVisable) {
      return false;
    }
    if (!this.dateTo) {
      return true;
    }
    return false;
  }
}
