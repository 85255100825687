import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetDetailsDto, AssetService } from '@proxy/register-service/assets';

@Component({
  selector: 'app-sub-assets-modal',
  templateUrl: './sub-assets-modal.component.html',
})
export class SubAssetsModalComponent implements OnInit {
  @Input() openModal: boolean;
  @Input() assets: AssetDetailsDto[];
  @Input() title: string;
  @Output() modalClose = new EventEmitter<boolean>();
  @Output() transfer = new EventEmitter<AssetDetailsDto[]>();

  allAssets: AssetDetailsDto[] = [];

  selectedAssets: AssetDetailsDto[] = [];
  isBusy: boolean = false;

  constructor(public readonly service: AssetService) {}

  ngOnInit() {
    this.service.getSubAssets({ ids: this.assets.map(e => e.id) }).subscribe({
      next: items => {
        if (items) {
          this.allAssets = this.buildHierarchy([...this.assets, ...items]);
          this.selectedAssets = items;
        }
      },
    });
  }

  buildHierarchy(assets: AssetDetailsDto[]): AssetDetailsDto[] {
    const assetMap = new Map<string, AssetDetailsDto>();

    assets.forEach(asset => {
      assetMap.set(asset.id, { ...asset, children: [] });
    });

    const rootAssets: AssetDetailsDto[] = [];

    assetMap.forEach(asset => {
      if (asset.parentId && assetMap.has(asset.parentId)) {
        assetMap.get(asset.parentId).children.push(asset);
      } else {
        rootAssets.push(asset);
      }
    });

    return rootAssets;
  }

  isSelected(id: string): boolean {
    return this.selectedAssets.some(e => e.id === id);
  }

  onSelectSubAssets(event: any, item: AssetDetailsDto) {
    const index = this.selectedAssets.findIndex(subAsset => subAsset.id === item.id);

    if (event.target.checked) {
      if (index === -1) {
        this.selectedAssets.push(item);
      }
    } else {
      if (index !== -1) {
        this.selectedAssets.splice(index, 1);
      }
    }
  }

  onTransfer() {
    this.isBusy = true;
    this.transfer.emit(this.selectedAssets);
  }
}
