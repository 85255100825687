import type { PagedResultDto } from '@abp/ng.core';
import type { AttachmentCreateDto, AttachmentDto, AttachmentUpdateDto, GetAttachmentsInput } from './models';
import { map, switchMap,tap } from 'rxjs/operators';
import { Observable, of, pipe } from 'rxjs';
import { IAttachmentService } from './attachment.service';

export class AttachmentLocalService implements IAttachmentService {
  data: PagedResultDto<AttachmentDto>;

  attach = (attachedToEntity: string, attachedToId: string, inputs: AttachmentCreateDto[]): Observable<AttachmentDto[]> => {
    return of(inputs.map(input => {
      const attachmentDto = {
        ...input,
        attachedToEntity: attachedToEntity,
        attachedToId: attachedToId
      } as AttachmentDto;
      this.data.items.push(attachmentDto);
      this.data.totalCount++;

      return attachmentDto;
    }));
  }

  get = (id: string, attachedToEntity: string, attachedToId: string) =>
    of(this.data.items.find(x => x.id === id && x.attachedToEntity === attachedToEntity && x.attachedToId === attachedToId));

  getList = (attachedToEntity: string, attachedToId: string, input: GetAttachmentsInput) =>
    of(this.data);

  update = (id: string, attachedToEntity: string, attachedToId: string, input: AttachmentUpdateDto): Observable<AttachmentDto> => {
    throw new Error('Method not implemented.');
  }

  download = (id: string, attachedToEntity: string, attachedToId: string): Observable<{
    fileName: string; contentType: string; data: any;
  }> =>
    this.get(id, attachedToEntity, attachedToId).pipe(
      switchMap(attachment => of({
        fileName: attachment.fileName,
        contentType: attachment.contentType,
        data: attachment.fileUrl
      }))
    );

  delete = (id: string, attachedToEntity: string, attachedToId: string): Observable<void> =>
    of(this.data.items.findIndex(x => x.id === id && x.attachedToEntity === attachedToEntity && x.attachedToId === attachedToId))
      .pipe(switchMap(index => of(this.data.items.splice(index, 1))), map(() => { }));

  constructor(data: PagedResultDto<AttachmentDto>) {
    this.data = data;
  }
}
