import { ListService, PagedResultDto } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AssetDetailsDto,
  AssetService,
  AssetTransferableParts,
} from '@proxy/register-service/assets';
import { LocationDto, LocationService } from '@proxy/register-service/locations';
import { DeallocationType } from '@register/proxy/register-service/assets';
import { LocationDefinition } from '@register/proxy/register-service/locations/location-type.enum';

@Component({
  selector: 'app-move-modal',
  templateUrl: './move-modal.component.html',
  styleUrls: ['./move-modal.component.scss'],
})
export class MoveModalComponent implements OnChanges {
  @Input() openMoveModal: boolean;
  @Input() asset: AssetDetailsDto;
  @Input() showMessage = false;
  @Input() twoStepEnabled = false;
  @Input() transferData: AssetTransferableParts | null = null;
  @Input() assets: string[];
  @Output() modalClose = new EventEmitter<boolean>();
  @Output() openTransferModal = new EventEmitter();

  data: PagedResultDto<LocationDto> = {
    items: [],
    totalCount: 0,
  };

  DeallocationType = DeallocationType;
  selectedLocation: LocationDto = null;
  locationDefinition: LocationDefinition = LocationDefinition.HOME;
  isBusy: boolean = true;

  constructor(
    public readonly list: ListService,
    public readonly service: LocationService,
    public readonly assetService: AssetService,
    public readonly toasterService: ToasterService
  ) {}

  selectLocation(location: LocationDto) {
    this.isBusy = false;
    this.selectedLocation = location;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['transferData'] && changes['transferData'].currentValue) {
      this.performAction();
    }
  }

  performAction() {
    const move = this.assetService.performAction({
      actionType: 'move',
      asset: this.asset,
      assetIds: this.assets,
      isQueue: !this.asset,
      transferData: this.transferData,
      selectedLocation: this.selectedLocation,
    });

    move.subscribe({
      next: () => {
        this.modalClose.emit(true);
        this.toasterService.success('RegisterService::Successful');
      },
    });
  }

  move() {
    this.isBusy = true;
    this.twoStepEnabled ? this.openTransferModal.emit() : this.performAction();
  }
}
