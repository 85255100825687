import { BaseNode, TreeAdapter } from '@abp/ng.components/tree';
import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { LocationTreeNode } from '@/locations/models/location-tree-node.model';
import { GetLocationsInput, LocationDefinition } from '@register/proxy/register-service/locations';
import { finalize, of, Subject, Subscription } from 'rxjs';
import { LocationService } from '@proxy/register-service/locations';
import { AssetService } from '@proxy/register-service/assets';

@Component({
  selector: 'app-locations-tree',
  templateUrl: './locations-tree.component.html',
  styleUrls: ['./locations-tree.component.scss'],
})
export class LocationsTreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isLoading: boolean;
  @Input() siteId: string;
  @Input() checkStrictly: boolean = false;
  @Input() checkable: boolean = false;
  @Input() selectedNode: LocationTreeNode;
  @Input() selectedNodes: LocationTreeNode[] = [];
  @Input() restrictedKeys: string[] = [];
  @Input() readonly: boolean;
  @Input() filters: GetLocationsInput;
  @Input() treeTitle: string;
  @Input() locationDefinition: LocationDefinition;
  @Input() parentId: string = '00000000-0000-0000-0000-000000000000';
  @Input() displayHeaders: boolean;
  @Input() hideCreate: boolean = false;
  @Input() isAllSelected: boolean = false;
  @Input() showChildLocations: boolean = true;
  @Input() pinOrKeyringPassword: string;
  @Output() addRootNode = new EventEmitter<LocationDefinition>();
  @Output() addSubNode = new EventEmitter<LocationTreeNode>();
  @Output() deleteNode = new EventEmitter<LocationTreeNode>();
  @Output() toggleActiveNode = new EventEmitter<LocationTreeNode>();
  @Output() selectedNodeChange = new EventEmitter<LocationTreeNode>();
  @Output() checkedKeysChange = new EventEmitter<string[]>();
  @Output() restrictedKeysChange = new EventEmitter<{
    type: LocationDefinition;
    items: string[];
  }>();

  @Input() data: PagedResultDto<LocationTreeNode> = {
    items: [],
    totalCount: 0,
  };
  selectedLocation: LocationTreeNode = null;
  expandedKeys: any[] = [];
  allKeys: string[] = [];
  nodes: LocationTreeNode[];
  treeAdapter: TreeAdapter<LocationTreeNode>;
  locationDefinitionEnum = LocationDefinition;

  private subscription: Subscription;

  private _checkedKeys: string[] = [];

  @Input()
  get checkedKeys(): string[] {
    return this._checkedKeys;
  }

  set checkedKeys(value: string[]) {
    if (this.checkStrictly) {
      const set1 = new Set(this.allKeys);
      const set2 = new Set(value);
      const uniqueArr = [...set1].filter(item => !set2.has(item));
      this.restrictedKeysChange.emit({ type: this.locationDefinition, items: uniqueArr });
    }

    this._checkedKeys = value;
    this.checkedKeysChange.emit(this._checkedKeys);
  }

  constructor(
    public readonly list: ListService,
    public readonly service: LocationService,
    public readonly assetService: AssetService,
    public readonly cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const getData = (query: ABP.PageQueryParams) => {
        this.isLoading = true;
        const path = this.route.snapshot.url.join('/');

        return this.service
          .getList({
            ...query,
            ...this.filters,
            assetId: path.includes('jobsite/edit') ? null : params.get('id'),
            siteId: this.siteId,
            pinOrKeyringPassword: this.pinOrKeyringPassword,
            locationDefinition: this.locationDefinition,
            parentId: this.showChildLocations ? null : this.parentId,
            term: this.isAllSelected ? '' : query.filter,
            skipCount: 0,
            maxResultCount: 100,
          })
          .pipe(finalize(() => (this.isLoading = false)));
      };

      const setData = (list: PagedResultDto<LocationTreeNode>) => {
        this.data = list;
        this.treeAdapter = new TreeAdapter(list.items);
        this.nodes = this.treeAdapter.getTree();
        this.expandedKeys = [...this.expandedKeys];
        this.selectedLocation = null;
        if (this.isAllSelected && !this.checkedKeys.length) {
          this.allKeys = this.data.items.map(e => e.id);
          this.checkedKeys = this.allKeys.filter(e => !this.restrictedKeys.includes(e));
        }
      };

      this.subscription = this.list.hookToQuery(getData).subscribe(setData);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.siteId) {
      this.list.get();
    }

    if (changes.parentId) {
      this.list.get();
    }

    if (changes.data && !changes.data.firstChange) {
      this.setTreeAdapterData(changes.data.currentValue);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setTreeAdapterData(list: PagedResultDto<LocationTreeNode>) {
    if (!list) return;

    this.data = list;
    this.treeAdapter = new TreeAdapter(list.items);
    this.nodes = this.treeAdapter.getTree();
    this.expandedKeys = [...this.expandedKeys];
  }

  onAddRootNode() {
    this.addRootNode.emit(this.locationDefinition);
  }

  onAddSubNode(node: NzTreeNode, $event: MouseEvent) {
    this.addSubNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onDelete(node: NzTreeNode, $event: MouseEvent) {
    this.deleteNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onToggleActive(node: NzTreeNode, $event: MouseEvent) {
    this.toggleActiveNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  isNodeSelected(node: NzTreeNode) {
    if (this.locationDefinition === this.locationDefinitionEnum.JOBSITE && this.readonly) {
      return this.selectedNodes.includes(node.origin.entity);
    } else {
      return node.origin.id === this.selectedNode?.id;
    }
  }

  onSelectedNode(node: LocationTreeNode) {
    this.selectedNodeChange.emit(node);
  }
}
