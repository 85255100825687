export default class Helpers {
  static normalizeText(text: string) {
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static normalizeTextDate(date: Date): string {
    return date.toISOString().split('T')[0].replace(/-/g, '.');
  }
}
