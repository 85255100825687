import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationDto } from '@proxy/register-service/locations';

@Component({
  selector: 'app-locations-picker',
  templateUrl: './locations-picker.component.html',
  styleUrls: ['./locations-picker.component.scss'],
})
export class LocationsPickerComponent {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() handleSelect = new EventEmitter<LocationDto>();

  selectedLocation: LocationDto = null;
  isBusy: boolean = true;

  constructor() {}

  selectLocation(location: LocationDto) {
    this.isBusy = false;
    this.selectedLocation = location;
  }

  submit() {
    this.isBusy = true;
    this.handleSelect.emit(this.selectedLocation);
    this.isOpenChange.emit(false);
  }
}
