<div
  class="file-uploader"
  (dragover.prevent)="dragover()"
  (dragleave.prevent)="dragleave()"
  (drop.prevent)="dropfiles($event)"
>
  <div [hidden]="!(files.length === 0 && !showWebLink)" class="file-upload-area">
    <ng-container *ngIf="!isDragging; else notDragging">
      <div>
        <div class="text-center mb-1">
          {{ '::Drag and drop files here or upload from' | abpLocalization }}
        </div>
        <div class="text-center mb-3">
          {{ "::Each file's size should not exceed 2MB" | abpLocalization }}
        </div>
        <div class="mt-2 text-center">
          <input
            #fileInput
            type="file"
            class="hidden"
            [multiple]="allowMultiple"
            [accept]="restrictions.allowedFileTypes.join(', ')"
            (change)="onFileInput($event)"
          />
          <button class="btn btn-file-upload" (click)="browseFiles()">
            <div>
              <span class="fa-stack fa-2x">
                <i class="fas fa-circle fa-stack-2x browse-icon"></i>
                <i class="fas fa-hdd fa-stack-1x" [style.color]="'white'"></i>
              </span>
            </div>
            <div class="mt-1">{{ '::My Device' | abpLocalization }}</div>
          </button>
          <button class="btn btn-file-upload" (click)="showWebLink = true">
            <div>
              <span class="fa-stack fa-2x">
                <i class="fas fa-circle fa-stack-2x link-icon"></i>
                <i class="fas fa-link fa-stack-1x" [style]="{ color: 'white' }"></i>
              </span>
            </div>
            <div class="mt-1">{{ '::Link' | abpLocalization }}</div>
          </button>
          <button *ngIf="allowTakePhoto" class="btn btn-file-upload" (click)="captureImage()">
            <div>
              <span class="fa-stack fa-2x">
                <i class="fas fa-circle fa-stack-2x browse-icon"></i>
                <i class="fas fa-camera fa-stack-1x" [style]="{ color: 'white' }"></i>
              </span>
            </div>
            <div class="mt-1">{{ '::Camera' | abpLocalization }}</div>
          </button>
        </div>
        <div class="text-muted text-medium">
          {{ uploadNotes }}
        </div>
      </div>
    </ng-container>
    <ng-template #notDragging>
      <div>
        {{ '::Drop files here' | abpLocalization }}
      </div>
    </ng-template>
  </div>
  <div [hidden]="!(files.length && !showWebLink)" class="file-preview-area">
    <div class="file-preview-container mb-3">
      <app-file-preview
        *ngFor="let file of files"
        [restrictions]="restrictions"
        [key]="file.name"
        [file]="file"
        [isModalBusy]="isModalBusy"
        (remove)="removeFile(file)"
      ></app-file-preview>
    </div>
    <div>
      <div>
        <div class="mb-3">
          <label class="form-label" for="groupName">
            {{ 'RegisterService::Name' | abpLocalization }}
          </label>
          <app-is-required></app-is-required>
          <input
            id="groupName"
            [(ngModel)]="groupName"
            class="form-control"
            maxlength="64"
            [disabled]="isModalBusy"
          />
        </div>
        <div class="mb-3 d-flex flex-column">
          <label class="form-label" for="asset-hire-date">
            {{ 'RegisterService::ExpiryDate' | abpLocalization }}
          </label>
          <div class="input-group date-selector" validationTarget>
            <input
              id="asset-hire-date"
              class="form-control"
              ngbDatepicker
              readonly
              #expirationDateInput
              #expirationDatePicker="ngbDatepicker"
              (click)="expirationDatePicker.open()"
              (keyup.space)="expirationDatePicker.open()"
              (dateSelect)="onExpirationDateSelect($event)"
              [minDate]="today"
              [disabled]="isModalBusy"
              [value]="expirationDate | date : getShortDateFormat(configState)"
            />
            <div *ngIf="expirationDateInput.value" class="input-group-append">
              <button
                class="btn btn-link px-2"
                type="button"
                [title]="'AbpUi::Clear' | abpLocalization"
                [disabled]="isModalBusy"
                (click)="
                  expirationDatePicker.writeValue(null);
                  expirationDatePicker.manualDateChange(null, true)
                "
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="showUploadButton && currentlyUploading === -1"
        class="d-flex justify-content-end align-center text-right mb-3"
      >
        <button
          class="btn btn-primary btn-sm margin-right"
          (click)="submitAttachments()"
          [disabled]="groupName.trim() === '' || isModalBusy || checkValidity"
        >
          <ng-container *ngIf="files.length === 1; else multiFiles">
            <span>
              {{ 'RegisterService::Attachments:AttachFile' | abpLocalization }}
            </span>
          </ng-container>
          <ng-template #multiFiles>
            <span>
              {{ 'RegisterService::Attachments:AttachNFiles' | abpLocalization : files.length }}
            </span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="showWebLink" class="mb-3">
    <div class="mb-3">
      <app-web-link
        #webLink
        ngDefaultControl
        [(ngModel)]="fileUrl"
        (webLinkChange)="onFileUrlChange($event)"
        (hideWebLink)="showWebLink = false"
      ></app-web-link>
    </div>
    <div
      *ngIf="showUploadButton && currentlyUploading === -1"
      class="d-flex justify-content-end align-center mt-2 text-right"
    >
      <button class="btn btn-primary btn-sm margin-right" (click)="submitAttachments()">
        <span>
          {{ '::Attach link' | abpLocalization }}
        </span>
      </button>
    </div>
  </div>
</div>
