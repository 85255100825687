import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import {
  AssetDetailsDto,
  AssetTransferDetailsDto,
  AssetService,
} from '@proxy/register-service/assets';

@Component({
  selector: 'app-asset-details-transfer-history',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './transfer-history.component.html',
  styleUrls: ['./transfer-history.component.scss'],
})
export class AssetTransferHistoryComponent implements OnInit {
  @Input() asset: AssetDetailsDto;

  data: PagedResultDto<AssetTransferDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  constructor(public readonly list: ListService, public readonly service: AssetService) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getAssetTransferHistory({
        ...query,
        id: this.asset.id,
        maxResultCount: 10,
      });

    const setData = (list: PagedResultDto<AssetTransferDetailsDto>) => {
      this.data = list;
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }
}
