import { ConfirmationService } from '@abp/ng.theme.shared';
import type {
  AssignedUrlsDto,
  GetAssignedSlugsInput,
  SlugDto,
  TenancyDto,
  TenancyUpdateDto,
} from './models';
import {
  ABP,
  EnvironmentService,
  PagedAndSortedResultRequestDto,
  PagedResultDto,
  RestService,
} from '@abp/ng.core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookupDto } from '@proxy/shared';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenancyService {
  apiName = 'AdministrationService';
  urlPrefix = '/api/admin/tenancy';
  smartcodePrefix = '/api/admin/smartcode';

  get = () =>
    this.restService.request<any, TenancyDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
      },
      { apiName: this.apiName }
    );

  getSlugsCount = () =>
    this.restService.request<any, { assigned: number; available: number }>(
      {
        method: 'GET',
        url: this.smartcodePrefix,
      },
      { apiName: this.apiName }
    );

  getPasswordPrivacy = () =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/is-required`,
      },
      { apiName: this.apiName }
    );

  getTwoStepStatus = (assetId?: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/two-step${assetId ? `?assetId=${assetId}` : ''}`,
      },
      { apiName: this.apiName }
    );

  updateTwoStepStatus = () =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/two-step-status`,
      },
      { apiName: this.apiName }
    );

  getRequestApproveStatus = () =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/is-approval`,
      },
      { apiName: this.apiName }
    );

  updateApprovalStatus = () =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/is-approval`,
      },
      { apiName: this.apiName }
    );

  getPassword = () =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/assetPassword`,
        responseType: 'text',
      },
      { apiName: this.apiName }
    );

  updatePasswordPrivacy = () =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/privacy-status`,
      },
      { apiName: this.apiName }
    );

  updatePassword = (newAssetPassword: string) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/asset-password/${newAssetPassword}`,
      },
      { apiName: this.apiName }
    );

  getLookup = () =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
      },
      { apiName: this.apiName }
    );

  getLogo = () =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/logo`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  getLogoUrl = (path: string) => this.environment.getApiUrl(this.apiName) + '/api/admin/' + path;

  getAssignTableData = (input: GetAssignedSlugsInput) =>
    this.restService.request<any, PagedResultDto<AssignedUrlsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/list`,
        params: {
          term: input.term,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  generateMoreSlugs = (count: number = 0) =>
    this.restService.request<any, SlugDto[]>(
      {
        method: 'POST',
        url: `${this.smartcodePrefix}/generateMore`,
        params: {
          count: count,
        },
      },
      { apiName: this.apiName }
    );

  getFile = (fileId: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${fileId}/assigned`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  assignFileToTenant = (tenantId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tenantId', tenantId);

    return this.http
      .post(`${this.environment.getApiUrl(this.apiName)}${this.smartcodePrefix}/assign`, formData, {
        responseType: 'blob',
      })
      .pipe(
        catchError(error => {
          return !!this.isBlobError(error) ? this.parseErrorBlob(error) : throwError(error);
        })
      );
  };

  exportSlugs = (fileName: string, count: number = 0) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.smartcodePrefix}/export`,
        params: {
          fileName: fileName,
          count: count,
        },
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  deleteLogo = () =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/logo`,
      },
      { apiName: this.apiName }
    );

  update = (input: TenancyUpdateDto) => {
    var formdata = new FormData();
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        const val = input[key];
        formdata.append(key, val != null ? val : '');
      }
    }

    return this.restService.request<any, TenancyDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}`,
        body: formdata,
      },
      { apiName: this.apiName }
    );
  };

  generateRandomPassword(
    length: number,
    numbers = false,
    symbols = false,
    uppercase = false,
    lowercase = false
  ) {
    let characters = '';
    if (numbers) {
      characters += '0123456789';
    }
    if (symbols) {
      characters += '!@#$%^&*()_+[]{}|;:,.<>?';
    }
    if (uppercase) {
      characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (lowercase) {
      characters += 'abcdefghijklmnopqrstuvwxyz';
    }

    if (characters.length === 0) {
      throw new Error('At least one character set must be selected.');
    }

    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  handleErrorResponse(error: any, categoryMsg: string): void {
    this.confirmation.error(error.error.error.message, categoryMsg, {
      cancelText: 'OK',
      hideYesBtn: true,
    });
    return;
  }

  isBlobError(err: any) {
    return (
      err instanceof HttpErrorResponse &&
      err.error instanceof Blob &&
      err.error.type === 'application/json'
    );
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = e => {
        observer.error(
          new HttpErrorResponse({
            ...err,
            error: JSON.parse(reader.result as string),
          })
        );
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  constructor(
    private restService: RestService,
    private environment: EnvironmentService,
    private confirmation: ConfirmationService,
    private http: HttpClient
  ) {}
}
