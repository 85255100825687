import { BaseNode, TreeAdapter } from '@abp/ng.components/tree';
import { ConfigStateService, generatePassword, ListService, PagedResultDto } from '@abp/ng.core';
import {
  Confirmation,
  ConfirmationService,
  getPasswordValidators,
  ToasterService,
} from '@abp/ng.theme.shared';
import {
  ePropType,
  EXTENSIONS_IDENTIFIER,
  FormProp,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.theme.shared/extensions';
import {
  Component,
  Injector,
  OnInit,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  GetIdentityUsersInput,
  IdentityRoleDto,
  IdentityRoleService,
  IdentityUserDto,
  IdentityUserService,
  IdentityUserUpdatePasswordInput,
  OrganizationUnitDto,
  OrganizationUnitService,
  OrganizationUnitWithDetailsDto,
} from '@volo/abp.ng.identity/proxy';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { eIdentityComponents, UsersComponent } from '@volo/abp.ng.identity';
import { identityTwoFactorBehaviourOptions } from '@volo/abp.ng.identity';
import { ClaimModalComponent } from '@volo/abp.ng.identity';
import { CostCentreService } from '@proxy/register-service/cost-centre';
import { ModifiedUserDto, TenancyService } from '@proxy/administration-service/tenancy';
import { StaffService } from '@proxy/servicing-service/staffs/staff.service';
import {
  CostCentreTreeNode,
  mapCostCentresTreeNode,
} from '@/cost-centre/models/cost-centre-tree-node.model';
import { CostCentreDto } from '@proxy/register-service/cost-centre';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.Users,
    },
    {
      provide: UsersComponent,
      useExisting: UserComponent,
    },
  ],
  styles: [
    `
      .mh-35 {
        max-height: 35px;
      }
    `,
  ],
  entryComponents: [ClaimModalComponent],
})
export class UserComponent implements OnInit {
  data: PagedResultDto<IdentityUserDto> = { items: [], totalCount: 0 };

  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;
  form: FormGroup;
  setPasswordForm: FormGroup;

  selected: IdentityUserDto;
  modifiedSelected: ModifiedUserDto;
  selectedUserRoles: IdentityRoleDto[];
  roles: IdentityRoleDto[];
  selectedOrganizationUnits: OrganizationUnitDto[];
  visiblePermissions = false;
  providerKey: string;
  isModalVisible: boolean;
  isSetPasswordModalVisible: boolean;
  modalBusy = false;
  visibleClaims = false;
  claimSubject = {} as { id: string; type: 'roles' | 'users' };
  filters = {} as GetIdentityUsersInput;
  checkedKeys: string[] = [];
  organization = {
    response: {} as PagedResultDto<OrganizationUnitWithDetailsDto>,
    nodes: [],
    checkedKeys: [],
    expandedKeys: [],
    selectFn: () => false,
  };
  randomlyGeneratedPin: string;
  keyringPassword: string = '';
  isLockModalVisible: boolean;
  restrictedEvents: string[] = [];
  twoFactor = {
    isModalVisible: false,
    checkboxValue: false,
    isOptional: false,
  };
  qrImageSrc: string | ArrayBuffer;
  lockForm: FormGroup;
  restrictedKeys: string[] = [];

  selectedRole = '';
  costCentres: PagedResultDto<CostCentreTreeNode> = {
    items: [],
    totalCount: 0,
  };

  private _isCostCentre = false;

  get isCostCentre(): boolean {
    return this._isCostCentre;
  }

  set isCostCentre(value: boolean) {
    if (value && !this.costCentres.totalCount) {
      this.getCostCentreData();
    }
    if (!value) this.checkedKeys = this.selected?.extraProperties?.costCentres || [];

    this._isCostCentre = value;
  }

  dateTimePickerProps = {
    defaultValue: new Date(),
    displayName: 'AbpIdentity::DisplayName:LockoutEnd',
    validators: () => [Validators.required],
    name: 'lockoutEnd',
    id: 'lockout-end',
    type: ePropType.DateTime,
  } as Partial<FormProp>;

  trackByFn: TrackByFunction<AbstractControl> = (index, item) => Object.keys(item)[0] || index;

  get roleGroups(): FormGroup[] {
    return ((this.form.get('roleNames') as FormArray)?.controls as FormGroup[]) || [];
  }

  constructor(
    public readonly list: ListService<GetIdentityUsersInput>,
    public confirmationService: ConfirmationService,
    public service: IdentityUserService,
    public staffService: StaffService,
    public costService: CostCentreService,
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public injector: Injector,
    public configState: ConfigStateService,
    public tenancyService: TenancyService,
    public roleService: IdentityRoleService,
    public organizationUnitService: OrganizationUnitService
  ) {}

  ngOnInit() {
    const { key } = identityTwoFactorBehaviourOptions[0];
    this.twoFactor.isOptional =
      this.configState.getFeature('Identity.TwoFactor') === key &&
      this.configState.getSetting('Abp.Identity.TwoFactor.Behaviour') === key;

    this.hookToQuery();
    this.setPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, ...getPasswordValidators(this.injector)]],
    });

    this.lockForm = this.fb.group({
      lockoutEnd: [new Date(), [Validators.required]],
    });
  }

  onVisiblePermissionChange = (value: boolean) => {
    this.visiblePermissions = value;
  };

  onRegenerate() {
    this.generateKeyringPassword();
    this.loadImage();
  }

  generatePin() {
    const extraProperties = this.form.get('extraProperties').value;
    extraProperties.pin = this.tenancyService.generateRandomPassword(4, true);
    this.form.get('extraProperties').setValue(extraProperties);
  }

  generateKeyringPassword() {
    this.keyringPassword = this.tenancyService.generateRandomPassword(16, true, true, true, true);
  }

  loadImage(): void {
    if (this.keyringPassword) {
      this.staffService.getQRPictureUrl(this.keyringPassword).subscribe((response: Blob) => {
        const reader = new FileReader();
        reader.onload = () => (this.qrImageSrc = reader.result);
        reader.readAsDataURL(response);
      });
    }
  }

  clearFilters() {
    this.filters = {} as GetIdentityUsersInput;
    this.list.get();
  }

  private hookToQuery() {
    this.list
      .hookToQuery(query => this.service.getList({ ...query, ...this.filters }))
      .subscribe(res => (this.data = res));
  }

  buildForm() {
    const data = new FormPropData(this.injector, this.selected);
    this.form = generateFormFromProps(data);

    this.service.getAssignableRoles().subscribe(({ items }) => {
      this.roles = items;
      this.form.addControl(
        'roleNames',
        this.fb.array(
          this.roles.map(role =>
            this.fb.group({
              [role.name]: [
                this.selected.id
                  ? !!this.selectedUserRoles?.find(userRole => userRole.id === role.id)
                  : role.isDefault,
              ],
            })
          )
        )
      );
    });

    this.service.getAvailableOrganizationUnits().subscribe(res => {
      this.organization.response = res;
      this.organization.nodes = new TreeAdapter(res.items as BaseNode[]).getTree();
      this.organization.expandedKeys = res.items.map(item => item.id);
      this.organization.checkedKeys = this.selectedOrganizationUnits.map(unit => unit.id);
    });
  }

  getCostCentreData = () => {
    this.modalBusy = true;
    return this.costService
      .getList({
        skipCount: 0,
        maxResultCount: 100,
      })
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe({
        next: (list: PagedResultDto<CostCentreDto>) => {
          this.costCentres = new PagedResultDto<CostCentreTreeNode>({
            totalCount: list.totalCount,
            items: mapCostCentresTreeNode(list.items),
          });
        },
      });
  };

  clearSelectedData() {
    this.checkedKeys = [];
    this.restrictedEvents = [];
  }

  openModal() {
    this.buildForm();
    this.isModalVisible = true;
    if (!this.selected.id) this.clearSelectedData();

    !this.selected?.extraProperties?.keyringPassword && this.generateKeyringPassword();
    !this.selected.extraProperties?.pin && this.generatePin();

    this.loadImage();
  }

  onAdd() {
    this.selected = {} as IdentityUserDto;
    this.selectedUserRoles = [];
    this.selectedOrganizationUnits = [];
    this.openModal();
  }

  onEdit(id: string) {
    this.clearSelectedData();

    this.service
      .get(id)
      .pipe(
        // tap(selectedUser => (this.selected = selectedUser)),
        // switchMap(() => this.service.getRoles(id)),
        tap(selectedUser => {
          this.selected = selectedUser;
          this.keyringPassword = selectedUser.extraProperties?.keyringPassword || '';

          if (selectedUser.extraProperties?.restrictedLocations) {
            this.restrictedKeys = selectedUser.extraProperties.restrictedLocations;
          }
          if (selectedUser.extraProperties?.restrictedEvents) {
            this.restrictedEvents = selectedUser.extraProperties.restrictedEvents;
          }
          this.isCostCentre = !!selectedUser.extraProperties?.costCentres?.length;
          if (selectedUser.extraProperties?.costCentres) {
            this.checkedKeys = selectedUser.extraProperties.costCentres;
          }
        }),
        switchMap(() => this.service.getRoles(id)),
        tap(res => (this.selectedUserRoles = res.items || [])),
        switchMap(() => this.service.getOrganizationUnits(id)),
        tap(res => (this.selectedOrganizationUnits = res)),
        take(1)
      )
      .subscribe(() => this.openModal());
  }

  exportFile() {
    this.staffService
      .credentialsExport({
        getFromUsers: true,
        skipCount: 0,
        maxResultCount: 100,
      })
      .subscribe({
        next: blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'file' + '.xls';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        },
        error: err => {
          console.error('Error downloading the file', err);
        },
      });
  }

  save() {
    if (!this.form.valid) return;
    this.modalBusy = true;

    const { id } = this.selected;
    const { roleNames } = this.form.value;
    const extraProperties = this.form.get('extraProperties').value;

    const mappedRoleNames =
      roleNames?.filter(role => !!role[Object.keys(role)[0]])?.map(role => Object.keys(role)[0]) ||
      [];

    (id
      ? this.service.update(id, {
          ...this.selected,
          ...this.form.value,
          roleNames: mappedRoleNames,
          organizationUnitIds: this.organization.checkedKeys,
          extraProperties: {
            ...extraProperties,
            keyringPassword: this.keyringPassword,
            restrictedLocations: this.isCostCentre ? [] : this.restrictedKeys,
            costCentres: this.isCostCentre ? this.checkedKeys : [],
            restrictedEvents: this.restrictedEvents,
          },
        })
      : this.service.create({
          ...this.form.value,
          roleNames: mappedRoleNames,
          organizationUnitIds: this.organization.checkedKeys,
          extraProperties: {
            ...extraProperties,
            keyringPassword: this.keyringPassword,
            restrictedLocations: this.isCostCentre ? [] : this.restrictedKeys,
            costCentres: this.isCostCentre ? this.checkedKeys : [],
            restrictedEvents: this.restrictedEvents,
          },
        })
    ).subscribe({
      next: () => {
        this.list.get();
        this.isModalVisible = false;
        this.isCostCentre = true;
        this.toasterService.success('IdentityService::UserSaved');
        this.modalBusy = false;
      },
      error: () => {
        this.modalBusy = false;
      },
    });
  }

  delete(id: string, userName: string) {
    this.confirmationService
      .warn('AbpIdentity::UserDeletionConfirmationMessage', 'AbpIdentity::AreYouSure', {
        messageLocalizationParams: [userName],
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.service.delete(id).subscribe(() => this.list.get());
        }
      });
  }

  onManageClaims(id: string) {
    this.claimSubject = {
      id,
      type: 'users',
    };

    this.visibleClaims = true;
  }

  unlock(id: string) {
    this.service.unlock(id).subscribe(() => {
      this.toasterService.success('AbpIdentity::UserUnlocked');
      this.list.get();
    });
  }

  openPermissionsModal(providerKey: string) {
    this.providerKey = providerKey;
    setTimeout(() => {
      this.visiblePermissions = true;
    }, 0);
  }

  setPassword() {
    if (this.setPasswordForm.invalid) return;

    this.modalBusy = true;
    this.service
      .updatePassword(
        this.selected.id,
        this.setPasswordForm.value as IdentityUserUpdatePasswordInput
      )
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isSetPasswordModalVisible = false;
        this.selected = {} as IdentityUserDto;
        this.setPasswordForm.reset();
      });
  }

  generatePassword() {
    this.setPasswordForm.get('newPassword').setValue(generatePassword());
  }

  lock() {
    const { lockoutEnd } = this.lockForm.value;

    this.modalBusy = true;
    this.service
      .lock(this.selected.id, lockoutEnd.toLocalISOString())
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isLockModalVisible = false;
        this.lockForm.reset({
          lockoutEnd: new Date(),
        });
        this.list.get();
      });
  }

  setTwoFactor() {
    this.modalBusy = true;
    this.service
      .setTwoFactorEnabled(this.selected.id, this.twoFactor.checkboxValue)
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => (this.twoFactor.isModalVisible = false));
  }

  isManager(): boolean {
    return this.roleGroups.some(group => group.value.manager === true);
  }

  onRoleClick(event: any, name: string) {
    this.selectedRole = name;
    if (name !== 'manager') {
      this.isCostCentre = false;
    }

    for (const roleGroup of this.roleGroups) {
      const val = roleGroup.value;
      roleGroup.setValue({
        [Object.keys(val)[0]]: Object.keys(val)[0] === name && event.target.checked,
      });
    }
  }

  get isStaff() {
    return true;
  }
}
