import { BaseNode } from '@abp/ng.components/tree';
import { CostCentreDto } from '@proxy/register-service/cost-centre';

export class CostCentreTreeNode extends BaseNode {
  email?: string;
  notes?: string;
  totalCount?: number;
  unit?: number;
}

export function mapCostCentresTreeNode(dtos: CostCentreDto[]): CostCentreTreeNode[] {
  if (!dtos) return [];

  return dtos.map(mapCostCentreTreeNode);
}

export function mapCostCentreTreeNode(dto: CostCentreDto): CostCentreTreeNode {
  const node = new CostCentreTreeNode(dto.id, dto.parentId);
  node.totalCount = dto.locationCount;
  node.name = dto.name;
  node.email = dto.email;
  node.notes = dto.notes;
  return node;
}
