<ng-template [ngIf]="value" [ngIfThen]="yes" [ngIfElse]="no"></ng-template>

<ng-template #yes>
  <div class="text-success">
    <span [title]="'AbpUi::Yes' | abpLocalization">
      <i class="fa fa-check" aria-hidden="true"></i>
    </span>
  </div>
</ng-template>

<ng-template #no>
  <div class="text-danger">
    <span [title]="'AbpUi::No' | abpLocalization">
      <i class="fa fa-times" aria-hidden="true"></i>
    </span>
  </div>
</ng-template>
