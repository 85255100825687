import { finalize } from 'rxjs';
import { TreeAdapter } from '@abp/ng.components/tree';
import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetDetailsDto } from '@proxy/register-service/assets';
import { LocationDto, LocationService } from '@proxy/register-service/locations';
import { LocationDefinition } from '@register/proxy/register-service/locations';
import { LocationTreeNode } from '@/locations/models/location-tree-node.model';

@Component({
  selector: 'app-jobsite-picker',
  templateUrl: './jobsite-picker.component.html',
  styleUrls: ['./jobsite-picker.component.scss'],
})
export class JobsitePickerComponent {
  @Input() form: FormGroup;
  @Input() asset: AssetDetailsDto;
  @Input() editing: boolean;
  @Input() isRequired: boolean;
  @Input() isLoading: boolean;
  @Output() isLoadingChange = new EventEmitter<boolean>();

  isJobsiteModalOpen = false;
  locationDefinition = LocationDefinition;
  expandedKeys: any[] = [];
  checkedKeys: any[] = [];
  nodes: LocationTreeNode[];
  functionForTree = {
    selectFn: () => false,
  };
  data: PagedResultDto<LocationDto> = {
    items: [],
    totalCount: 0,
  };
  treeAdapter: TreeAdapter<LocationTreeNode>;
  jobsiteNames: string[] = [];

  constructor(
    public readonly list: ListService,
    private fb: FormBuilder,
    public readonly locationService: LocationService
  ) {}

  formatJobsiteNames() {
    this.jobsiteNames = [];

    this.checkedKeys.forEach(jobsiteId => {
      this.locationService.get(jobsiteId).subscribe(jobsite => {
        this.jobsiteNames.push(jobsite.fullName);
      });
    });

    const jobsiteIdsFormArray = this.form.get('jobsiteIds') as FormArray;

    jobsiteIdsFormArray.clear();
    this.checkedKeys.forEach(id => jobsiteIdsFormArray.push(this.fb.control(id)));

    this.isJobsiteModalOpen = false;
  }

  convertSetToArray(set: Set<any>): any[] {
    return [...set];
  }

  getJobsites() {
    const getData = (query: ABP.PageQueryParams) => {
      this.isLoadingChange.emit(true);

      return this.locationService
        .getList({
          ...query,
          locationDefinition: LocationDefinition.JOBSITE,
          term: query.filter,
          skipCount: 0,
          maxResultCount: 100,
        })
        .pipe(finalize(() => this.isLoadingChange.emit(false)));
    };

    const setData = (list: PagedResultDto<LocationTreeNode>) => {
      this.data = list;
      this.treeAdapter = new TreeAdapter(list.items);
      this.nodes = this.treeAdapter.getTree();
      this.expandedKeys = [...this.expandedKeys];
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }
}
