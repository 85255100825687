import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-ring',
  templateUrl: './progress-ring.component.svg',
  styles: [`circle {
              transition: stroke-dashoffset 0.35s;
              transform: rotate(-90deg);
              transform-origin: 50% 50%;
            }
          `]
})
export class ProgressRingComponent {
  @Input() primary: string;
  @Input() secondary: string;
  @Input() radius: number;
  @Input() progress: number;
  @Input() stroke: number;

  get normalizedRadius() { return this.radius - this.stroke * 2; }
  get circumference() { return this.normalizedRadius * 2 * Math.PI; }

  strokeDashoffset() {
    return this.circumference - (this.progress / 100) * this.circumference;
  }
}
