<!-- <p>It works!</p> -->
<abp-page [title]="'QueueService::Queue' | abpLocalization">
  <abp-advanced-entity-filters localizationSourceName="QueueService" [list]="list">
    <abp-advanced-entity-filters-form>
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="assetPrimaryIDFilter">
                {{ 'QueueService::AssetPrimaryID' | abpLocalization }}
              </label>
              <input
                id="assetPrimaryIDFilter"
                class="form-control"
                [(ngModel)]="filters.assetPrimaryID"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="assetSecondaryIDFilter">
                {{ 'QueueService::AssetSecondaryID' | abpLocalization }}
              </label>
              <input
                id="assetSecondaryIDFilter"
                class="form-control"
                [(ngModel)]="filters.assetSecondaryID"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="categoryNicknameFilter">
                {{ 'ServicingService::Nickname' | abpLocalization }}
              </label>
              <input
                id="categoryNicknameFilter"
                class="form-control"
                [(ngModel)]="filters.categoryNickname"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              />
            </div>
          </div>
          <div class="col-6 col-sm-auto d-flex align-items-end">
            <div class="mb-3">
              <app-category-picker
                (nodeSelected)="setCategoryFilter($event)"
                [name]="categoryName"
                #categoryPicker
              />
            </div>
          </div>
          <div class="col-6 col-sm-auto d-flex align-items-end">
            <div class="mb-3">
              <app-location-picker
                [title]="'Home'"
                [displayName]="'Home'"
                [name]="homeLocationName"
                (nodeSelected)="setLocationId($event)"
                [locationDefinition]="locationDefinition.HOME"
                #locationPicker
              />
            </div>
          </div>
          <div class="col-6 col-sm-auto d-flex align-items-end">
            <div class="mb-3">
              <app-location-picker
                [title]="'Secondary'"
                [displayName]="'Secondary'"
                [name]="secondaryLocationName"
                (nodeSelected)="setLocationId($event)"
                [locationDefinition]="locationDefinition.SECONDARY"
                #locationPicker
              />
            </div>
          </div>
          <div class="col-6 col-sm-auto d-flex align-items-end">
            <div class="mb-3">
              <app-location-picker
                [title]="'Jobsites'"
                [displayName]="'Jobsites'"
                [name]="jobsiteLocationName"
                (nodeSelected)="setLocationId($event)"
                [locationDefinition]="locationDefinition.JOBSITE"
                #locationPicker
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="isActiveFilter">
                {{ 'ServicingService::IsActive' | abpLocalization }}
              </label>

              <select
                name="isActive"
                id="isActiveFilter"
                class="form-select form-control"
                [(ngModel)]="filters.isActive"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="true">{{ 'AbpUi::Yes' | abpLocalization }}</option>
                <option [ngValue]="false">{{ 'AbpUi::No' | abpLocalization }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>

  <div class="card">
    <div class="card-body queue-datatable">
      <h4 class="queue-assets-header">{{ 'QueueService::AssetsInQueue' | abpLocalization }}</h4>
      <ngx-datatable
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        [loadingIndicator]="isBusy"
        [selected]="selected"
        [selectionType]="SelectionType.checkbox"
        [selectAllRowsOnPage]="true"
        (select)="onSelect($event)"
        (page)="onPageChange()"
        default
      >
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn"
          >
            <label class="datatable-checkbox">
              <input
                type="checkbox"
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
              />
            </label>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-row="row">
            <label class="datatable-checkbox">
              <input
                type="checkbox"
                [checked]="isSelected(row)"
                (change)="onSelectSubAssets($event, row)"
              />
            </label>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="20" cellClass="!flex justify-center" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div
              *ngIf="row.parentId || row.isParent"
              class="flex flex-col items-center justify-center h-4 min-w-4 rounded-full overflow-hidden border-2 border-black"
            >
              <div class="w-full h-1/2" [ngClass]="row.isParent ? 'bg-black' : 'bg-white'"></div>
              <div class="w-full h-1/2 bg-black"></div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="assetPrimaryID" [sortable]="false">
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <span class="datatable-header-cell-label draggable">{{
                'QueueService::AssetPrimaryID' | abpLocalization
              }}</span>
            </div>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.assetPrimaryID }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::AssetSecondaryID' | abpLocalization }}"
          prop="assetSecondaryID"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.assetSecondaryID }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'RegisterService::Category' | abpLocalization }}"
          prop="categoryName"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.categoryFullName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'RegisterService::Nickname' | abpLocalization }}"
          prop="name"
          [sortable]="false"
        >
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <button
                type="button"
                class="btn btn-sm custom-btn text-white header-buttons mb-2"
                [disabled]="!selected.length"
                (click)="onModalOpen('reserve')"
              >
                {{ 'AbpUi::Reserve' | abpLocalization }}
              </button>
              <span class="datatable-header-cell-label draggable">{{
                'RegisterService::Nickname' | abpLocalization
              }}</span>
            </div>
          </ng-template>

          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.categoryNickname }}
          </ng-template>
        </ngx-datatable-column>

        <!-- <ngx-datatable-column
          name="{{ 'QueueService::SiteName' | abpLocalization }}"
          prop="siteName"
          [sortable]="false"
        >
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <button
                type="button"
                class="btn btn-sm btn-primary text-white header-buttons mb-2"
                (click)="onModalOpen('reserve')"
              >
                {{ 'AbpUi::Reserve' | abpLocalization }}
              </button>
              <span class="datatable-header-cell-label draggable">{{
                'QueueService::SiteName' | abpLocalization
              }}</span>
            </div>
          </ng-template>

          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteName }}
          </ng-template>
        </ngx-datatable-column> -->

        <ngx-datatable-column prop="homeLocation" [sortable]="false">
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <button
                *abpPermission="'RegisterService.Assets.Move'"
                type="button"
                [disabled]="!selected.length"
                class="btn btn-sm bg-warning header-buttons mb-2"
                (click)="onModalOpen('move')"
              >
                {{ 'AbpUi::Move' | abpLocalization }}
              </button>
              <span class="datatable-header-cell-label draggable">{{
                'QueueService::Home Location' | abpLocalization
              }}</span>
            </div>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="form-check flex items-center gap-1">
              <input
                type="checkbox"
                class="form-check-input min-w-[1.25em]"
                [id]="row.id"
                [disabled]="!isTopLevel(row)"
                (change)="
                  selectParents(
                    $event,
                    row.homeLocationName,
                    row.homeLocationId,
                    row.locationGroupId
                  )
                "
                [checked]="
                  locations.includes(row.homeLocationId) || topLevels.includes(row.homeLocationId)
                "
              />
              <label class="form-check-label" [attr.for]="row.id">
                {{ row.homeLocationName }}
              </label>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="secondaryLocation" [sortable]="false">
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <button
                type="button"
                [disabled]="!selected.length"
                class="btn btn-sm bg-danger text-white header-buttons mb-2"
                (click)="onModalOpen('checkout')"
              >
                {{ 'AbpUi::Checkout' | abpLocalization }}
              </button>
              <span class="datatable-header-cell-label draggable">{{
                'QueueService::Secondary Location' | abpLocalization
              }}</span>
            </div>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.secondaryLocationName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="jobsite" [width]="100" [sortable]="false">
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <div class="jobsite-buttons mb-2">
                <button
                  type="button"
                  [disabled]="!selected.length"
                  class="btn btn-sm bg-success text-white me-2 header-buttons"
                  (click)="onModalOpen('allocate')"
                >
                  {{ 'AbpUi::Allocate' | abpLocalization }}
                </button>
              </div>
              <span class="datatable-header-cell-label draggable">{{
                'QueueService::Jobsite' | abpLocalization
              }}</span>
            </div>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <p *ngIf="!row.isDeallocated">
              {{ row.jobsiteNames.join(', ') }}
            </p>
          </ng-template>
        </ngx-datatable-column>

        <!-- <ngx-datatable-column prop="jobsite" [width]="100" [sortable]="false">
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <div class="jobsite-buttons mb-2">
                <button
                  type="button"
                  class="btn btn-sm bg-gray text-white header-buttons"
                  (click)="onModalOpen('deallocate')"
                  [disabled]="deallocationButtonAvailability()"
                >
                  {{ 'AbpUi::Deallocate' | abpLocalization }}
                </button>
              </div>
              <span class="datatable-header-cell-label draggable">{{
                'QueueService::Jobsite' | abpLocalization
              }}</span>
            </div>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <p *ngIf="row.isDeallocated">
              {{ row.jobsiteNames.join(', ') }}
            </p>
          </ng-template>
        </ngx-datatable-column> -->

        <ngx-datatable-column prop="isActive" [sortable]="false" [width]="100">
          <ng-template ngx-datatable-header-template>
            <div class="d-flex flex-column justify-content-center">
              <button
                *abpPermission="'RegisterService.Assets.ToggleActivity'"
                type="button"
                [disabled]="!selected.length"
                class="btn btn-sm bg-gray header-buttons mb-2"
                (click)="onModalOpen('status')"
              >
                {{ 'AbpUi::Edit' | abpLocalization }}
              </button>
              <span class="datatable-header-cell-label draggable">{{
                'RegisterService::IsActive' | abpLocalization
              }}</span>
            </div>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-yes-no-display [value]="row.isActive"></app-yes-no-display>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="220" [sortable]="false">
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <div class="d-flex me-3">
              <button
                type="button"
                class="btn btn-light btn-sm details-button me-2"
                (click)="onView(row)"
              >
                {{ 'AbpUi::Details' | abpLocalization }}
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm details-button"
                (click)="onDequeue(row)"
              >
                {{ 'AbpUi::Remove' | abpLocalization }}
              </button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  <abp-card-footer>
    <div class="modal-footer mb-5 mb-md-4">
      <abp-button
        buttonClass="btn btn-primary"
        [disabled]="!data.items.length"
        iconClass="fa fa-check"
        (click)="inspectAll()"
      >
        {{ 'AbpUi::Event All' | abpLocalization }}
      </abp-button>
      <abp-button
        buttonClass="btn btn-danger"
        iconClass="fa fa-trash-alt"
        (click)="onDequeue()"
        [disabled]="!selected.length"
      >
        {{ 'AbpUi::Remove Selected' | abpLocalization }}
      </abp-button>
    </div>
  </abp-card-footer>
</abp-page>

<app-queue-modals
  *ngIf="isModalOpen"
  [type]="modalType"
  [assets]="convertedData"
  [isModalOpen]="isModalOpen"
  [selected]="convertedSelectedData"
  [showMessage]="topLevels.length"
  (modalClose)="onModalClose()"
></app-queue-modals>
