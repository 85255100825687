import { BaseNode } from '@abp/ng.components/tree';
import { LocationDefinition, LocationDto } from '@register/proxy/register-service/locations';

export class LocationTreeNode extends BaseNode {
  fullName?: string;
  isActive?: boolean;
  locationDefinition?: LocationDefinition;
  notes?: string;
  path?: string;
  pictureUrl?: string;
  siteId?: string;
}

export function mapLocationsTreeNode(dtos: LocationDto[]): LocationTreeNode[] {
  if (!dtos) return [];

  return dtos.map(mapLocationTreeNode);
}

export function mapLocationTreeNode(dto: LocationDto): LocationTreeNode {
  const node = new LocationTreeNode(dto.id, dto.parentId);
  node.fullName = dto.fullName;
  node.id = dto.id;
  node.isActive = dto.isActive;
  node.locationDefinition = dto.locationDefinition;
  node.name = dto.name;
  node.parentId = dto.parentId;
  node.path = dto.path;
  node.siteId = dto.siteId;

  return node;
}
