import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetInspectionTypesInput,
  InspectionTypeCreateDto,
  InspectionTypeDto,
  InspectionTypeUpdateDto,
} from './models';
import { LookupDto, LookupRequestDto } from '@proxy/shared';
import { map } from 'rxjs/operators';
import {
  CheckGroupDefinitionCreateDto,
  CheckGroupDefinitionDto,
  CheckGroupDefinitionUpdateDto,
  GetCheckGroupDefinitionsInput,
} from './check-groups/models';
import {
  CheckDefinitionCreateDto,
  CheckDefinitionDto,
  CheckDefinitionUpdateDto,
  GetCheckDefinitionsInput,
} from './checks/models';
import { AttributeValueType } from '@proxy/register-service/attribute-definitions/attributes';
import { Observable } from 'rxjs';
import { EventTypeDefinition } from './inspection-type-definition.enum';

@Injectable({
  providedIn: 'root',
})
export class InspectionTypeService {
  apiName = 'ServicingService';
  urlPrefix = '/api/servicing/inspection-types';

  create = (input: InspectionTypeCreateDto) =>
    this.restService.request<any, InspectionTypeDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, InspectionTypeDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetInspectionTypesInput) =>
    this.restService.request<any, PagedResultDto<InspectionTypeDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          categoryId: input.categoryId,
          categoryPath: input.categoryPath,
          frequency: input.frequency,
          eventTypeDefinition: input.eventTypeDefinition,
          intervalMax: input.intervalMax,
          intervalMin: input.intervalMin,
          isActive: input.isActive,
          hasJobsites: input.hasJobsites,
          jobsiteIds: input.jobsiteIds,
          maxResultCount: input.maxResultCount,
          name: input.name,
          notes: input.notes,
          recurrence: input.recurrence,
          reportTemplate: input.reportTemplate,
          reportTitle: input.reportTitle,
          skipCount: input.skipCount,
          sorting: input.sorting,
          term: input.term,
        },
      },
      { apiName: this.apiName }
    );

  getTypes = (input: GetInspectionTypesInput) =>
    this.restService.request<any, InspectionTypeDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/types`,
        params: {
          assetId: input.assetId,
          categoryId: input.categoryId,
          eventTypeDefinition: input.eventTypeDefinition,
          hasJobsites: input.hasJobsites,
          jobsiteIds: input.jobsiteIds,
          pinOrKeyringPassword: input.pinOrKeyringPassword,
        },
      },
      { apiName: this.apiName }
    );

  getByCategoryId = (
    categoryId: string,
    eventTypeDefinition: EventTypeDefinition,
    assetId: string = '',
    pinOrKeyringPassword?: string
  ): Observable<InspectionTypeDto[]> =>
    this.getTypes({
      assetId: assetId,
      categoryId: categoryId,
      eventTypeDefinition: eventTypeDefinition,
      maxResultCount: 100,
      pinOrKeyringPassword,
    } as GetInspectionTypesInput);

  getByCategoryAndJobsitesId = (
    categoryId: string,
    jobsiteIds: string[],
    eventTypeDefinition: EventTypeDefinition,
    assetId: string = '',
    pinOrKeyringPassword?: string
  ): Observable<InspectionTypeDto[]> =>
    this.getTypes({
      assetId: assetId,
      categoryId: categoryId,
      eventTypeDefinition: eventTypeDefinition,
      jobsiteIds: jobsiteIds,
      hasJobsites: jobsiteIds.length !== 0,
      maxResultCount: 100,
      pinOrKeyringPassword,
    } as GetInspectionTypesInput);

  getLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
        params: {
          filter: input.filter,
          eventType: input.eventType,
          categoryId: input.categoryId,
          categoryPath: input.categoryPath,
          eventTypeDefinition: input.eventTypeDefinition,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: InspectionTypeUpdateDto) =>
    this.restService.request<any, InspectionTypeDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  createCheckGroup = (input: CheckGroupDefinitionCreateDto) =>
    this.restService.request<any, CheckGroupDefinitionDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${input.inspectionTypeId}/check-groups`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteCheckGroup = (typeId: string, name: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${typeId}/check-groups/${name}`,
      },
      { apiName: this.apiName }
    );

  getCheckGroup = (id: string) =>
    this.restService.request<any, CheckGroupDefinitionDto>(
      {
        method: 'GET',
        url: `/api/servicing/check-group-definitions/${id}`,
      },
      { apiName: this.apiName }
    );

  getListCheckGroup = (input: GetCheckGroupDefinitionsInput) =>
    this.restService.request<any, PagedResultDto<CheckGroupDefinitionDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${input.inspectionTypeId}/check-groups`,
        params: {
          term: input.term,
          text: input.description,
          orderMin: input.orderMin,
          orderMax: input.orderMax,
          hideOnReport: input.hideOnReport,
          isActive: input.isActive,
          inspectionTypeId: input.inspectionTypeId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getLookupCheckGroup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/servicing/check-group-definitions/inspection-type-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  updateCheckGroup = (input: CheckGroupDefinitionUpdateDto) =>
    this.restService.request<any, CheckGroupDefinitionDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${input.inspectionTypeId}/check-groups/${input.name}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  createCheck = (typeId: string, input: CheckDefinitionCreateDto<AttributeValueType>) =>
    this.restService.request<any, CheckDefinitionDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${typeId}/checks`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteCheck = (id: string, name: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/checks/${name}`,
      },
      { apiName: this.apiName }
    );

  getCheck = (id: string) =>
    this.restService.request<any, CheckDefinitionDto>(
      {
        method: 'GET',
        url: `/api/servicing/check-definitions/${id}`,
      },
      { apiName: this.apiName }
    );

  getListCheck = (input: GetCheckDefinitionsInput) =>
    this.restService.request<any, PagedResultDto<CheckDefinitionDto>>(
      {
        method: 'GET',
        url: `${input.inspectionTypeId}/checks`,
        params: {
          term: input.term,
          name: input.name,
          text: input.description,
          help: input.help,
          resultType: input.resultType,
          resultOptions: input.resultOptions,
          defaultResult: input.defaultResult,
          isRequired: input.isRequired,
          orderMin: input.orderMin,
          orderMax: input.orderMax,
          hideOnReport: input.hideOnReport,
          isActive: input.isActive,
          checkGroupName: input.checkGroupName,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          inspectionTypeId: input.inspectionTypeId,
        },
      },
      { apiName: this.apiName }
    );

  getLookupCheck = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/servicing/check-definitions/check-group-definition-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  updateCheck = (id: string, name: string, input: CheckDefinitionUpdateDto) =>
    this.restService.request<any, CheckDefinitionDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/checks/${name}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
