<abp-modal [(visible)]="openMoveModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::MoveAssets' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="mb-3">
      <app-locations-tree
        [isLoading]="isLoading"
        [readonly]="true"
        [selectedNode]="selectedLocation"
        (selectedNodeChange)="selectLocation($event)"
        [locationDefinition]="locationDefinition"
        [displayHeaders]="false"
      >
      </app-locations-tree>
    </div>
    <div class="flex gap-2" *ngIf="showMessage">
      <label class="text-black-base font-medium">
        {{ 'ServicingService::TotalSelected' | abpLocalization }}:
      </label>

      <label class="form-label"> {{ assets.length }} </label>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" (click)="move()" [disabled]="isBusy">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
