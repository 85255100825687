import type {
  GetLocationsInput,
  LocationCreateDto,
  LocationDetailsDto,
  LocationDto,
  LocationUpdateDto,
} from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AttachmentCreateDto,
  AttachmentDto,
  AttachmentUpdateDto,
  GetAttachmentsInput,
} from '../../attachment/models';
import type { FileStreamResult } from '../../microsoft/asp-net-core/mvc/models';
import type { LocationLookupRequestDto, LookupDto } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  apiName = 'Default';

  attach = (id: string, input: AttachmentCreateDto<string>, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto<string>>(
      {
        method: 'POST',
        url: `/api/register/locations/${id}/attachments`,
        body: input.file,
      },
      { apiName: this.apiName, ...config }
    );

  create = (input: LocationCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'POST',
        url: '/api/register/locations',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/locations/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  downloadAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileStreamResult>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/attachments/${attachmentId}/download`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto<string>>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/attachments/${attachmentId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttachments = (
    id: string,
    input: GetAttachmentsInput<string>,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, PagedResultDto<AttachmentDto<string>>>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/attachments`,
        params: {
          term: input.term,
          title: input.title,
          contentType: input.contentType,
          fileName: input.fileName,
          fileUrl: input.fileUrl,
          folder: input.folder,
          ownerType: input.ownerType,
          ownerId: input.ownerId,
          isActive: input.isActive,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getDetails = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDetailsDto>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/details`,
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetLocationsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LocationDto>>(
      {
        method: 'GET',
        url: '/api/register/locations',
        params: {
          term: input.term,
          name: input.name,
          addressCity: input.addressCity,
          addressStreet1: input.addressStreet1,
          addressStreet2: input.addressStreet2,
          addressPostCode: input.addressPostCode,
          addressRegion: input.addressRegion,
          addressCountry: input.addressCountry,
          email: input.email,
          positionLatitudeMin: input.positionLatitudeMin,
          positionLatitudeMax: input.positionLatitudeMax,
          positionLongitudeMin: input.positionLongitudeMin,
          positionLongitudeMax: input.positionLongitudeMax,
          isActive: input.isActive,
          tag: input.tag,
          siteId: input.siteId,
          parentId: input.parentId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getLookup = (input: LocationLookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/register/locations/lookup',
        params: {
          type: input.type,
          siteId: input.siteId,
          parentId: input.parentId,
          locationDefinition: input.locationDefinition,
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  removeAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/locations/${id}/attachments/${attachmentId}`,
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: LocationUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'PUT',
        url: `/api/register/locations/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateAttachment = (
    id: string,
    attachmentId: string,
    input: AttachmentUpdateDto<string>,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, AttachmentDto<string>>(
      {
        method: 'PUT',
        url: `/api/register/locations/${id}/attachments/${attachmentId}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
