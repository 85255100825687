import { Component, HostListener } from '@angular/core';
import { LogoComponent } from '@/components/layout/logo/logo.component';
import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
import { AuthService, ConfigStateService, ReplaceableComponentsService } from '@abp/ng.core';
import { UserComponent } from './users/user/user.component';
import { eIdentityComponents } from '@volo/abp.ng.identity';
import { PageComponent } from '@abp/ng.components/page';
import { ModalComponent, NavItemsService } from '@abp/ng.theme.shared';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { ApiDocsComponent } from './admin/api/api-docs.component';
import { ToolbarService } from '@volosoft/ngx-lepton-x/layouts';
import { QueueService } from '@proxy/queue-service';
import { Store } from '@ngrx/store';
import { State } from '@/store/models/state.model';
import { ThemeService, StyleNames, lightTheme } from '@volosoft/ngx-lepton-x';
import { UpdateQueueSizeAction } from '@/store/actions/queue.actions';
import { NavigationExtras, Router } from '@angular/router';
import { AppMobileNavbarComponent } from './components/layout/mobile/mobile-navbar.component';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
  `,
  entryComponents: [PageComponent, ModalComponent],
})
export class AppComponent {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private settingTabs: SettingTabsService,
    private configState: ConfigStateService,
    private auth: AuthService,
    private toolbar: ToolbarService,
    private queue: QueueService,
    private store: Store<State>,
    private themeService: ThemeService,
    private router: Router
  ) {
    this.replaceableComponents.add({
      component: AppMobileNavbarComponent,
      key: eThemeLeptonXComponents.NavbarMobile,
    });
    this.replaceableComponents.add({
      component: LogoComponent,
      key: eThemeLeptonXComponents.Logo,
    });
    this.replaceableComponents.add({
      component: UserComponent,
      key: eIdentityComponents.Users,
    });

    if (this.themeService.selectedStyle.styleName != StyleNames.Light) {
      this.themeService.setTheme(lightTheme);
    }

    this.configState.getOne$('currentUser').subscribe(user => {
      if (user.isAuthenticated) {
        // this.queue.size().subscribe(data => store.dispatch(new UpdateQueueSizeAction(data.size)));
        const currentPath = window.location.pathname;
        const url = new URL(window.location.href);
        const navigationExtras: NavigationExtras = {
          queryParams: {},
          queryParamsHandling: 'merge',
          fragment: url.hash,
          preserveFragment: true,
        };

        url.searchParams.forEach((value, key) => {
          navigationExtras.queryParams[key] = value;
        });

        this.router.navigate([currentPath === '/' ? '/home' : currentPath], navigationExtras);
      }
    });
  }

  get hasLoggedIn(): boolean {
    return this.auth.isAuthenticated;
  }

  onMove() {
    window.scrollTo({ top: 0 });
  }

  login() {
    this.auth.navigateToLogin();
  }
}
