<div class="w-100">
  <div class="mb-3" *ngIf="!hideSelect">
    <label class="form-label" for="siteIdFilter">
      {{ 'RegisterService::SelectArea' | abpLocalization }}
    </label>
    <abp-lookup-select
      cid="siteIdFilter"
      displayNameProp="name"
      [getFn]="siteService.getLookup"
      [(ngModel)]="siteId"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="onSiteIdChange($event)"
    ></abp-lookup-select>
  </div>
  <div class="mb-3" *ngIf="siteId || hideSelect">
    <app-locations-tree
      class="mb-3"
      [isLoading]="isLoading"
      [readonly]="readonly"
      [checkable]="checkable"
      [getAll]="getAll"
      [checkStrictly]="checkStrictly"
      [selectedNode]="selectedLocation"
      (selectedNodeChange)="selectLocation($event)"
      (restrictedKeysChange)="onCheckClick($event)"
      [siteId]="siteId"
      [restrictedKeys]="restrictedKeys"
      [isAllSelected]="checkStrictly"
      [treeTitle]="'Home'"
      [locationDefinition]="locationDefinition.HOME"
      [displayHeaders]="true"
      [hideCreate]="true"
    >
    </app-locations-tree>
    <app-locations-tree
      class="mb-3"
      [isLoading]="isLoading"
      [readonly]="readonly"
      [checkable]="checkable"
      [getAll]="getAll"
      [checkStrictly]="checkStrictly"
      [selectedNode]="selectedLocation"
      (selectedNodeChange)="selectLocation($event)"
      [siteId]="siteId"
      [isAllSelected]="checkStrictly"
      [treeTitle]="'Secondary'"
      [restrictedKeys]="restrictedKeys"
      (restrictedKeysChange)="onCheckClick($event)"
      [locationDefinition]="locationDefinition.SECONDARY"
      [displayHeaders]="true"
      [hideCreate]="true"
    >
    </app-locations-tree>
    <app-locations-tree
      class="mb-3"
      [isLoading]="isLoading"
      [readonly]="readonly"
      [checkable]="checkable"
      [getAll]="getAll"
      [checkStrictly]="checkStrictly"
      [selectedNode]="selectedLocation"
      (selectedNodeChange)="selectLocation($event)"
      (restrictedKeysChange)="onCheckClick($event)"
      [siteId]="siteId"
      [restrictedKeys]="restrictedKeys"
      [isAllSelected]="checkStrictly"
      [treeTitle]="'Jobsite'"
      [locationDefinition]="locationDefinition.JOBSITE"
      [displayHeaders]="true"
      [hideCreate]="true"
    >
    </app-locations-tree>
  </div>
</div>
