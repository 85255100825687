<div class="flex flex-col gap-1">
  <label class="form-label"> {{ 'RegisterService::' + title | abpLocalization }} </label>
  <ngx-datatable
    [rows]="data.items"
    [count]="data.totalCount"
    [list]="list"
    [scrollTop]="true"
    [loadingIndicator]="isLoading"
    default
  >
    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::ReqNum' | abpLocalization }}"
      prop="id"
      [width]="50"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.id }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::Category' | abpLocalization }}"
      prop="categoryName"
      [width]="170"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.categoryName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status === RequestStatus.ACTIONED"
      name="{{ 'RegisterService::ActionType' | abpLocalization }}"
      prop="notes"
      [sortable]="false"
      [width]="140"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ 'RegisterService::' + row?.actionType | abpLocalization }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status === RequestStatus.OPEN"
      name="{{ 'RegisterService::Notes' | abpLocalization }}"
      prop="notes"
      [sortable]="false"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.notes }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::Quantity' | abpLocalization }}"
      prop="quantity"
      [width]="80"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.quantity }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::TargetLocation' | abpLocalization }}"
      prop="targetLocationName"
      [width]="140"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.targetLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::From' | abpLocalization }}"
      prop="fromDate"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.isAsap ? ('RegisterService::ASAP' | abpLocalization) : (row?.fromDate | date) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::To' | abpLocalization }}"
      prop="endDate"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.period ? helpers.getDisplayText(row.period) : (row?.endDate | date) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status !== RequestStatus.OPEN"
      [sortable]="false"
      name="ID 1/2"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.primaryID ? row.primaryID + '/' : '' }}{{ row.secondaryID || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status !== RequestStatus.OPEN"
      [sortable]="false"
      name="{{ 'RegisterService::HomeLocation' | abpLocalization }}"
      prop="homeLocationName"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.homeLocationName || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status !== RequestStatus.OPEN"
      [sortable]="false"
      name="{{ 'RegisterService::SecondaryLocation' | abpLocalization }}"
      prop="secondaryLocationName"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.secondaryLocationName || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status !== RequestStatus.OPEN"
      [sortable]="false"
      name="{{ 'RegisterService::Jobsites' | abpLocalization }}"
      prop="jobsite"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.jobsite || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status !== RequestStatus.CLOSED"
      [sortable]="false"
      name="{{ 'RegisterService::Requester' | abpLocalization }}"
      prop="requester"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.requester || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status === RequestStatus.OPEN"
      [sortable]="false"
      name="{{ 'RegisterService::rawMessage' | abpLocalization }}"
      prop="rawMessage"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.rawMessage || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status !== RequestStatus.ACTIONED"
      [sortable]="false"
      name="{{ 'RegisterService::notify' | abpLocalization }}"
      prop="notifyOption"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ 'RegisterService::' + row.notifyOption | abpLocalization }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="status === RequestStatus.CLOSED"
      [sortable]="false"
      name="{{ 'RegisterService::closedBy' | abpLocalization }}"
      prop="closedBy"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.closedBy || '-' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="status === RequestStatus.OPEN ? 380 : 100" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="flex items-center gap-2" *ngIf="status === RequestStatus.OPEN; else closeIcon">
          <button
            type="button"
            *ngIf="isApproved"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5]"
            [ngClass]="row.isApproved ? 'text-blue' : 'text-[#6D767C]'"
            (click)="onApprove(row.id, row.isApproved)"
          >
            <i class="fas fa-thumbs-up fa-lg"></i>
          </button>
          <button
            *abpPermission="'RegisterService.Requests.Refuse'"
            type="button"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5] text-[#6D767C]"
            (click)="onRefuse(row.id)"
          >
            <i class="fas fa-ban fa-lg"></i>
          </button>
          <button
            *abpPermission="'RegisterService.Requests.Match'"
            type="button"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5] text-[#6D767C]"
            [disabled]="(isApproved && !row.isApproved) || !assetId"
            (click)="onMatch(row.id)"
          >
            {{ 'RegisterService::Match' | abpLocalization }}
          </button>
          <button
            *abpPermission="'RegisterService.Requests.Hire'"
            type="button"
            [disabled]="isApproved && !row.isApproved"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5] text-[#6D767C]"
            (click)="onHire(row.id)"
          >
            {{ 'RegisterService::Hire' | abpLocalization }}
          </button>

          <button
            *abpPermission="'RegisterService.Requests.Duplicate'"
            type="button"
            [disabled]="isApproved && !row.isApproved"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5] text-[#6D767C]"
            (click)="onDuplicate(row.id)"
          >
            <i class="fas fa-copy fa-lg"></i>
          </button>

          <button
            *abpPermission="'RegisterService.Requests.Edit'"
            type="button"
            [disabled]="isApproved && !row.isApproved"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5] text-[#6D767C]"
            (click)="onEdit(row)"
          >
            <i class="fas fa-pen fa-lg"></i>
          </button>
        </div>

        <ng-template #closeIcon>
          <button
            *abpPermission="'RegisterService.Requests.Close'"
            type="button"
            class="btn hover:bg-[#D3DAE5] border-[#D3DAE5] text-red"
            (click)="status === RequestStatus.CLOSED ? onClose(row.id) : onRefuse(row.id)"
          >
            <i aria-hidden="true" class="fa fa-times fa-lg"></i>
          </button>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
