import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filesize'})
export class FileSizePipe implements PipeTransform {
  transform(value: any): any {
      if (value > 1048576) {
        value = Math.round(Math.round(value) / 1048576) + 'MB';
      } else if (value > 1024) {
        value = Math.round(Math.round(value) / 1024) + 'KB';
      } else if (value > 0) {
        value = '1KB';
      }
      return value;    
  }
}
