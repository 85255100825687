import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-request-picker',
  templateUrl: './request-picker.component.html',
})
export class RequestPickerComponent {
  @Input() openModal: boolean;
  @Input() assetId: string;
  @Input() categoryId: string;
  @Input() isPageTemporary: boolean = false;
  @Input() pinOrKeyringPassword: string;
  @Input() tenantName: string | null = null;
  @Output() modalClose = new EventEmitter<boolean>();

  constructor() {}

  onUpdate(isClose?: boolean) {
    this.modalClose.emit(false);
  }
}
