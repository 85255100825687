import { QRPageNotFoundRoutingModule } from './qr-not-found/qr-not-found-routing.module';
import { QRPageNotFoundComponent } from './qr-not-found/qr-not-found.component';
import { AuthGuard, PermissionGuard, RouterOutletComponent } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { Route, Router, RouterModule, Routes } from '@angular/router';
import { saasCreateFormPropContributors } from './tenants/tenant-form-prop-contributors';
import { AssetDetailsComponent } from './assets/details/asset-details.component';
import { InspectionCreateComponent } from './inspections/create/inspection-create.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { dashboardType: 'jobsite' }, // Passing 'jobsite' as data
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { dashboardType: 'tenant' }, // Passing 'tenant' as data
  },

  {
    path: 'qr-not-found',
    component: QRPageNotFoundComponent,
    data: { skipGuard: true },
  },

  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () =>
      import('@volo/abp.ng.saas').then(m =>
        m.SaasModule.forLazy({ createFormPropContributors: saasCreateFormPropContributors })
      ),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'gdpr-cookie-consent',
    loadChildren: () =>
      import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
        m => m.GdprCookieConsentModule
      ),
  },
  {
    path: 'countries',
    loadChildren: () => import('./countries/country/country.module').then(m => m.CountryModule),
  },
  { path: 'sites', loadChildren: () => import('./sites/site/site.module').then(m => m.SiteModule) },
  {
    path: 'attribute-definition-groups',
    loadChildren: () =>
      import('./attribute-definitions/attribute-definition.module').then(
        m => m.AttributeDefinitionModule
      ),
  },
  {
    path: 'attribute-definition-groups/create',
    loadChildren: () =>
      import('./attribute-definitions/attribute-definition.module').then(
        m => m.AttributeDefinitionModule
      ),
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/category.module').then(m => m.CategoryModule),
  },
  {
    path: 'consumables',
    loadChildren: () => import('./consumables/consumables.module').then(m => m.ConsumablesModule),
  },
  {
    path: 'cost-centre',
    loadChildren: () => import('./cost-centre/cost-centre.module').then(m => m.CostCentreModule),
  },
  {
    path: 'qty-assets',
    loadChildren: () => import('./qty-assets/qty-assets.module').then(m => m.QtyAssetsModule),
  },
  {
    path: 'manufacturers',
    loadChildren: () =>
      import('./manufacturers/manufacturer/manufacturer.module').then(m => m.ManufacturerModule),
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/location.module').then(m => m.LocationModule),
  },
  {
    path: 'assets',
    loadChildren: () => import('./assets/asset.module').then(m => m.AssetModule),
  },
  {
    path: 'requests',
    loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule),
    data: { skipGuard: true },
  },
  {
    path: 'assets/guest/view/:id',
    component: AssetDetailsComponent,
    data: { skipGuard: true },
  },
  {
    path: 'staff',
    loadChildren: () => import('./staffs/staff/staff.module').then(m => m.StaffModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'inspection-statuses',
    loadChildren: () =>
      import('./inspection-statuses/inspection-status/inspection-status.module').then(
        m => m.InspectionStatusModule
      ),
  },
  {
    path: 'inspection-types/:id/cert',
    loadChildren: () =>
      import('./reports/report-designer.module').then(m => m.ReportDesignerModule),
  },
  {
    path: 'inspection-types',
    loadChildren: () =>
      import('./inspection-types/inspection-types.module').then(m => m.InspectionTypesModule),
  },
  {
    path: 'inspections',
    loadChildren: () => import('./inspections/inspection.module').then(m => m.InspectionModule),
    data: { skipGuard: true },
  },

  {
    path: 'jobsites',
    loadChildren: () => import('./jobsites/jobsites.module').then(m => m.JobsitesModule),
  },
  {
    path: 'queue',
    loadChildren: () => import('./queue/queue.module').then(m => m.QueueModule),
  },
  {
    path: 'reports/designer',
    loadChildren: () =>
      import('./reports/report-designer.module').then(m => m.ReportDesignerModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AuthGuard, PermissionGuard],
})
export class AppRoutingModule {
  constructor(router: Router) {
    router.config
      .filter(route => !route.data || !route.data.skipGuard)
      .forEach(route => this.addGuard(route));
  }

  private addGuard(route: Route): void {
    route.canActivate = route.canActivate
      ? [AuthGuard, PermissionGuard].concat(route.canActivate)
      : [AuthGuard, PermissionGuard];
  }
}
