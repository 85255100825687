import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-move-to-top',
  templateUrl: './move-to-top.component.html',
  styleUrls: ['./move-to-top.component.scss'],
})
export class MoveToTopComponent {
  @Output() move = new EventEmitter();
  @ViewChild('upButton', { static: true }) upButton: ElementRef;

  scrollToTop(event): void {
    this.move.emit();

    this.upButton.nativeElement.classList.add('up-button-pink');
    setTimeout(() => {
      this.upButton.nativeElement.classList.remove('up-button-pink');
    }, 100);
  }
}
