import { LocationTreeNode } from '@/locations/models/location-tree-node.model';
import { TreeAdapter } from '@abp/ng.components/tree';
import { PagedResultDto, ListService, ABP } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AssetDetailsDto,
  AssetService,
  AssetTransferableParts,
} from '@proxy/register-service/assets';
import { GetLocationsInput, LocationDto, LocationService } from '@proxy/register-service/locations';
import { DeallocationType } from '@register/proxy/register-service/assets';
import { LocationDefinition } from '@register/proxy/register-service/locations';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-allocate-modal',
  templateUrl: './allocate-modal.component.html',
  styleUrls: ['./allocate-modal.component.scss'],
})
export class AllocateModalComponent implements OnInit, OnChanges {
  @Input() asset: AssetDetailsDto;
  @Input() assets: string[];
  @Input() openAllocateModal: boolean;
  @Input() showMessage = false;
  @Input() twoStepEnabled = false;
  @Input() transferData: AssetTransferableParts | null = null;
  @Input() pinOrKeyringPassword: string = '';
  @Output() modalClose = new EventEmitter<boolean>();
  @Output() openTransferModal = new EventEmitter();

  DeallocationType = DeallocationType;
  selectedNode: LocationDto;
  selectedLocations: LocationDto[] = [];
  selectedLocationsIds: string[] = [];
  data: PagedResultDto<LocationDto> = {
    items: [],
    totalCount: 0,
  };
  locationDefinition: LocationDefinition = LocationDefinition.JOBSITE;
  isBusy: boolean = true;

  selectedLocation: LocationTreeNode = null;
  expandedKeys: any[] = [];
  checkedKeys: any[] = [];
  nodes: any[];
  treeAdapter: TreeAdapter<LocationTreeNode>;
  locationDefinitionEnum = LocationDefinition;
  isLoading: boolean;
  filters: GetLocationsInput;
  functionForTree = {
    selectFn: node => {
      this.disableApplyButton(node);
      return false;
    },
  };

  constructor(
    public readonly list: ListService,
    public readonly service: LocationService,
    public readonly cdr: ChangeDetectorRef,
    public readonly assetService: AssetService,
    public readonly toasterService: ToasterService
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) => {
      this.isLoading = true;

      return this.service
        .getList({
          ...query,
          ...this.filters,
          assetId: this.asset?.id,
          pinOrKeyringPassword: this.pinOrKeyringPassword,
          locationDefinition: LocationDefinition.JOBSITE,
          term: query.filter,
          skipCount: 0,
          maxResultCount: 100,
        })
        .pipe(finalize(() => (this.isLoading = false)));
    };

    const setData = (list: PagedResultDto<LocationTreeNode>) => {
      this.data = list;
      this.treeAdapter = new TreeAdapter(list.items);
      this.nodes = this.treeAdapter.getTree();
      this.expandedKeys = [...this.expandedKeys];
      this.selectedLocation = null;
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['transferData'] && changes['transferData'].currentValue) {
      this.performAction();
    }
  }

  toggleLocation(location: LocationDto) {
    this.isBusy = false;
    const index = this.selectedLocations.indexOf(location);
    if (index === -1) {
      this.selectedLocations.push(location);
      this.selectedLocationsIds.push(location.id);
    } else {
      this.selectedLocations.splice(index, 1);
      this.selectedLocationsIds.splice(index, 1);
    }
  }

  getJobsiteLookup = () => {
    return this.service.getLookup({
      locationDefinition: LocationDefinition.JOBSITE,
      parentId: this.assetService.defaultGUID,
      maxResultCount: 100,
      skipCount: 0,
    });
  };

  disableApplyButton(node: any) {
    if (!node.treeService) {
      return;
    }

    const { checkedNodeList, rootNodes } = node.treeService;
    const checkedNodeCount = checkedNodeList.length;

    if (checkedNodeCount === 0 || checkedNodeCount > rootNodes.length) {
      this.isBusy = true;
      return;
    }

    for (const rootNode of rootNodes) {
      if (this.moreThanOneChecked(rootNode) > 1) {
        this.isBusy = true;
        return;
      }
    }

    this.isBusy = false;
  }

  moreThanOneChecked(node: any): number {
    let count: number = node?.component?.isChecked ? 1 : 0;

    if (node._children.length > 0) {
      for (const child of node._children) {
        count += this.moreThanOneChecked(child);
      }
    }

    return count;
  }

  performAction() {
    const allocate = this.assetService.performAction({
      actionType: 'allocate',
      asset: this.asset,
      assetIds: this.assets,
      transferData: this.transferData,
      selectedLocationsIds: this.checkedKeys,
      pinOrKeyringPassword: this.pinOrKeyringPassword,
    });

    allocate.subscribe({
      next: () => {
        this.modalClose.emit(true);
        this.toasterService.success('RegisterService::Successful');
      },
    });
  }

  allocate() {
    this.isBusy = true;
    this.twoStepEnabled ? this.openTransferModal.emit() : this.performAction();
  }
}
