<div class="calendar-container">
  <div *ngFor="let month of months" class="month-panel">
    <div class="month-header">{{ month.name }}</div>
    <div class="week-days">
      <div *ngFor="let day of weekDays" class="day-header">{{ day }}</div>
    </div>
    <div class="month-days">
      <div *ngFor="let day of month.days">
        <ng-container *ngIf="day; else emptyDay">
          <div [ngClass]="day.class">{{ day.date }}</div>
        </ng-container>
        <ng-template #emptyDay>
          <div class="empty-day"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
