import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '@proxy/administration-service/settings';
import {
  AssetActionModalType,
  AssetDetailsDto,
  AssetTransferableParts,
} from '@proxy/register-service/assets';
import { TenancyService } from '@proxy/administration-service/tenancy';

@Component({
  selector: 'app-queue-modals',
  templateUrl: './queue-modals.component.html',
  styleUrls: ['./queue-modals.component.scss'],
})
export class QueueModalsComponent implements OnInit {
  @Input() asset: AssetDetailsDto;
  @Input() assets: AssetDetailsDto[];
  @Input() isModalOpen: boolean = false;
  @Input() showMessage = false;
  @Input() pinOrKeyringPassword: string = '';
  @Input() selected: string[];
  @Input() type: AssetActionModalType | null = null;
  @Output() modalClose = new EventEmitter<boolean>();

  twoStepEnabled = false;
  showTransferModal: boolean = false;
  transferData: AssetTransferableParts | null = null;

  AssetActionModalType = AssetActionModalType;
  constructor(public readonly service: SettingsService, private tenancyService: TenancyService) {}

  ngOnInit(): void {
    if (this.asset) {
      this.tenancyService.getTwoStepStatus(this.asset.id).subscribe({
        next: data => {
          this.twoStepEnabled = data || this.asset.isParent || this.asset.hasSubAssets;
        },
      });
    }
  }

  triggerTransferAction(data: AssetTransferableParts | null) {
    this.transferData = data;
  }

  closeModal(isApplied: boolean) {
    this.modalClose.emit(isApplied);
  }
}
