import { ListService, PagedResultDto } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetService, AssetDetailsDto } from '@proxy/register-service/assets';
import { LocationDto, LocationService } from '@proxy/register-service/locations';
import { LocationDefinition } from '@register/proxy/register-service/locations';
import { DeallocationType } from '@register/proxy/register-service/assets';
@Component({
  selector: 'app-deallocate-modal',
  templateUrl: './deallocate-modal.component.html',
  styleUrls: ['./deallocate-modal.component.scss'],
})
export class DeallocateModalComponent implements OnInit {
  @Input() openDeallocateModal: boolean;
  @Input() asset: AssetDetailsDto;
  @Input() assets: string[];
  @Output() modalClose = new EventEmitter<boolean>();

  DeallocationType = DeallocationType;
  LocationDefinition = LocationDefinition;
  selectedDeallocationType: DeallocationType = DeallocationType.AWAITINGCOLLECTION;
  selectedLocation: LocationDto = null;
  parentJobsiteId: string = '';
  parentJobsiteIds: LocationDto[] = [];

  data: PagedResultDto<LocationDto> = {
    items: [],
    totalCount: 0,
  };

  isBusy: boolean = true;

  constructor(
    public readonly list: ListService,
    public readonly service: LocationService,
    public readonly assetService: AssetService,
    public readonly toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.getJobsiteLookup().subscribe({
      next: data => {
        this.parentJobsiteIds = data.items;
        this.parentJobsiteId = this.parentJobsiteIds[0].id;
        this.getChildJobsiteList();
      },
    });
  }

  selectLocation(location: LocationDto) {
    this.isBusy = false;
    this.selectedLocation = location;
  }

  selectDeallocationType(deallocationType: DeallocationType) {
    this.selectedDeallocationType = deallocationType;
  }

  getJobsiteLookup = () => {
    return this.service.getLookup({
      groupIds: this.asset ? this.asset.jobsiteGroupIds : null,
      locationDefinition: LocationDefinition.JOBSITE,
      maxResultCount: 100,
      parentId: this.assetService.defaultGUID,
      skipCount: 0,
    });
  };

  getChildJobsiteList = () => {
    this.selectedLocation = null;
    this.isBusy = true;

    return this.service
      .getList({
        locationDefinition: LocationDefinition.JOBSITE,
        parentId: this.parentJobsiteId,
        forDeallocation: true,
        maxResultCount: 100,
        skipCount: 0,
      })
      .subscribe(data => {
        this.data = data;
      });
  };

  deallocate() {
    this.isBusy = true;
    const deallocate = this.assetService.performAction({
      actionType: 'deallocate',
      asset: this.asset,
      assetIds: this.assets,
      parentJobsiteId: this.parentJobsiteId,
      selectedLocation: this.selectedLocation,
    });

    deallocate.subscribe({
      next: () => {
        this.modalClose.emit(true);
        this.toasterService.success('RegisterService::Successful');
      },
    });
  }
}
