<abp-page
  class="asset-name-txt"
  [ngClass]="{ 'temporary-page': isPageTemporary }"
  [title]="
    ((asset?.parentId ? 'RegisterService::SubAsset' : 'RegisterService::Asset') | abpLocalization) +
    ': ' +
    (asset?.primaryID ?? asset?.secondaryID ?? asset?.code ?? '')
  "
>
  <div *ngIf="asset">
    <app-asset-infos
      [asset]="asset"
      [isPageTemporary]="isPageTemporary"
      [tenantPassword]="tenantPassword"
      *ngIf="isPageTemporary"
    ></app-asset-infos>
    <div *ngIf="!isPageTemporary">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs mb-3">
        <li [ngbNavItem]="1" class="ms-3 link-buttons">
          <a ngbNavLink>{{ '::Information' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-asset-infos [asset]="asset"></app-asset-infos>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="ms-3 link-buttons">
          <a ngbNavLink>{{ 'RegisterService::EventHistory' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-asset-details-event-history
              [asset]="asset"
              [isGroup]="true"
            ></app-asset-details-event-history>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" class="ms-3 link-buttons">
          <a ngbNavLink>{{ 'RegisterService::TransferHistory' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-asset-details-transfer-history
              [asset]="asset"
              [isGroup]="true"
            ></app-asset-details-transfer-history>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" class="ms-3 link-buttons">
          <a ngbNavLink>{{ '::Documents' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-attachment
              apiName="registerService"
              attachedToEntity="assets"
              ownerType="Asset"
              [attachedToId]="asset.id"
            ></app-attachment>
            <h4 class="mt-3 border-bottom">
              {{ 'RegisterService::RelativeDocuments' | abpLocalization }}
            </h4>
            <app-attachment
              apiName="registerService"
              attachedToEntity="categories"
              ownerType="Category"
              [attachedToId]="asset.categoryId"
              [allowAttach]="false"
              [allowDelete]="false"
            ></app-attachment>
          </ng-template>
        </li>

        <li [ngbNavItem]="5" class="ms-3 link-buttons">
          <a ngbNavLink>{{ 'RegisterService::Utilization' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-asset-utilization [asset]="asset"></app-asset-utilization>
          </ng-template>
        </li>

        <li [ngbNavItem]="6" class="ms-3 link-buttons">
          <a ngbNavLink>{{ 'RegisterService::Trackers' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-asset-trackers [assetDetails]="asset"></app-asset-trackers>
          </ng-template>
        </li>

        <li [ngbNavItem]="7" *ngIf="asset.hasSubAssets" class="ms-3 link-buttons">
          <a ngbNavLink>{{ 'RegisterService::SubAssets' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <app-sub-assets [asset]="asset"></app-sub-assets>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <div *ngIf="activeTab === 1" class="mt-2 modal-footer mb-5 mb-md-4">
      <button
        *abpPermission="'RegisterService.Assets.Reserve'"
        type="button"
        class="btn btn-primary me-2"
        (click)="onModalOpen('reserve')"
      >
        {{ 'RegisterService::Reserve' | abpLocalization }}
      </button>
      <button
        *abpPermission="'RegisterService.Assets.Move'"
        type="button"
        class="btn bg-warning text-white"
        (click)="onModalOpen('move')"
      >
        {{ 'RegisterService::Move' | abpLocalization }}
      </button>
      <ng-container *ngIf="!isPageTemporary">
        <button
          *abpPermission="'RegisterService.Assets.Checkout'"
          type="button"
          class="btn bg-danger text-white me-2"
          (click)="onModalOpen('checkout')"
        >
          {{ 'RegisterService::Checkout' | abpLocalization }}
        </button>
      </ng-container>
      <ng-container *ngIf="!isPageTemporary">
        <button
          *abpPermission="'RegisterService.Assets.Allocate'"
          type="button"
          class="btn bg-success text-white me-2"
          (click)="onModalOpen('allocate')"
          [disabled]="asset.isDeallocated"
        >
          {{ 'RegisterService::Allocate' | abpLocalization }}
        </button>
      </ng-container>
      <div *abpPermission="'RegisterService.Assets.Deallocate'">
        <button
          type="button"
          class="btn bg-gray text-white me-2"
          (click)="onModalOpen('deallocate')"
          [disabled]="asset.jobsiteIds.length === 0"
          *ngIf="asset.assetType === assetType.OWNED && !asset.isDeallocated"
        >
          {{ 'RegisterService::Deallocate' | abpLocalization }}
        </button>
      </div>
      <div
        *abpPermission="'RegisterService.Assets.Collect'"
        [ngStyle]="{
          display: asset.assetType === assetType.OWNED && asset.isDeallocated ? 'block' : 'none'
        }"
      >
        <button
          type="button"
          class="btn bg-danger text-white me-2"
          (click)="collectAsset(asset)"
          *ngIf="asset.assetType === assetType.OWNED && asset.isDeallocated"
        >
          {{ 'RegisterService::Collect' | abpLocalization }}
        </button>
      </div>
      <div
        *abpPermission="'RegisterService.Assets.OffHire'"
        [ngStyle]="{ display: asset.assetType === assetType.HIRED ? 'block' : 'none' }"
      >
        <button
          type="button"
          class="btn bg-danger text-white me-2"
          (click)="OffHireAsset(asset)"
          *ngIf="asset.assetType === assetType.HIRED"
        >
          {{ 'RegisterService::OffHire' | abpLocalization }}
        </button>
      </div>
      <button
        *abpPermission="'RegisterService.Assets.ToggleActivity'"
        type="button"
        class="btn bg-warning text-white me-2"
        (click)="onModalOpen('status')"
      >
        {{ 'RegisterService::ActiveStatus' | abpLocalization }}
      </button>
      <ng-container *ngIf="asset.isActive">
        <a
          *abpPermission="'RegisterService.Assets.Edit'"
          [routerLink]="['/assets/edit', asset.id]"
          class="btn btn-outline-secondary me-2"
        >
          {{ 'AbpUi::Edit' | abpLocalization }}
        </a>
        <ng-container *ngIf="!isPageTemporary">
          <button
            *abpPermission="'ServicingService.Events.Create'"
            class="btn btn-outline-secondary me-2"
            (click)="onInspect()"
          >
            {{ 'RegisterService::Event' | abpLocalization }}
          </button>
        </ng-container>
      </ng-container>
      <button
        *abpPermission="'RegisterService.Assets.Delete'"
        class="btn btn-outline-danger me-2"
        (click)="onDelete()"
      >
        <i class="fa fa-thrash-alt"></i>
        {{ '::Delete' | abpLocalization }}
      </button>
    </div>
    <div *ngIf="isPageTemporary" class="buttons-to-top">
      <button
        type="button"
        class="btn bg-danger text-white me-2"
        (click)="isStaffActionModalOpen = true; staffActionType = 'checkout'"
      >
        {{ 'RegisterService::Checkout' | abpLocalization }}
      </button>
      <button
        class="btn btn-outline-secondary me-2"
        (click)="isStaffActionModalOpen = true; staffActionType = 'event'"
      >
        {{ 'RegisterService::Event' | abpLocalization }}
      </button>
      <button
        type="button"
        class="btn bg-success text-white me-2"
        (click)="isStaffActionModalOpen = true; staffActionType = 'allocate'"
        [disabled]="asset.isDeallocated"
      >
        {{ 'RegisterService::Allocate' | abpLocalization }}
      </button>
    </div>
  </div>
</abp-page>

<app-queue-modals
  *ngIf="isModalOpen"
  [type]="modalType"
  [asset]="asset"
  [isModalOpen]="isModalOpen"
  [pinOrKeyringPassword]="pinOrKeyringPassword"
  (modalClose)="onModalClose()"
></app-queue-modals>

<abp-modal
  *ngIf="isPageTemporary"
  [(visible)]="isTemporaryModalOpen"
  [options]="{
    animation: true,
    centered: true,
    backdropClass: 'hidenContent',
    keyboard: false,
    backdrop: 'static'
  }"
>
  <ng-template #abpHeader>
    <h3 class="modal-header p-0 m-0">
      {{ 'RegisterService::TenantPassword' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="mb-3 d-flex flex-column gap-3 align-items-center justify-content-center">
      <div class="w-100">
        <label
          class="form-label"
          for="tenant-password"
          *ngIf="wrongTenantPassword"
          class="text-danger"
          >{{ 'RegisterService::IncorrectTenantPassword' | abpLocalization }}</label
        >
        <input
          [(ngModel)]="tenantPassword"
          [ngClass]="{ 'wrong-input': wrongTenantPassword }"
          class="form-control"
          id="tenant-password"
          maxlength="64"
        />
      </div>
      <abp-button [disabled]="!tenantPassword" (click)="checkIfPasswordIsCorrect()">
        {{ 'AbpUi::Submit' | abpLocalization }}
      </abp-button>
    </div>
  </ng-template>
</abp-modal>
<abp-modal
  [(visible)]="isStaffActionModalOpen"
  [options]="{
    animation: true,
    centered: true,
  }"
  (appear)="pinOrKeyringPassword = ''"
>
  <ng-template #abpHeader>
    <h3 class="modal-header m-0 p-0">
      {{ 'RegisterService::PinOrPassword' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="mb-3 d-flex gap-2 align-items-end justify-content-between">
      <div>
        <label
          class="form-label"
          for="tenant-password"
          *ngIf="wrongPinOrPassword"
          class="text-danger"
          >{{ 'RegisterService::IncorrectPinOrPassword' | abpLocalization }}</label
        >
        <input
          (input)="onPinInput($event)"
          [(ngModel)]="pinOrKeyringPassword"
          [ngClass]="{ 'wrong-input': wrongPinOrPassword }"
          class="form-control"
          id="pin-or-password"
        />
      </div>
      <button class="btn btn-primary qr-button" (click)="isQRScannerShown = true">
        <i class="fa fa-qrcode fa-2x"></i>
      </button>
    </div>
    <zxing-scanner *ngIf="isQRScannerShown" (scanSuccess)="onScanSuccess($event)"></zxing-scanner>
  </ng-template>
</abp-modal>
