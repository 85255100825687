<ngx-datatable
  default
  [rows]="data"
  [count]="recordsTotal"
  [list]="list"
  (activate)="tableActivate.emit($event)"
>
  <ngx-datatable-column
    *ngIf="actionsTemplate || (actionList.length && hasAtLeastOnePermittedAction)"
    [name]="actionsText | abpLocalization"
    [maxWidth]="columnWidths[0]"
    [width]="columnWidths[0]"
    [sortable]="false"
  >
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <ng-container
        *ngTemplateOutlet="actionsTemplate || gridActions; context: { $implicit: row, index: i }"
      ></ng-container>
      <ng-template #gridActions>
        <ng-container *ngIf="isVisibleActions(row)">
          <abp-grid-actions [index]="i" [record]="row" text="AbpUi::Actions"></abp-grid-actions>
        </ng-container>
      </ng-template>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngFor="let prop of propList; let i = index; trackBy: trackByFn"
    [width]="columnWidths[i + 1] || 200"
    [name]="prop.displayName | abpLocalization"
    [prop]="prop.name"
    [sortable]="prop.sortable"
  >
    <ng-template ngx-datatable-header-template let-column="column">
      <ng-container *ngIf="prop.tooltip">
        <span
          [ngbTooltip]="prop.tooltip.text | abpLocalization"
          [placement]="prop.tooltip.placement || 'auto'"
          container="body"
        >
          {{ column.name }} <i class="fa fa-info-circle" aria-hidden="true"></i>
        </span>
      </ng-container>
      <ng-container *ngIf="!prop.tooltip">
        {{ column.name }}
      </ng-container>
    </ng-template>

    <ng-template let-row="row" let-i="index" ngx-datatable-cell-template>
      <ng-container *abpPermission="prop.permission; runChangeDetection: false">
        <ng-container *abpVisible="row['_' + prop.name]?.visible">
          <ng-container *ngIf="!row['_' + prop.name]?.component">
            <div
              *ngIf="prop.name !== 'keyringPassword'"
              [innerHTML]="
                !prop.isExtra
                  ? (row['_' + prop.name]?.value | async)
                  : ('::' + (row['_' + prop.name]?.value | async) | abpLocalization)
              "
              (click)="
                prop.action && prop.action({ getInjected: getInjected, record: row, index: i })
              "
              [ngClass]="entityPropTypeClasses[prop.type]"
              [class.pointer]="prop.action"
            ></div>

            <div *ngIf="prop.name === 'keyringPassword'">
              <img
                *ngIf="row.extraProperties.keyringPassword; else noValue"
                [src]="getQrCode(row.extraProperties.keyringPassword) | async"
                alt="qr code"
                class="qr-code-img"
              />
              <ng-template #noValue>-</ng-template>
            </div>
          </ng-container>

          <ng-container *ngIf="row['_' + prop.name]?.component">
            <ng-container
              *ngComponentOutlet="
                row['_' + prop.name].component;
                injector: row['_' + prop.name].injector
              "
            ></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
