import { ListService, PagedResultDto } from '@abp/ng.core';
import { debounceTime, finalize, switchMap, Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  CostCentreTreeNode,
  mapCostCentresTreeNode,
} from '@/cost-centre/models/cost-centre-tree-node.model';
import { CostCentreDto } from '@proxy/register-service/cost-centre';
import { LocationService } from '@proxy/register-service/locations';
import { CostCentreService } from '@proxy/register-service/cost-centre';

@Component({
  selector: 'app-cost-centre-picker',
  templateUrl: './cost-centre-picker.component.html',
  styleUrls: ['./cost-centre-picker.component.scss'],
})
export class CostCentrePickerComponent implements OnInit {
  @Input() isModalOpen = false;
  @Input() locationId: string;
  @Input() selectedId: string;

  @Output() isModalOpenChange = new EventEmitter<boolean>();
  @Output() submitAction = new EventEmitter<CostCentreTreeNode | null>();

  isLoading = false;
  selected: CostCentreTreeNode = null;
  data: PagedResultDto<CostCentreTreeNode> = {
    items: [],
    totalCount: 0,
  };

  searchTerm$ = new Subject<string>();

  constructor(
    public readonly list: ListService,
    public readonly service: CostCentreService,
    public readonly locationsService: LocationService
  ) {}

  ngOnInit(): void {
    this.searchTerm$
      .pipe(
        debounceTime(250),
        switchMap(term => {
          this.isLoading = true;
          return this.service
            .getList({
              term,
              skipCount: 0,
              maxResultCount: 100,
            })
            .pipe(finalize(() => (this.isLoading = false)));
        })
      )
      .subscribe((list: PagedResultDto<CostCentreDto>) => {
        this.data = new PagedResultDto<CostCentreTreeNode>({
          totalCount: list.totalCount,
          items: mapCostCentresTreeNode(list.items),
        });
        if (this.selectedId) {
          this.selected = list.items.find(e => e.id === this.selectedId) as CostCentreTreeNode;
        }
      });
    this.searchTerm$.next('');
  }

  onSearchTermChange(term: string) {
    this.searchTerm$.next(term);
  }

  onSelect() {
    this.submitAction.emit(this.selected);
    this.onCancel();
  }

  onCancel() {
    this.selected = null;
    this.isModalOpenChange.emit(false);
  }
}
