// import { eLayoutType, RoutesService } from '@abp/ng.core';
// import { APP_INITIALIZER } from '@angular/core';

import { RestService } from "@abp/ng.core";
import { InjectionToken } from "@angular/core";
import { AttachmentService } from "@proxy/attachments";

// export const REGISTER_ATTACHMENT_SERVICE = new InjectionToken<string>('register', {
//   providedIn: 'root',
//   factory: () => 'register'
// });

// {
// provide: 'userService1',
// deps: [ HttpClient ],
// useFactory: (dep1 ) => {
//     return new UserService( dep1, 'test');
//   }
// }

export const ADMINISTRATION_ATTACHMENT_SERVICE = new InjectionToken<AttachmentService>('attachmentService');
export const REGISTER_ATTACHMENT_SERVICE = new InjectionToken<AttachmentService>('attachmentService');
export const SERVICING_ATTACHMENT_SERVICE = new InjectionToken<AttachmentService>('attachmentService');
export const QUEUE_ATTACHMENT_SERVICE = new InjectionToken<AttachmentService>('attachmentService');
export function attachmentServiceFactory(apiName: string) {
    return (restService: RestService) => new AttachmentService(restService, apiName);
}
