import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const INSPECTION_STATUSES_INSPECTION_STATUS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/inspection-statuses',
        iconClass: 'fas fa-flag-checkered',
        name: 'ServicingService::Menu:EventStatuses',
        parentName: '::Menu:Settings',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.InspectionStatuses.Page',
      },
    ]);
  };
}
