import { RestService } from '@abp/ng.core';
import { PagedResultDto, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AttachmentCreateDto,
  AttachmentDto,
  AttachmentUpdateDto,
  GetAttachmentsInput,
} from './models';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, defer, from, of, zip } from 'rxjs';
import { receiveDownload } from '@proxy/shared/receive-download';
import { resultTypeOptions } from '@proxy/servicing-service/inspection-statuses';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService implements IAttachmentService {
  // apiName = 'registerService';

  // create = (input: AttachmentCreateDto) =>
  //   this.restService.request<any, AttachmentDto>({
  //     method: 'POST',
  //     url: '/api/${this.apiName}/attachments',
  //     body: input,
  //   },
  //     { apiName: this.apiName });

  attach = (
    attachedToEntity: string,
    attachedToId: string,
    inputs: AttachmentCreateDto[]
  ): Observable<AttachmentDto[]> => {
    const results: Observable<AttachmentDto>[] = [];
    for (let i = 0; i < inputs.length; i++) {
      var formdata = new FormData();
      const input = inputs[i];
      for (const key in input) {
        if (Object.prototype.hasOwnProperty.call(input, key)) {
          const val = input[key];
          formdata.append(`input.${key}`, val ?? '');
        }
      }

      results.push(
        this.restService.request<any, AttachmentDto>(
          {
            method: 'POST',
            url: `/api/${this.apiName}/${attachedToEntity}/${attachedToId}/attachments`,
            body: formdata,
          },
          { apiName: this.apiName }
        )
      );
    }

    return zip(...results);
  };

  get = (id: string, attachedToEntity: string, attachedToId: string) =>
    this.restService.request<any, AttachmentDto>(
      {
        method: 'GET',
        url: `/api/${this.apiName}/${attachedToEntity}/${attachedToId}/attachments/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (attachedToEntity: string, attachedToId: string, input: GetAttachmentsInput) =>
    this.restService.request<any, PagedResultDto<AttachmentDto>>(
      {
        method: 'GET',
        url: `/api/${this.apiName}/${attachedToEntity}/${attachedToId}/attachments`,
        params: {
          term: input.term,
          title: input.title,
          contentType: input.contentType,
          fileName: input.fileName,
          fileUrl: input.fileUrl,
          folder: input.folder,
          attachedToEntity: input.attachedToEntity,
          attachedToId: input.attachedToId,
          ownerId: input.ownerId,
          isActive: input.isActive,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (
    id: string,
    attachedToEntity: string,
    attachedToId: string,
    input: AttachmentUpdateDto
  ) =>
    this.restService.request<any, AttachmentDto>(
      {
        method: 'PUT',
        url: `/api/${this.apiName}/${attachedToEntity}/${attachedToId}/attachments/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  download = (
    id: string,
    attachedToEntity: string,
    attachedToId: string
  ): Observable<{
    fileName: string;
    contentType: string;
    data: any;
  }> =>
    this.restService
      .request<any, HttpResponse<Blob>>(
        {
          method: 'GET',
          url: `/api/${this.apiName}/${attachedToEntity}/${attachedToId}/attachments/${id}/download`,
          responseType: 'blob',
        },
        { apiName: this.apiName, observe: Rest.Observe.Response }
      )
      .pipe(map(resp => receiveDownload(resp)));

  delete = (id: string, attachedToEntity: string, attachedToId: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/${this.apiName}/${attachedToEntity}/${attachedToId}/attachments/${id}`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService, private apiName: string) {}
}

export interface IAttachmentService {
  attach(
    attachedToEntity: string,
    attachedToId: string,
    inputs: AttachmentCreateDto[]
  ): Observable<AttachmentDto[]>;
  get(id: string, attachedToEntity: string, attachedToId: string): Observable<AttachmentDto>;
  getList(
    attachedToEntity: string,
    attachedToId: string,
    input: GetAttachmentsInput
  ): Observable<PagedResultDto<AttachmentDto>>;
  update(
    id: string,
    attachedToEntity: string,
    attachedToId: string,
    input: AttachmentUpdateDto
  ): Observable<AttachmentDto>;
  download(
    id: string,
    attachedToEntity: string,
    attachedToId: string
  ): Observable<{
    fileName: string;
    contentType: string;
    data: any;
  }>;
  delete(id: string, attachedToEntity: string, attachedToId: string): Observable<void>;
}
