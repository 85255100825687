<div class="mb-3 picture-upload" (drop)="onDropFile($event)" (dragover)="allowDropFile($event)">
  <div class="picture-edit">
    <input
      type="file"
      id="category-picture"
      accept=".png, .jpg, .jpeg"
      #fileInput
      (change)="onChangeFile($event)"
      class="form-control"
    />
    <label for="category-picture" *ngIf="editFile" [ngClass]="['custom-label', 'upload-image']">
    </label>
    <label
      *ngIf="removeImage"
      [ngClass]="['custom-label', 'remove-image']"
      (click)="onRemoveImage()"
    ></label>
  </div>
  <div class="picture-preview">
    <div [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }"></div>
  </div>
</div>
