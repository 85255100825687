import { Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SelectionType } from '@swimlane/ngx-datatable';
import { ModelService } from '@proxy/register-service/models';
import type { AssetDetailsDto } from '@/proxy/register-service/assets/models';
import { AssetService } from '@/proxy/register-service/assets/asset.service';
import { AssetType, ComplianceStatus } from '@proxy/register-service/assets';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss'],
})
export class AssetsTableComponent {
  window = window;
  isModalOpen = false;
  modalType = '';
  assetType = AssetType;
  SelectionType = SelectionType;
  selectedAsset: AssetDetailsDto;
  ComplianceStatus = ComplianceStatus;

  @Input() isBusy = false;
  @Input() hideCheckbox = false;
  @Input() list: ListService;
  @Input() selected: AssetDetailsDto[];
  @Input() assetColumns: { [x: string]: boolean };
  @Input() data: PagedResultDto<AssetDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  @Output() selectedChange = new EventEmitter<AssetDetailsDto[]>();

  constructor(
    private confirmation: ConfirmationService,
    private readonly toasterService: ToasterService,
    private router: Router,
    public readonly modelService: ModelService,
    public readonly service: AssetService
  ) {}

  onModalOpen(modalType: string, asset: AssetDetailsDto) {
    this.modalType = modalType;
    this.selectedAsset = asset;
    this.isModalOpen = true;
  }

  onModalClose(isApplied: boolean) {
    this.modalType = '';
    this.isModalOpen = false;
    isApplied && this.list.get();
  }

  onView(record: AssetDetailsDto) {
    this.router.navigate(['/assets/view', record.id]);
  }

  onInspect(record: AssetDetailsDto) {
    this.router.navigate(['/inspections/create'], { queryParams: { assetId: record.id } });
  }

  update(record: AssetDetailsDto) {
    this.router.navigate(['/assets/edit', record.id]);
  }

  onSelectRow({ selected }: any): void {
    this.selectedChange.emit([...selected]);
  }

  clearSelected() {
    this.selectedChange.emit([]);
  }

  delete(record: AssetDetailsDto) {
    this.confirmation
      .warn('RegisterService::DeleteConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  collectAsset(asset: AssetDetailsDto) {
    this.service.collectAsset(asset).subscribe({
      next: () => {
        this.toasterService.success('RegisterService::Successful');
        this.list.get();
      },
    });
  }

  OffHireAsset(asset: AssetDetailsDto) {
    this.service.offHire(asset).subscribe({
      next: () => {
        this.toasterService.success('RegisterService::Successful');
        this.list.get();
      },
    });
  }
}
