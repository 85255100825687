<abp-page [title]="'RegisterService::Sites' | abpLocalization">
  <abp-page-toolbar-container class="col new-area-col">
    <div class="text-lg-end">
      <button
        *abpPermission="'RegisterService.Sites.Create'"
        class="btn btn-primary btn-sm"
        type="button"
        (click)="create()"
      >
        <i class="fa fa-plus me-1" aria-hidden="true"></i>
        {{ 'RegisterService::NewSite' | abpLocalization }}
      </button>
    </div>
  </abp-page-toolbar-container>

  <abp-advanced-entity-filters localizationSourceName="RegisterService" [list]="list">
    <abp-advanced-entity-filters-form>
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="emailFilter">
                {{ 'RegisterService::Email' | abpLocalization }}
              </label>
              <input
                id="emailFilter"
                class="form-control"
                [(ngModel)]="filters.email"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="phoneFilter">
                {{ 'RegisterService::Phone' | abpLocalization }}
              </label>
              <input
                id="phoneFilter"
                class="form-control"
                [(ngModel)]="filters.phone"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="notesFilter">
                {{ 'RegisterService::Notes' | abpLocalization }}
              </label>
              <input
                id="notesFilter"
                class="form-control"
                [(ngModel)]="filters.notes"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="isActiveFilter">
                {{ 'RegisterService::IsActive' | abpLocalization }}
              </label>

              <select
                name="isActive"
                id="isActiveFilter"
                class="form-select form-control"
                [(ngModel)]="filters.isActive"
                [ngModelOptions]="{ standalone: true }"
                (change)="list.get()"
              >
                <option [ngValue]=""></option>
                <option [ngValue]="true">{{ 'AbpUi::Yes' | abpLocalization }}</option>
                <option [ngValue]="false">{{ 'AbpUi::No' | abpLocalization }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>

  <div class="card">
    <div class="card-body">
      <ngx-datatable [rows]="data.items" [count]="data.totalCount" [list]="list" default>
        <ngx-datatable-column name="{{ 'RegisterService::Name' | abpLocalization }}" prop="name">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.name }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'RegisterService::Address' | abpLocalization }}"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ [row.address?.street1, row.address?.city, row.address?.postCode] | join : ', ' }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'RegisterService::Email' | abpLocalization }}" prop="email">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.email }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'RegisterService::Phone' | abpLocalization }}" prop="phone">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.phone }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'RegisterService::Notes' | abpLocalization }}" prop="notes">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.notes }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'RegisterService::IsActive' | abpLocalization }}"
          prop="isActive"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <app-yes-no-display [value]="row.isActive"></app-yes-no-display>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <div ngbDropdown container="body" class="d-inline-block">
              <button
                class="btn btn-primary btn-sm dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                ngbDropdownToggle
              >
                <i class="fa fa-cog me-1" aria-hidden="true"></i>
                {{ 'AbpUi::Actions' | abpLocalization }}
              </button>

              <div ngbDropdownMenu>
                <ng-template [ngIf]="row.isActive" [ngIfThen]="yes" [ngIfElse]="no"></ng-template>

                <ng-template #yes>
                  <button
                    ngbDropdownItem
                    *abpPermission="'RegisterService.Sites.Edit'"
                    (click)="updateStatus(row, false)"
                  >
                    {{ 'RegisterService::Disable' | abpLocalization }}
                  </button>
                </ng-template>

                <ng-template #no>
                  <button
                    ngbDropdownItem
                    *abpPermission="'RegisterService.Sites.Edit'"
                    (click)="updateStatus(row, true)"
                  >
                    {{ 'RegisterService::Enable' | abpLocalization }}
                  </button>
                </ng-template>

                <button
                  ngbDropdownItem
                  *abpPermission="'RegisterService.Sites.Edit'"
                  (click)="update(row)"
                >
                  {{ 'AbpUi::Edit' | abpLocalization }}
                </button>

                <button
                  ngbDropdownItem
                  *abpPermission="'RegisterService.Sites.Delete'"
                  (click)="delete(row)"
                >
                  {{ 'AbpUi::Delete' | abpLocalization }}
                </button>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<abp-modal [busy]="isModalBusy" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ (selected ? 'AbpUi::Edit' : 'RegisterService::NewSite') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form" (ngSubmit)="submitForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="site-name">
            {{ 'RegisterService::Name' | abpLocalization }} <app-is-required></app-is-required>
          </label>
          <input
            id="site-name"
            formControlName="name"
            class="form-control"
            maxlength="128"
            autofocus
          />
        </div>
        <div formGroupName="address">
          <div class="mb-3">
            <label class="form-label" for="site-address-street1">
              {{ 'RegisterService::AddressStreet1' | abpLocalization }}
            </label>
            <input
              id="site-address-street1"
              formControlName="street1"
              class="form-control"
              maxlength="128"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="site-address-city">
              {{ 'RegisterService::AddressCity' | abpLocalization }}
            </label>
            <input
              id="site-address-city"
              formControlName="city"
              class="form-control"
              maxlength="64"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="site-address-post-code">
              {{ 'RegisterService::AddressPostCode' | abpLocalization }}
            </label>
            <input
              id="site-address-post-code"
              formControlName="postCode"
              class="form-control"
              maxlength="32"
            />
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="site-email">
            {{ 'RegisterService::Email' | abpLocalization }}
          </label>
          <input id="site-email" formControlName="email" class="form-control" maxlength="256" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="site-phone">
            {{ 'RegisterService::Phone' | abpLocalization }}
          </label>
          <input id="site-phone" formControlName="phone" class="form-control" maxlength="32" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="site-notes">
            {{ 'RegisterService::Notes' | abpLocalization }}
          </label>
          <textarea
            id="site-notes"
            formControlName="notes"
            rows="3"
            maxlength="512"
            class="form-control"
          ></textarea>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" (click)="submitForm()" [disabled]="form?.invalid">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
