import { CoreModule } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from './register-locales';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { REPORTS_REPORT_DESIGNER_ROUTE_PROVIDER } from './reports/report-designer-route.provider';
import { JOBSITE_ROUTE_PROVIDER } from './jobsites/jobsite-route.provider';
import { COUNTRIES_COUNTRY_ROUTE_PROVIDER } from './countries/country/providers/country-route.provider';
import { SITES_SITE_ROUTE_PROVIDER } from './sites/site/providers/site-route.provider';
import { ATTRIBUTE_DEFINITIONS_ATTRIBUTE_DEFINITION_ROUTE_PROVIDER } from './attribute-definitions/attribute-definition-group-route.provider';
import { CATEGORIES_CATEGORY_ROUTE_PROVIDER } from './categories/category-route.provider';
import { MANUFACTURERS_MANUFACTURER_ROUTE_PROVIDER } from './manufacturers/manufacturer/providers/manufacturer-route.provider';
import { LOCATIONS_LOCATION_ROUTE_PROVIDER } from './locations/location-route.provider';
import { ASSETS_ASSET_ROUTE_PROVIDER } from './assets/asset-route.provider';
import { CONSUMABLES_ROUTE_PROVIDER } from './consumables/consumables-route.provider';
import { STAFFS_STAFF_ROUTE_PROVIDER } from './staffs/staff/providers/staff-route.provider';
import { INSPECTION_STATUSES_INSPECTION_STATUS_ROUTE_PROVIDER } from './inspection-statuses/inspection-status/providers/inspection-status-route.provider';
import { INSPECTION_TYPES_INSPECTION_TYPE_ROUTE_PROVIDER } from './inspection-types/inspection-types-route.provider';
import { INSPECTIONS_INSPECTION_ROUTE_PROVIDER } from './inspections/inspection-route.provider';
// import { MOVEMENTS_MOVEMENT_ROUTE_PROVIDER } from './movements/movement/providers/movement-route.provider';
import { QUEUE_ROUTE_PROVIDER } from './queue/queue-route.provider';
import { TENANCY_TENANCY_ROUTE_PROVIDER } from './tenancy/tenancy-route.provider';
import { APPLICATION_SETTINGS__ROUTE_PROVIDER } from './settings/settings.provider';
import { LogoComponent } from './components/layout/logo/logo.component';
import { UserModule } from './users/user.module';
import { QueueModule } from './queue/queue.module';
import { AdminModule } from './admin/admin.module';
import { StoreModule } from '@ngrx/store';
import { QueueReducer } from './store/reducers/queue.reducer';
import { LpxSideMenuLayoutModule } from '@volosoft/ngx-lepton-x/layouts';
import { LpxResponsiveModule } from '@volo/ngx-lepton-x.core'; // optional. Only, if you are using lpxResponsive directive
import { AppLayoutComponent } from './components/layout/app/app-layout.component';
import { LeptonXAbpCoreModule } from '@volo/abp.ng.lepton-x.core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { QTY_ASSETS_ROUTE_PROVIDER } from './qty-assets/qty-assets-route.provider';
import { AuthHttpInterceptor } from '@proxy/interceptors/http.interceptor';
import { SharedModule } from './shared/shared.module';
import { AppLpxMobileNavbarModule } from './components/layout/mobile/mobile-navbar.module';

import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { COST_CENTRE_ROUTE_PROVIDER } from './cost-centre/cost-centre-route.provider';

@NgModule({
  declarations: [AppComponent, LogoComponent, AppLayoutComponent],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppLpxMobileNavbarModule,
    CoreModule.forRoot({
      localizations: [
        {
          culture: 'en-GB',
          resources: [
            {
              resourceName: 'RegisterService',
              texts: {
                'Menu:RegisterService': 'Register',
                HomePage: 'Home',
                'Enum:EditorType:TagBox': 'Tag box',
              },
            },
          ],
        },
      ],
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    StoreModule.forRoot({
      queue: QueueReducer,
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    GdprConfigModule.forRoot(),
    GdprConfigModule.forRoot({
      cookieConsent: {
        privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
        cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
      },
    }),
    UserModule,
    AdminModule,
    QueueModule,
    LpxSideMenuLayoutModule,
    LpxResponsiveModule,
    LeptonXAbpCoreModule,
    SharedModule,
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    APPLICATION_SETTINGS__ROUTE_PROVIDER,
    COUNTRIES_COUNTRY_ROUTE_PROVIDER,
    SITES_SITE_ROUTE_PROVIDER,
    CONSUMABLES_ROUTE_PROVIDER,
    ATTRIBUTE_DEFINITIONS_ATTRIBUTE_DEFINITION_ROUTE_PROVIDER,
    CATEGORIES_CATEGORY_ROUTE_PROVIDER,
    MANUFACTURERS_MANUFACTURER_ROUTE_PROVIDER,
    QTY_ASSETS_ROUTE_PROVIDER,
    LOCATIONS_LOCATION_ROUTE_PROVIDER,
    ASSETS_ASSET_ROUTE_PROVIDER,
    STAFFS_STAFF_ROUTE_PROVIDER,
    REPORTS_REPORT_DESIGNER_ROUTE_PROVIDER,
    INSPECTION_STATUSES_INSPECTION_STATUS_ROUTE_PROVIDER,
    INSPECTION_TYPES_INSPECTION_TYPE_ROUTE_PROVIDER,
    INSPECTIONS_INSPECTION_ROUTE_PROVIDER,
    JOBSITE_ROUTE_PROVIDER,
    COST_CENTRE_ROUTE_PROVIDER,
    QUEUE_ROUTE_PROVIDER,
    TENANCY_TENANCY_ROUTE_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
