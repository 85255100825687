import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { serialize } from 'object-to-formdata';
import type { RequestDetailsDto, RequestCreateDto, GetRequestInput } from './models';
import type { LookupDto } from '@proxy/shared/models';

import { AssetLookupRequestDto } from '../shared';
import { ConfirmationService } from '@abp/ng.theme.shared';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/requests';

  constructor(
    private restService: RestService,
    private readonly confirmation: ConfirmationService
  ) {}

  create = (input: RequestCreateDto) =>
    this.restService.request<any, RequestCreateDto>(
      {
        method: 'POST',
        url: this.urlPrefix,
        body: input,
      },
      { apiName: this.apiName }
    );

  refuse = (id: number) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/refuse`,
      },
      { apiName: this.apiName }
    );

  hire = (id: number) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/hire-required`,
      },
      { apiName: this.apiName }
    );

  duplicate = (id: number) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/duplicate`,
      },
      { apiName: this.apiName }
    );

  close = (id: number) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/close`,
      },
      { apiName: this.apiName }
    );

  match = (id: number, assetId: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/match`,
        params: {
          assetId,
        },
      },
      { apiName: this.apiName }
    );

  approve = (id: number) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/approve`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, RequestDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetRequestInput) =>
    this.restService.request<any, PagedResultDto<RequestDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${input.status}`,
        params: {
          maxResultCount: input.maxResultCount,
          skipCount: input.skipCount,
          categoryId: input.categoryId,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: AssetLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          siteId: input.siteId,
          modelId: input.modelId,
          locationId: input.locationId,
          categoryId: input.categoryId,
          manufacturerId: input.manufacturerId,
          parentId: input.parentId,
        },
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string, isPageTemporary: boolean = false, tenantPassword: string = '') =>
    this.restService.request<any, RequestDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${isPageTemporary ? 'guest/' : ''}${id}/details`,
        params: {
          tenantPassword: tenantPassword,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: number, input: RequestCreateDto) => {
    const formData = serialize(input, { indices: true, dotsForObjectNotation: true });

    return this.restService.request<any, RequestDetailsDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  handleErrorResponse(error: any): void {
    this.confirmation.info(error.error.error.message, 'Request', {
      cancelText: 'OK',
      hideYesBtn: true,
    });
    return;
  }
}
