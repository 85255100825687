import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetDetailsDto, AssetService } from '@proxy/register-service/assets';

@Component({
  selector: 'app-is-active-modal',
  templateUrl: './is-active-modal.component.html',
  styleUrls: ['./is-active-modal.component.scss'],
})
export class IsActiveModalComponent implements OnInit {
  @Input() openIsActiveModal: boolean;
  @Input() asset: AssetDetailsDto;
  @Input() showMessage = false;
  @Input() assets: string[];
  @Output() modalClose = new EventEmitter<boolean>();

  isActiveSelected: boolean = true;
  isBusy: boolean = false;

  constructor(
    public readonly assetService: AssetService,
    public readonly toasterService: ToasterService
  ) {}

  ngOnInit() {
    if (this.asset) {
      this.isActiveSelected = this.asset.isActive ? true : false;
    }
  }

  toggleIsActiveSelected(value: boolean) {
    this.isActiveSelected = value;
  }

  toggleIsActive() {
    this.isBusy = true;
    const toggleActive = this.assetService.performAction({
      actionType: 'toggleActive',
      asset: this.asset,
      assetIds: this.assets,
      isActiveSelected: this.isActiveSelected,
    });

    toggleActive.subscribe({
      next: () => {
        this.modalClose.emit(true);
        this.toasterService.success('RegisterService::Successful');
      },
    });
  }
}
