<div class="col-12 col-sm-auto" *ngIf="isModalOpen">
  <div *ngIf="showTransferModal">
    <app-transfer-sub-assets-modal
      [openModal]="showTransferModal"
      [asset]="asset"
      [type]="type"
      [pinOrKeyringPassword]="pinOrKeyringPassword"
      (modalClose)="showTransferModal = false"
      (transfer)="triggerTransferAction($event)"
    ></app-transfer-sub-assets-modal>
  </div>

  <ng-container [ngSwitch]="type">
    <app-request-picker
      *ngSwitchCase="AssetActionModalType.Match"
      [assetId]="asset.id"
      [categoryId]="asset.categoryId"
      [openModal]="true"
      (modalClose)="closeModal($event)"
    ></app-request-picker>
    <app-reserve-modal
      *ngSwitchCase="AssetActionModalType.RESERVE"
      [openReserveModal]="true"
      [asset]="asset"
      [assets]="selected"
      [showMessage]="showMessage"
      (modalClose)="closeModal($event)"
    ></app-reserve-modal>
    <app-move-modal
      *ngSwitchCase="AssetActionModalType.MOVE"
      [openMoveModal]="true"
      [asset]="asset"
      [transferData]="transferData"
      [twoStepEnabled]="twoStepEnabled"
      [showMessage]="showMessage"
      [assets]="selected"
      (openTransferModal)="showTransferModal = true"
      (modalClose)="closeModal($event)"
    ></app-move-modal>
    <app-checkout-modal
      *ngSwitchCase="AssetActionModalType.CHECKOUT"
      [openCheckoutModal]="true"
      [transferData]="transferData"
      [twoStepEnabled]="twoStepEnabled"
      (openTransferModal)="showTransferModal = true"
      [pinOrKeyringPassword]="pinOrKeyringPassword"
      [asset]="asset"
      [showMessage]="showMessage"
      [assets]="selected"
      (modalClose)="closeModal($event)"
    ></app-checkout-modal>
    <app-allocate-modal
      *ngSwitchCase="AssetActionModalType.ALLOCATE"
      [openAllocateModal]="true"
      [transferData]="transferData"
      [twoStepEnabled]="twoStepEnabled"
      (openTransferModal)="showTransferModal = true"
      [pinOrKeyringPassword]="pinOrKeyringPassword"
      [asset]="asset"
      [showMessage]="showMessage"
      [assets]="selected"
      (modalClose)="closeModal($event)"
    ></app-allocate-modal>
    <app-deallocate-modal
      *ngSwitchCase="AssetActionModalType.DEALLOCATE"
      [openDeallocateModal]="true"
      [asset]="asset"
      [showMessage]="showMessage"
      [assets]="selected"
      (modalClose)="closeModal($event)"
    ></app-deallocate-modal>

    <app-is-active-modal
      *ngSwitchCase="AssetActionModalType.Status"
      [openIsActiveModal]="true"
      [asset]="asset"
      [assets]="assets"
      [showMessage]="showMessage"
      (modalClose)="closeModal($event)"
    ></app-is-active-modal>
  </ng-container>
</div>
