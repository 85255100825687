<div class="flex flex-col w-full mb-3" *ngIf="withInput; else defaultTemplate">
  <label class="form-label" for="asset-category-id">
    {{ 'ServicingService::Category' | abpLocalization }}
    <label class="text-danger" title="This field is required">*</label>
  </label>
  <div class="flex justify-between">
    <input
      id="asset-category-id"
      class="form-control border border-gray-300 p-2 rounded-lg rounded-r-none"
      maxlength="64"
      readonly
      [value]="name"
    />
    <div class="flex" *ngIf="!readonly">
      <button
        class="btn btn-primary px-2 rounded-none"
        (click)="onSelectClicked()"
        style="margin-left: -1px"
      >
        {{ 'RegisterService::Pick' | abpLocalization }}
      </button>
      <button type="button" class="btn btn-danger rounded-l-none" (click)="onRemove()">
        <i aria-hidden="true" class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>
<ng-template #defaultTemplate>
  <div class="selector-container">
    <label class="form-label">
      {{ 'ServicingService::Category' | abpLocalization }}
    </label>
    <div>
      <button
        *ngIf="!name"
        type="button"
        class="btn btn-outline-secondary"
        (click)="onSelectClicked()"
      >
        {{ 'AbpUi::Select' | abpLocalization }}
      </button>

      <div *ngIf="name">
        <span class="text-wrap">{{ name }}</span>
        <i
          *ngIf="!readonly"
          class="fas fa-times cursor-pointer fa-lg ms-2"
          (click)="onRemove()"
        ></i>
      </div>
    </div>
  </div>
</ng-template>
<abp-modal [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h5>
      {{ 'RegisterService::Categories' | abpLocalization }}
    </h5>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form">
      <div class="mb-3">
        <input
          [placeholder]="'AbpUi::Search' | abpLocalization"
          formControlName="term"
          class="form-control"
        />
      </div>
    </form>

    <app-categories-tree
      [isLoading]="isLoading"
      [data]="data"
      (toggleNodeExpansion)="toggleNodeExpansion($event)"
      [(expandedKeys)]="expandedKeys"
      [readonly]="true"
      [selectedNode]="selectedCategoryTemp"
      (selectedNodeChange)="onSelectedNodeChange($event)"
    >
    </app-categories-tree>
  </ng-template>

  <ng-template #abpFooter>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSelect()"
      [disabled]="!selectedCategoryTemp"
    >
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
