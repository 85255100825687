import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetModelsInput, ModelCreateDto, ModelDto, ModelUpdateDto, ModelDetailsDto } from './models';
import type { LookupDto } from '@proxy/shared/models';
import { ModelLookupRequestDto } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  apiName = 'RegisterService';

  create = (input: ModelCreateDto) =>
    this.restService.request<any, ModelDto>({
      method: 'POST',
      url: '/api/register/models',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/register/models/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ModelDto>({
      method: 'GET',
      url: `/api/register/models/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: GetModelsInput) =>
    this.restService.request<any, PagedResultDto<ModelDetailsDto>>({
      method: 'GET',
      url: '/api/register/models',
      params: { term: input.term, name: input.name, isActive: input.isActive, manufacturerId: input.manufacturerId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getLookup = (input: ModelLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/register/models/lookup',
      params: { filter: input.filter, manufacturerId: input.manufacturerId, isActive: input.isActive, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getDetails = (id: string) =>
    this.restService.request<any, ModelDetailsDto>({
      method: 'GET',
      url: `/api/register/models/${id}/details`,
    },
    { apiName: this.apiName });

  update = (id: string, input: ModelUpdateDto) =>
    this.restService.request<any, ModelDto>({
      method: 'PUT',
      url: `/api/register/models/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
