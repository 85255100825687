import { NgModule } from '@angular/core';
import { CoreModule } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { YesNoDisplayComponent } from './yes-no-display/yes-no-display.component';
import { LocalizationModule } from '@abp/ng.core';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { ReserveModalComponent } from './reserve-modal/reserve-modal.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { MoveModalComponent } from './move-modal/move-modal.component';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { CheckoutModalComponent } from './checkout-modal/checkout-modal.component';
import { AllocateModalComponent } from './allocate-modal/allocate-modal.component';
import { DeallocateModalComponent } from './deallocate-modal/deallocate-modal.component';
import { IsRequiredComponent } from './is-required/is-required.component';
import { IsActiveModalComponent } from './is-active-modal/is-active-modal.component';
import { AssetTrackersComponent } from './asset-trackers/asset-trackers.component';
import { SharedModule } from '@/shared/shared.module';
import { TreeModule } from '@abp/ng.components/tree';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SpaceModule } from '@flatfile/angular-sdk';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { ConsumablesSelectComponent } from './consumables-select/consumables-select.component';
import { AccordionComponent } from './accordion/accordion.component';
import { LocationsModalComponent } from './locations-modal/locations-modal.component';
import { CustomListenerService } from './flat-file/listener';
import { ConsumablePickerComponent } from './consumable-picker/consumable-picker.component';
import { SubAssetsModalComponent } from './sub-assets-modal/sub-assets-modal.component';
import { TransferSubAssetsModalComponent } from './transfer-sub-assets-modal/transfer-sub-assets-modal.component';
import { FlatFileComponent } from './flat-file/flat-file.component';

@NgModule({
  declarations: [
    AllocateModalComponent,
    AssetTrackersComponent,
    CheckoutModalComponent,
    DeallocateModalComponent,
    ImagePickerComponent,
    IsActiveModalComponent,
    IsRequiredComponent,
    MoveModalComponent,
    ReserveModalComponent,
    YesNoDisplayComponent,
    ConsumablesSelectComponent,
    AccordionComponent,
    LocationsModalComponent,
    ConsumablePickerComponent,
    SubAssetsModalComponent,
    TransferSubAssetsModalComponent,
    FlatFileComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps,
    }),
    CommercialUiModule,
    CommonModule,
    CoreModule,
    FormsModule,
    LocalizationModule,
    SpaceModule,
    NgApexchartsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    SharedModule,
    ThemeSharedModule,
    TreeModule,
  ],
  providers: [CustomListenerService],
  exports: [
    AccordionComponent,
    AllocateModalComponent,
    AssetTrackersComponent,
    CheckoutModalComponent,
    DeallocateModalComponent,
    ImagePickerComponent,
    IsActiveModalComponent,
    IsRequiredComponent,
    LocationsModalComponent,
    MoveModalComponent,
    ConsumablesSelectComponent,
    ReserveModalComponent,
    YesNoDisplayComponent,
    ConsumablePickerComponent,
    TransferSubAssetsModalComponent,
    SubAssetsModalComponent,
    FlatFileComponent,
  ],
})
export class SharedComponentsModule {}
