import { Component, OnInit } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AssetDetailsDto, AssetType } from '@proxy/register-service/assets';
import { AssetService } from '@proxy/register-service/assets';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { AuthService, ListService } from '@abp/ng.core';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
  providers: [ListService],
})
export class AssetDetailsComponent implements OnInit {
  activeTab = 1;
  asset: AssetDetailsDto;
  assetId: string;
  assetType = AssetType;
  isModalOpen = false;
  isPageTemporary: boolean = false;
  isStaffActionModalOpen: boolean = false;
  isTemporaryModalOpen: boolean = false;
  isTenantPasswordRequired: boolean = false;
  isQRScannerShown: boolean = true;
  modalType: string = '';
  pinOrKeyringPassword: string;
  wrongTenantPassword: boolean = false;
  wrongPinOrPassword: boolean = false;
  staffActionType: 'allocate' | 'checkout' | 'event' | null = null;
  tenantPassword: string;
  window = window;

  constructor(
    private readonly authService: AuthService,
    private readonly confirmation: ConfirmationService,
    private readonly listService: ListService,
    private readonly route: ActivatedRoute,
    private readonly service: AssetService,
    private readonly toasterService: ToasterService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (this.assetId) {
          this.assetId = params.get('id')!;
          this.activeTab = 1;
          this.getAssetDetails();
        }
        this.assetId = params.get('id')!;
      },
    });

    this.route?.data.subscribe(data => {
      this.isPageTemporary = !this.authService.isAuthenticated && data.skipGuard;

      if (this.isPageTemporary) {
        this.service.isTenantPasswordRequired(this.assetId).subscribe({
          next: data => {
            if (data) {
              this.isTemporaryModalOpen = true;
              this.isTenantPasswordRequired = true;
            } else {
              this.getAssetDetails(this.isPageTemporary, this.tenantPassword);
            }
          },
        });
      } else {
        this.getAssetDetails();
      }
    });
  }

  getAssetDetails(isPageTemporary = false, tenantPassword = '') {
    this.service.getDetails(this.assetId, isPageTemporary, tenantPassword).subscribe(asset => {
      this.asset = asset;
    });
  }

  checkIfPasswordIsCorrect() {
    this.service.validateTenantPassword(this.assetId, this.tenantPassword).subscribe({
      next: data => {
        if (data) {
          this.getAssetDetails(true, this.tenantPassword);
          this.isTemporaryModalOpen = false;
          this.wrongTenantPassword = false;
        } else {
          this.wrongTenantPassword = true;
        }
      },
    });
  }

  onPinInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (
      (input.value.length === 4 && this.isFourDigitNumber(input.value)) ||
      input.value.length === 16
    ) {
      this.handleFourDigitInput();
    }
  }

  private isFourDigitNumber(value: string): boolean {
    const number = Number(value);
    return /^[0-9]{4}$/.test(value) && !isNaN(number);
  }

  handleFourDigitInput(): void {
    this.service
      .checkPinOrKeyringPassword(this.asset.id, this.pinOrKeyringPassword.split('/')[0])
      .subscribe({
        next: data => {
          if (data) {
            this.isStaffActionModalOpen = false;
            this.wrongPinOrPassword = false;

            if (this.staffActionType === 'event') {
              this.onInspect();
            } else {
              this.onModalOpen(this.staffActionType);
            }
          } else {
            this.wrongPinOrPassword = true;
          }
        },
      });
  }

  goBack() {
    this.router.navigate(['/assets']);
  }

  onDelete() {
    this.confirmation
      .warn('RegisterService::DeleteConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(this.asset.id))
      )
      .subscribe(() => {
        this.toasterService.success('ServicingService::SuccessfullyDeleted');
        this.goBack();
      });
  }

  onInspect() {
    this.router.navigate(['/inspections/create'], {
      queryParams: {
        assetId: this.asset.id,
        pinOrKeyringPassword: this.pinOrKeyringPassword,
        tenantPassword: this.tenantPassword,
        isPageTemporary: this.isPageTemporary,
      },
    });
  }

  reloadRouting() {
    this.router.navigate([`/assets/view/${this.asset.id}`]);
  }

  onModalOpen(modalType: string) {
    this.modalType = modalType;
    this.isModalOpen = true;
  }

  onModalClose() {
    this.modalType = '';
    this.isModalOpen = false;
    this.getAssetDetails(this.isPageTemporary, this.tenantPassword);
  }

  collectAsset(asset: AssetDetailsDto) {
    this.service.collectAsset(asset).subscribe({
      next: () => {
        this.toasterService.success('RegisterService::Successful');
        this.getAssetDetails(this.isPageTemporary, this.tenantPassword);
      },
    });
  }

  OffHireAsset(asset: AssetDetailsDto) {
    this.service.offHire(asset).subscribe({
      next: () => {
        this.toasterService.success('RegisterService::Successful');
        this.goBack();
      },
    });
  }

  onScanSuccess(resultString: string) {
    this.pinOrKeyringPassword = resultString;
    this.handleFourDigitInput();
    this.isQRScannerShown = false;
  }
}
