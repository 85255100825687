<abp-page [title]="'AbpIdentity::Users' | abpLocalization" [toolbar]="data.items">
  <div id="identity-users-wrapper">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="AbpIdentity">
      <abp-advanced-entity-filters-form>
        <form #filterForm (keyup.enter)="list.get()">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label for="roleIdFilter" class="form-label">
                  {{ 'AbpIdentity::Role' | abpLocalization }}
                </label>
                <abp-lookup-select
                  cid="roleIdFilter"
                  lookupNameProp="name"
                  [getFn]="roleService.getList"
                  [(ngModel)]="filters.roleId"
                  [ngModelOptions]="{ standalone: true }"
                ></abp-lookup-select>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label for="organizationUnitIdFilter" class="form-label">
                  {{ 'AbpIdentity::OrganizationUnit' | abpLocalization }}
                </label>
                <abp-lookup-select
                  cid="organizationUnitIdFilter"
                  [getFn]="organizationUnitService.getList"
                  [(ngModel)]="filters.organizationUnitId"
                  [ngModelOptions]="{ standalone: true }"
                ></abp-lookup-select>
              </div>
            </div>

            <div class="col-12 col-sm-3">
              <div class="form-group mb-3">
                <label for="UserName" class="form-label">
                  {{ 'AbpIdentity::UserName' | abpLocalization }}
                </label>
                <input
                  type="text"
                  id="UserName"
                  class="form-control"
                  [(ngModel)]="filters.userName"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="col-12 col-sm-3">
              <div class="form-group mb-3">
                <label for="PhoneNumber" class="form-label">
                  {{ 'AbpIdentity::PhoneNumber' | abpLocalization }}
                </label>
                <input
                  type="text"
                  id="PhoneNumber"
                  class="form-control"
                  [(ngModel)]="filters.phoneNumber"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="col-12 col-sm-3">
              <div class="form-group mb-3">
                <label for="EmailAddress" class="form-label">
                  {{ 'AbpIdentity::EmailAddress' | abpLocalization }}
                </label>
                <input
                  type="text"
                  id="EmailAddress"
                  class="form-control"
                  [(ngModel)]="filters.emailAddress"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="d-flex col-3">
              <div class="align-self-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="is-locked-out"
                    [(ngModel)]="filters.isLockedOut"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label class="form-check-label" for="is-locked-out">{{
                    'AbpIdentity::Lock' | abpLocalization
                  }}</label>
                </div>
              </div>
              <div class="align-self-center ms-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="not-active"
                    [(ngModel)]="filters.notActive"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label class="form-check-label" for="not-active">{{
                    'AbpIdentity::NotActive' | abpLocalization
                  }}</label>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-auto align-self-end mb-3">
              <div class="row">
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                    <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                  </button>
                </div>
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-primary" (click)="list.get()">
                    <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
    <div class="d-flex justify-content-end pb-2">
      <button
        *abpPermission="'RegisterService.Assets.Create'"
        class="btn btn-sm export-btn"
        type="button"
        data-toggle="tooltip"
        data-placement="top"
        title="Download file"
        (click)="exportFile()"
      >
        <i class="fas fa-file-excel" aria-hidden="true"></i>
      </button>
    </div>
    <div class="card">
      <!-- <abp-extensible-table
        [data]="data.items"
        [recordsTotal]="data.totalCount"
        [list]="list"
      ></abp-extensible-table> -->

      <app-user-table
        [data]="data.items"
        [recordsTotal]="data.totalCount"
        [list]="list"
      ></app-user-table>
    </div>
  </div>
</abp-page>

<abp-modal
  [(visible)]="isModalVisible"
  class="userModal"
  [busy]="modalBusy"
  [options]="{ size: 'xl' }"
  (disappear)="form = null"
>
  <ng-template #abpHeader>
    <h3>
      {{ (selected?.id ? 'AbpUi::Edit' : 'AbpIdentity::NewUser') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <div class="row">
      <form *ngIf="form" [formGroup]="form" class="col-6" (ngSubmit)="save()" validateOnSubmit>
        <h4 class="mb-3">{{ 'AbpIdentity::UserInformations' | abpLocalization }}</h4>
        <div>
          <label class="form-label">{{ 'AbpIdentity::Roles' | abpLocalization }}</label>
          <div
            *ngFor="let roleGroup of roleGroups; let i = index; trackBy: trackByFn"
            class="form-check mb-3"
          >
            <!-- <input
              type="checkbox"
              class="form-check-input"
              [id]="'roles-' + i"
              [disabled]="selected.id ? true : null"
              (change)="onRoleClick($event, roles[i].name)"
              [checked]="roleGroup.value[name]"
            /> -->

            <input
              type="radio"
              class="form-check-input"
              [id]="'roles-' + i"
              [disabled]="selected.id ? true : null"
              (change)="onRoleClick($event, roles[i].name)"
              [checked]="selectedRole === roles[i].name"
            />
            <label class="form-check-label" [attr.for]="'roles-' + i">{{ roles[i].name }}</label>
          </div>

          <div class="form-check mb-3 py-3" *ngIf="isManager()">
            <input
              type="checkbox"
              id="cost-centre-enabled"
              class="form-check-input"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="isCostCentre"
            />
            <label class="form-check-label" for="cost-centre-enabled">{{
              'AdministrationService::CostCentreUser' | abpLocalization
            }}</label>
          </div>
          <abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>
          <div class="col-12 col-sm-3">
            <div class="flex flex-col mb-3">
              <label class="form-label" for="asset-hours">
                {{ 'RegisterService::Notifications' | abpLocalization }}
              </label>
              <div class="flex gap-1">
                <div *ngFor="let key of notificationOptions" class="flex items-center gap-1">
                  <input
                    type="checkbox"
                    [id]="'note-' + key"
                    class="form-check-input"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="notificationSettings[key]"
                  />
                  <label [for]="'note-' + key" role="button" class="me-2 min-w-max">
                    {{ 'RegisterService::' + capitalizeFirstLetter(key) | abpLocalization }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mb-3">
            <label class="form-label" for="staff-keyringPassword">
              {{ 'ServicingService::KeyringPassword' | abpLocalization }} *
            </label>
            <input
              id="staff-keyringPassword"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="keyringPassword"
              class="form-control"
            />
          </div> -->
          <!-- <div class="d-flex justify-content-between align-items-center pb-3">
            <img [width]="150" [height]="150" [src]="qrImageSrc" alt="QR Code" *ngIf="qrImageSrc" />
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="$event.stopPropagation(); $event.preventDefault(); onRegenerate()"
            >
              <i class="fa fa fa-refresh me-1" aria-hidden="true"></i>
              {{ 'ServicingService::Regenerate' | abpLocalization }}
            </button>
          </div> -->

          <div>
            <label class="form-label">{{
              'AbpIdentity::OrganizationUnits' | abpLocalization
            }}</label>
            <abp-tree
              *ngIf="organization.nodes?.length; else noDataMessage"
              [checkStrictly]="true"
              [checkable]="true"
              [nodes]="organization.nodes"
              [isNodeSelected]="organization.selectFn"
              [(checkedKeys)]="organization.checkedKeys"
              [(expandedKeys)]="organization.expandedKeys"
            >
            </abp-tree>
            <ng-template #noDataMessage>
              <p class="text-muted">
                {{ 'AbpIdentity::NoOrganizationUnits' | abpLocalization }}
              </p>
            </ng-template>
          </div>
        </div>
      </form>
      <div class="col">
        <div class="row mb-3">
          <h4 class="mb-3">
            {{ 'AdministrationService::UserRestrictions' | abpLocalization }}
          </h4>

          <h5>
            {{
              (isCostCentre ? 'RegisterService::CostCentre' : 'RegisterService::Locations')
                | abpLocalization
            }}
          </h5>

          <app-cost-centre-tree
            *ngIf="isCostCentre; else locations"
            [isLoading]="isLoading"
            [data]="costCentres"
            [readonly]="true"
            [checkable]="true"
            [checkStrictly]="true"
            [(checkedKeys)]="checkedKeys"
          />
          <ng-template #locations>
            <app-locations
              [isLoading]="modalBusy"
              [readonly]="true"
              [checkable]="true"
              [getAll]="true"
              [hideSelect]="true"
              [checkStrictly]="true"
              [(restrictedKeys)]="restrictedKeys"
              [(selectedLocation)]="selectedLocation"
            >
            </app-locations>
          </ng-template>
        </div>
        <div class="row pt-1">
          <app-event-types [(restrictedKeys)]="restrictedEvents" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" (click)="save()" [disabled]="form?.invalid">{{
      'AbpUi::Save' | abpLocalization
    }}</abp-button>
  </ng-template>
</abp-modal>

<abp-permission-management
  *abpReplaceableTemplate="{
    inputs: {
      providerName: { value: 'R' },
      providerKey: { value: providerKey },
      hideBadges: { value: true },
      visible: { value: visiblePermissions, twoWay: true }
    },
    outputs: { visibleChange: onVisiblePermissionChange },
    componentKey: 'PermissionManagement.PermissionManagementComponent'
  }"
  [(visible)]="visiblePermissions"
  [providerKey]="providerKey"
  [hideBadges]="true"
  providerName="R"
>
</abp-permission-management>

<abp-claim-modal [(visible)]="visibleClaims" [subject]="claimSubject"></abp-claim-modal>

<abp-modal [(visible)]="isSetPasswordModalVisible" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::SetPassword' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="setPasswordForm" (ngSubmit)="setPassword()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="new-password">{{
            'AbpIdentity::Password' | abpLocalization
          }}</label>
          <div class="input-group">
            <div class="col-10 p-0">
              <input
                type="text"
                id="new-password"
                class="form-control"
                formControlName="newPassword"
                autofocus
              />
            </div>

            <button
              class="btn btn-secondary"
              id="generate-random-password-button"
              type="button"
              (click)="generatePassword()"
            >
              <i class="fa fa-refresh"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button
      iconClass="fa fa-check"
      (click)="setPassword()"
      [disabled]="setPasswordForm?.invalid"
      >{{ 'AbpUi::Save' | abpLocalization }}</abp-button
    >
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="isLockModalVisible" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::Lock' | abpLocalization }} - {{ selected.userName }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="lockForm" (ngSubmit)="lock()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3" [formGroup]="lockForm">
          <abp-date-time-picker [prop]="dateTimePickerProps"></abp-date-time-picker>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button
      iconClass="fa fa-check"
      (click)="lock()"
      [disabled]="lockForm?.invalid || modalBusy"
      >{{ 'AbpUi::Save' | abpLocalization }}</abp-button
    >
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="twoFactor.isModalVisible" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::TwoFactor' | abpLocalization }} - {{ selected.userName }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <div class="mt-2 fade-in-top">
      <div class="form-check mb-2">
        <input
          type="checkbox"
          class="form-check-input"
          id="two-factor-enabled"
          [(ngModel)]="twoFactor.checkboxValue"
        />

        <label class="form-check-label" for="two-factor-enabled">{{
          'AbpIdentity::DisplayName:TwoFactorEnabled' | abpLocalization
        }}</label>
      </div>
    </div>
  </ng-template>

  <ng-template #abpFooter>
    <button
      type="button"
      (click)="(isModalVisible !== isModalVisible)"
      class="btn btn-secondary"
      abpClose
    >
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" (click)="setTwoFactor()" [disabled]="modalBusy">{{
      'AbpUi::Save' | abpLocalization
    }}</abp-button>
  </ng-template>
</abp-modal>
