import { ABP, ListService, PagedResultDto, RestService, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import fileDownload from 'js-file-download';
import type {
  GetAttachmentsInput,
  AttachmentDto,
  AttachmentCreateDto,
  IAttachmentService,
} from '@proxy/attachments';
import { AttachmentService } from '@proxy/attachments';
import {
  ADMINISTRATION_ATTACHMENT_SERVICE,
  attachmentServiceFactory,
  QUEUE_ATTACHMENT_SERVICE,
  REGISTER_ATTACHMENT_SERVICE,
  SERVICING_ATTACHMENT_SERVICE,
} from './attachment-service.provider';
import { AttachmentLocalService } from '@proxy/attachments/attachment.local.service';

@Component({
  selector: 'app-attachment',
  providers: [
    ListService,
    { provide: NgbDateAdapter, useClass: DateAdapter },
    {
      provide: ADMINISTRATION_ATTACHMENT_SERVICE,
      useFactory: attachmentServiceFactory('administration'),
      deps: [RestService],
    },
    {
      provide: REGISTER_ATTACHMENT_SERVICE,
      useFactory: attachmentServiceFactory('register'),
      deps: [RestService],
    },
    {
      provide: SERVICING_ATTACHMENT_SERVICE,
      useFactory: attachmentServiceFactory('servicing'),
      deps: [RestService],
    },
    {
      provide: QUEUE_ATTACHMENT_SERVICE,
      useFactory: attachmentServiceFactory('queue'),
      deps: [RestService],
    },
  ],
  templateUrl: './attachment.component.html',
  styles: ['table a { color: var(--text-color); } '],
})
export class AttachmentComponent implements OnInit, OnChanges {
  @Input() apiName!: string;
  @Input() attachedToId!: string;
  @Input() attachedToEntity!: string;
  @Input() ownerType!: string;
  @Input() allowAttach: boolean = true;
  @Input() allowDelete: boolean = true;
  @ViewChild('titleTemplate') titleTemplate!: TemplateRef<never>;
  @ViewChild('actionsTemplate') actionsTemplate!: TemplateRef<never>;

  data: PagedResultDto<AttachmentDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetAttachmentsInput;
  isFiltersHidden = true;
  isModalBusy = false;
  isModalOpen = false;
  service: IAttachmentService;
  mappedData: AttachmentDto[];

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    private readonly injector: Injector,
    private confirmation: ConfirmationService
  ) {}

  public get isPersistedEntity(): boolean {
    return !!this.attachedToId;
  }

  private resolveService(apiName: string): IAttachmentService {
    if (!this.isPersistedEntity) return new AttachmentLocalService(this.data);

    switch (apiName) {
      case 'registerService':
        return this.injector.get(REGISTER_ATTACHMENT_SERVICE);
      case 'administrationService':
        return this.injector.get(ADMINISTRATION_ATTACHMENT_SERVICE);
      case 'servicingService':
        return this.injector.get(SERVICING_ATTACHMENT_SERVICE);
      case 'queueService':
        return this.injector.get(QUEUE_ATTACHMENT_SERVICE);
    }
  }

  ngOnChanges({ attachedToId }: SimpleChanges) {
    if (attachedToId?.currentValue && !attachedToId.firstChange) {
      this._load();
    }
  }

  ngOnInit() {
    this.service = this.resolveService(this.apiName);
    // const getData = this.isPersistedEntity ? (query: ABP.PageQueryParams) =>
    //   this.service.getList(this.attachedToEntity, this.attachedToId, {
    //     ...query,
    //     ...this.filters,
    //     term: query.filter,
    //   }) : (query: ABP.PageQueryParams) => of(this.data);
    this._load();
  }

  clearFilters() {
    this.filters = {} as GetAttachmentsInput;
    this.list.get();
  }

  hideForm() {
    this.isModalOpen = false;
    // this.form.reset();
  }

  showForm() {
    // this.buildForm();
    this.isModalOpen = true;
  }

  // submitForm() {
  //   if (this.form.invalid) return;

  //   const request = this.selected
  //     ? this.service.update(this.selected.id, this.form.value)
  //     : this.service.create(this.form.value);

  //   this.isModalBusy = true;

  //   request
  //     .pipe(
  //       finalize(() => (this.isModalBusy = false)),
  //       tap(() => this.hideForm()),
  //     )
  //     .subscribe(this.list.get);
  // }

  create() {
    // this.selected = undefined;
    this.showForm();
  }

  // update(record: AttachmentDto) {
  //   this.selected = record;
  //   this.showForm();
  // }

  delete(record: AttachmentDto) {
    this.confirmation
      .warn('ServicingService::DeleteConfirmationMessage', 'ServicingService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id, this.attachedToEntity, record.ownerId))
      )
      .subscribe(this.list.get);
  }

  onDelete(doc: AttachmentDto) {
    this.delete(doc);
  }

  onGetDocument(doc: AttachmentDto) {
    const vm = this;
    if (vm.isModalBusy || vm.isModalOpen) return;

    if (this.isHttpLink(doc)) {
      window.open(doc.fileUrl, '_blank');
      return;
    }

    if ((doc as any).file) {
      // immediate download after upload case
      fileDownload((doc as any).file, doc.fileName, doc.contentType);
      return;
    }

    vm.isModalBusy = true;
    vm.service
      .download(doc.id, this.attachedToEntity, doc.ownerId)
      .pipe(finalize(() => (this.isModalBusy = false)))
      .subscribe(resp => {
        fileDownload(resp.data, doc.fileName ?? resp.fileName, resp.contentType);
      });
  }

  onAttachDocument(attachments: AttachmentCreateDto[]) {
    this.isModalBusy = true;
    const request = this.service.attach(this.attachedToEntity, this.attachedToId, attachments);
    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(this.list.get);

    // this.http.post('https://localhost:5001/api/upload', formData, {reportProgress: true, observe: 'events'})
    //   .subscribe(
    //     {next: (event) => {
    //     if (event.type === HttpEventType.UploadProgress)
    //       this.progress = Math.round(100 * event.loaded / event.total);
    //     else if (event.type === HttpEventType.Response) {
    //       this.message = 'Upload success.';
    //       this.onUploadFinished.emit(event.body);
    //     }
    //   },
    // });
  }

  // onAttachSuccess() {
  //   this.isModalOpen = false;
  //   this.list.get;
  // }

  isHttpLink(doc: AttachmentDto) {
    return doc.fileUrl && doc.fileUrl.startsWith('http');
  }

  private _load() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList(this.attachedToEntity, this.attachedToId, {
        ...query,
        ...this.filters,
        term: query.filter,
        ownerId: this.attachedToId,
        maxResultCount: 100,
      });

    const setData = (list: PagedResultDto<AttachmentDto>) => {
      this.data.items = [...list.items];
      this.mappedData = this.groupByTitle(this.data.items);
      this.data.totalCount = list.totalCount;
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  groupByTitle(items: AttachmentDto[]) {
    const groupedItems = [];
    const titleMap = {};

    items.forEach(item => {
      const title = item.title;
      if (titleMap[title]) {
        titleMap[title].push(item);
      } else {
        titleMap[title] = [item];
      }
    });

    for (const title in titleMap) {
      groupedItems.push(titleMap[title]);
    }

    return groupedItems;
  }
}
