import { TreeAdapter } from '@abp/ng.components/tree';
import { PagedResultDto } from '@abp/ng.core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { cloneDeep } from 'lodash';
import { CategoryTreeNode } from '../../categories/models/category-tree-node.model';
import { CategoryUpdateDto } from '@proxy/register-service/categories';
import { ConsumableTreeNode } from '@/consumables/models/consumable-tree-node.model';
import { AddConsumableCount } from '@proxy/register-service/consumables';

@Component({
  selector: 'app-categories-tree',
  templateUrl: './categories-tree.component.html',
  styleUrls: ['./categories-tree.component.scss'],
})
export class CategoriesTreeComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;
  @Input() isConsumable = false;
  @Input() isMSL = false;
  @Input() childsOnly = false;
  @Input() counts: AddConsumableCount[] = [];
  @Input() data: PagedResultDto<CategoryTreeNode>;
  @Input() selectedNode: CategoryTreeNode;
  @Input() expandedKeys: any[] = [];
  @Input() readonly: boolean;

  @Output() addRootNode = new EventEmitter<void>();
  @Output() addSubNode = new EventEmitter<CategoryTreeNode>();
  @Output() deleteNode = new EventEmitter<CategoryTreeNode>();
  @Output() toggleShowNode = new EventEmitter<CategoryUpdateDto>();
  @Output() selectedNodeChange = new EventEmitter<CategoryTreeNode>();
  @Output() toggleNodeExpansion = new EventEmitter<string>();
  @Output() expandedKeysChange = new EventEmitter<any[]>();
  @Output() showHiddenNodes = new EventEmitter<boolean>();

  show: boolean = true;
  nodes: (CategoryTreeNode | ConsumableTreeNode)[];
  window = window;

  treeAdapter: TreeAdapter<CategoryTreeNode>;

  ngOnChanges({ data }: SimpleChanges) {
    if (data && !data.firstChange) {
      this.setTreeAdapterData(data.currentValue);
    }
  }

  ngOnInit(): void {
    this.setTreeAdapterData(this.data);
  }

  toggle(event: any, node: CategoryTreeNode): void {
    event.stopPropagation();
    this.expandedKeysChange.emit([...this.expandedKeys]);
    this.toggleNodeExpansion.emit(node.key);
  }

  setTreeAdapterData(list: PagedResultDto<CategoryTreeNode | ConsumableTreeNode>) {
    if (!list) return;

    this.treeAdapter = new TreeAdapter(list.items);
    this.nodes = this.treeAdapter.getTree();
    this.expandedKeysChange.emit([...this.expandedKeys]);
  }

  findCount(id: string) {
    const item = this.counts.find(e => e.consumableId === id);
    return item?.count || '';
  }

  onAddRootNode() {
    this.addRootNode.emit();
  }

  onAddSubNode(node: NzTreeNode, $event: MouseEvent) {
    this.addSubNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onDelete(node: NzTreeNode, $event: MouseEvent) {
    this.deleteNode.emit(this.show ? node.origin.entity : node);
    $event.stopPropagation();
  }

  onToggleShow(node, $event: MouseEvent) {
    const updatedNode = this.show
      ? { ...cloneDeep(node.origin.entity), show: !node.origin.entity.show }
      : { ...cloneDeep(node), show: !node.show };
    this.toggleShowNode.emit(updatedNode);
    $event.stopPropagation();
  }

  onSelectedNode(node: CategoryTreeNode) {
    if (this.selectedNode === node) return;
    if (this.childsOnly) {
      const isParent = this.data.items.find(e => e.parentId === node.id);

      if ((isParent && this.isMSL) || !node.parentId) {
        this.expandedKeysChange.emit(
          this.expandedKeys.includes(node.id)
            ? [...this.expandedKeys].filter(e => e !== node.id)
            : [...this.expandedKeys, node.id]
        );
        return;
      }
    }
    this.selectedNodeChange.emit(node);
  }

  toggleHidden($event: MouseEvent) {
    this.show = !this.show;
    this.showHiddenNodes.emit(this.show);
    $event.stopPropagation();
  }
}
