import { Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { SelectionType } from '@swimlane/ngx-datatable';
import { ModelService } from '@proxy/register-service/models';
import type { AssetDetailsDto } from '@/proxy/register-service/assets/models';
import { AssetService } from '@/proxy/register-service/assets/asset.service';
import { AssetType, ComplianceStatus } from '@proxy/register-service/assets';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss'],
})
export class AssetsTableComponent implements OnChanges {
  window = window;
  isModalOpen = false;
  modalType = '';
  assetType = AssetType;
  pendingPageChange = false;
  SelectionType = SelectionType;
  selectedAsset: AssetDetailsDto;
  ComplianceStatus = ComplianceStatus;
  dataHistory: AssetDetailsDto[] = [];

  @Input() isBusy = false;
  @Input() hideCheckbox = false;
  @Input() list: ListService;
  @Input() selected: AssetDetailsDto[];
  @Input() assetColumns: { [x: string]: boolean };
  @Input() data: PagedResultDto<AssetDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  @Output() selectedChange = new EventEmitter<AssetDetailsDto[]>();

  constructor(
    private confirmation: ConfirmationService,
    private readonly toasterService: ToasterService,
    private router: Router,
    public readonly modelService: ModelService,
    public readonly service: AssetService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
      if (this.pendingPageChange) {
        this.pendingPageChange = false;
        let newSelected = [...this.selected];

        const items = newSelected.filter(
          (item, index, self) => index === self.findIndex(i => i.id === item.id)
        );
        this.selectedChange.emit([...items]);
      }
    }
  }

  onModalOpen(modalType: string, asset: AssetDetailsDto) {
    this.modalType = modalType;
    this.selectedAsset = asset;
    this.isModalOpen = true;
  }

  onModalClose(isApplied: boolean) {
    this.modalType = '';
    this.isModalOpen = false;
    isApplied && this.list.get();
  }

  onPageChange() {
    this.dataHistory = [...this.dataHistory, ...this.data.items].filter(
      (asset, index, self) => index === self.findIndex(a => a.id === asset.id)
    );
    this.pendingPageChange = true;
  }

  onView(record: AssetDetailsDto) {
    this.router.navigate(['/assets/view', record.id]);
  }

  onInspect(record: AssetDetailsDto) {
    this.router.navigate(['/inspections/create'], { queryParams: { assetId: record.id } });
  }

  update(record: AssetDetailsDto) {
    this.router.navigate(['/assets/edit', record.id]);
  }

  onSelectAssets(event: any, item: AssetDetailsDto) {
    const index = this.selected.findIndex(subAsset => subAsset.id === item.id);
    const items = [...this.selected];
    if (event.target.checked) {
      if (index === -1) {
        items.push(item);
      }
    } else {
      if (index !== -1) {
        items.splice(index, 1);
      }
    }
    this.selectedChange.emit([...items]);
  }

  isSelected(row: AssetDetailsDto): boolean {
    return this.selected.some(e => e.id === row.id);
  }

  isAllRowsSelected(): boolean {
    if (!this.data.items.length || !this.selected.length) {
      return false;
    }
    const allSelected = this.data.items.every(item =>
      this.selected.some(selectedItem => selectedItem.id === item.id)
    );
    return allSelected;
  }

  selectAllRows(select: boolean): void {
    if (select) {
      const allItems = this.data.items.filter(
        item => !this.selected.some(selectedItem => selectedItem.id === item.id)
      );
      this.selectedChange.emit([...this.selected, ...allItems]);
    } else {
      this.selectedChange.emit(
        this.selected.filter(
          selectedItem => !this.data.items.some(item => item.id === selectedItem.id)
        )
      );
    }
  }

  onSelectRow({ selected }: any): void {
    if (this.pendingPageChange) return;
    const items = [...selected].filter(
      (asset, index, self) => index === self.findIndex(a => a.id === asset.id)
    );

    this.selectedChange.emit([...items]);
  }

  clearSelected() {
    this.selectedChange.emit([]);
  }

  delete(record: AssetDetailsDto) {
    this.confirmation
      .warn('RegisterService::DeleteConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  collectAsset(asset: AssetDetailsDto) {
    this.service.collectAsset(asset).subscribe({
      next: () => {
        this.toasterService.success('RegisterService::Successful');
        this.list.get();
      },
    });
  }

  OffHireAsset(asset: AssetDetailsDto) {
    this.service.offHire(asset).subscribe({
      next: () => {
        this.toasterService.success('RegisterService::Successful');
        this.list.get();
      },
    });
  }
}
