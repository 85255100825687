import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { LookupDto, LookupRequestDto } from '@proxy/shared/models';
import type {
  GetSitesInput,
  SiteCreateDto,
  SiteDto,
  SiteUpdateDto,
  SiteDetailsDto,
  SiteUpdateStatusDto,
} from './models';
import { SiteLookupRequestDto } from '@proxy/register-service/shared';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  apiName = 'RegisterService';

  create = (input: SiteCreateDto) =>
    this.restService.request<any, SiteDto>(
      {
        method: 'POST',
        url: '/api/register/sites',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/sites/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, SiteDto>(
      {
        method: 'GET',
        url: `/api/register/sites/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetSitesInput) =>
    this.restService.request<any, PagedResultDto<SiteDetailsDto>>(
      {
        method: 'GET',
        url: '/api/register/sites',
        params: {
          term: input.term,
          name: input.name,
          addressCity: input.addressCity,
          addressStreet1: input.addressStreet1,
          addressStreet2: input.addressStreet2,
          addressPostCode: input.addressPostCode,
          addressRegion: input.addressRegion,
          email: input.email,
          fax: input.fax,
          phone: input.phone,
          notes: input.notes,
          isActive: input.isActive,
          countryId: input.addressCountry,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: SiteLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/register/sites/lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );
  getDetails = (id: string) =>
    this.restService.request<any, SiteDetailsDto>(
      {
        method: 'GET',
        url: `/api/register/sites/${id}/details`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: SiteUpdateDto) =>
    this.restService.request<any, SiteDto>(
      {
        method: 'PUT',
        url: `/api/register/sites/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  updateStatus = (id: string, input: SiteUpdateStatusDto) =>
    this.restService.request<any, SiteDto>(
      {
        method: 'PUT',
        url: `/api/register/sites/${id}/status`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
