import { mapEnumToOptions } from '@abp/ng.core';

export enum AssetType {
  OWNED = 'OWNED',
  HIRED = 'HIRED',
  LEASED = 'LEASED',
  BORROWED = 'BORROWED',
  OFFHIRED = 'OFFHIRED',
}

export enum ComplianceStatus {
  NONE = '-',
  ATRISK = 'At Risk',
  COMPLIANT = 'Compliant',
}

export enum AssetActionType {
  MOVE = 'MOVE',
  CHECKOUT = 'CHECKOUT',
  ALLOCATE = 'ALLOCATE',
  DEALLOCATE = 'DEALLOCATE',
  COLLECT = 'COLLECT',
  RETURN = 'RETURN',
}

export enum AssetActionModalType {
  MOVE = 'move',
  CHECKOUT = 'checkout',
  ALLOCATE = 'allocate',
  DEALLOCATE = 'deallocate',
  RESERVE = 'reserve',
  Status = 'status',
}

export enum HirePeriodRange {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum ComponentStatus {
  None = 'None',
  Exist = 'Exist',
  Missing = 'Missing',
}

export enum AssetAvailability {
  AVAILABLE = 'AVAILABLE',
  NOTAVAILABLE = 'NOTAVAILABLE',
  AWAITINGCOLLECTION = 'AWAITINGCOLLECTION',
  RESERVED = 'RESERVED',
  ONHIRE = 'ONHIRE',
}

export const AssetAvailabilities = mapEnumToOptions(AssetAvailability);

export const hirePeriodRangeOptions = mapEnumToOptions(HirePeriodRange);

export const AssetActionTypes = mapEnumToOptions(AssetActionType);

export const assetTypeOptions = mapEnumToOptions(AssetType);
