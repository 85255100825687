import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUEUE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/queue',
        iconClass: 'fas fa-file-alt',
        name: 'QueueService::Menu:Queue',
        parentName: 'QueueService::Menu:QueueService',
        order: 33,
        layout: eLayoutType.application,
        requiredPolicy: 'QueueService.Queue.Page',
      },
    ]);
  };
}
