import { ABP, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import type { GetSitesInput, SiteDetailsDto } from '@proxy/register-service/sites/models';
import { SiteService } from '@/proxy/register-service/sites/site.service';
import { CountryService } from '@proxy/administration-service/countries/country.service';

@Component({
  selector: 'app-site',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit {
  data: PagedResultDto<SiteDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetSitesInput;
  form: FormGroup;
  isFiltersHidden = true;
  isModalBusy = false;
  isModalOpen = false;
  selected?: SiteDetailsDto;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: SiteService,
    public readonly countryService: CountryService,
    private confirmation: ConfirmationService,
    private readonly toasterService: ToasterService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        term: query.filter,
      });
    const setData = (list: PagedResultDto<SiteDetailsDto>) => (this.data = list);
    this.list.hookToQuery(getData).subscribe(setData);
  }

  clearFilters() {
    this.filters = {} as GetSitesInput;
    this.list.get();
  }

  buildForm() {
    const {
      name,
      email,
      phone,
      notes,
      address: { street1, city, postCode } = {
        street1: null,
        city: null,
        postCode: null,
      },
    } = this.selected ?? { address: {} };

    this.form = this.fb.group({
      name: [name ?? null, [Validators.required, Validators.maxLength(128)]],
      address: this.fb.group({
        street1: [street1 ?? null, [Validators.maxLength(128)]],
        city: [city ?? null, [Validators.maxLength(64)]],
        postCode: [postCode ?? null, [Validators.maxLength(32)]],
      }),
      email: [email ?? null, [Validators.maxLength(256), Validators.email]],
      phone: [phone ?? null, [Validators.maxLength(32)]],
      notes: [notes ?? null, [Validators.maxLength(512)]],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    if (this.form.get('email').value === '') {
      this.form.get('email').setValue(null);
    }

    const request = this.selected
      ? this.service.update(this.selected.id, this.form.value)
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(() => {
        this.toasterService.success('ServicingService::SuccessfullySaved');
        this.list.get();
      });
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: SiteDetailsDto) {
    this.selected = record;
    this.showForm();
  }

  updateStatus(record: SiteDetailsDto, status: boolean) {
    this.confirmation
      .warn('RegisterService::StatusConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() =>
          this.service.updateStatus(record.id, {
            isActive: status,
          })
        )
      )
      .subscribe(() => {
        this.toasterService.success('ServicingService::SuccessfullySaved');
        this.list.get();
      });
  }

  delete(record: SiteDetailsDto) {
    this.confirmation
      .warn('RegisterService::DeleteConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(() => {
        this.toasterService.success('ServicingService::SuccessfullySaved');
        this.list.get();
      });
  }
}
