import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const CATEGORIES_CATEGORY_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/categories',
        iconClass: 'fas fa-layer-group',
        name: 'RegisterService::Menu:Categories',
        parentName: 'RegisterService::Menu:RegisterService',
        order: 25,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Categories.Page',
      },

      // {
      //   path: '/categories',
      //   iconClass: 'fas fa-tools',
      //   name: 'RegisterService::Assets',
      //   parentName: 'RegisterService::Menu:Categories',
      //   order: 26,
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'RegisterService.Categories.Page',
      // },
      // {
      //   path: '/consumables/create',
      //   iconClass: 'fas fa-box',
      //   name: 'Consumables',
      //   parentName: 'RegisterService::Menu:Categories',
      //   order: 27,
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'RegisterService.Consumables.Page',
      // },
    ]);
  };
}
