import { finalize } from 'rxjs';
import { ABP, ListService, PagedResultDto, PermissionService } from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TenancyService } from '@proxy/administration-service/tenancy';
import {
  RequestDetailsDto,
  RequestsService,
  RequestStatus,
} from '@proxy/register-service/requests';
import Helpers from '../utils/helpers';

@Component({
  selector: 'app-request-table',
  templateUrl: './request-table.component.html',
  styleUrls: ['./request-table.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class RequestTableComponent implements OnInit {
  @Input() title: string = 'OpenRequests';
  @Input() status = RequestStatus.OPEN;
  @Input() onlyApproved = false;
  @Input() assetId: string;
  @Input() categoryId: string;
  @Input() maxResultCount: number = 10;
  @Input() isPageTemporary: boolean = false;
  @Input() pinOrKeyringPassword: string;
  @Input() tenantName: string | null = null;
  isModalOpen = false;
  isApproved = false;
  isLoading = false;
  helpers = Helpers;
  RequestStatus = RequestStatus;
  data: PagedResultDto<RequestDetailsDto> = {
    items: [],
    totalCount: 0,
  };
  @Output() requestUpdated = new EventEmitter<boolean>();
  @Output() handleEdit = new EventEmitter<RequestDetailsDto>();

  constructor(
    public readonly list: ListService,
    public readonly service: RequestsService,
    private tenancyService: TenancyService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.tenancyService.getRequestApproveStatus().subscribe({
      next: data => {
        this.isApproved = data;
      },
    });
    this.loadData();
  }

  canPerformAction(permission: string): boolean {
    return this.isPageTemporary || this.permissionService.getGrantedPolicy(permission);
  }
  

  loadData() {
    this.isLoading = true;
    const getData = (query: ABP.PageQueryParams) =>
      this.service
        .getList({
          ...query,
          status: this.status,
          isApproved: this.onlyApproved || this.isPageTemporary,
          categoryId: this.categoryId,
          pinOrKeyringPassword: this.pinOrKeyringPassword,
          tenantName: this.tenantName,
          maxResultCount: this.maxResultCount,
        })
        .pipe(finalize(() => (this.isLoading = false)));

    const setData = (list: PagedResultDto<RequestDetailsDto>) => {
      this.data = list;
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  onEdit(val: RequestDetailsDto) {
    this.handleEdit.emit(val);
  }

  onRefuse(id: number) {
    this.service.refuse(id, this.tenantName, this.pinOrKeyringPassword).subscribe({
      next: () => {
        this.requestUpdated.emit(true);
        this.list.get();
      },
      error: error => {
        this.service.handleErrorResponse(error);
      },
    });
  }

  onMatch(id: number) {
    if (this.assetId) {
      this.isLoading = true;
      this.service
        .match(id, this.assetId, this.tenantName, this.pinOrKeyringPassword)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: () => {
            this.list.get();
          },
          error: error => {
            this.service.handleErrorResponse(error);
          },
        });
    }
  }

  onHire(id: number) {
    this.service.hire(id, this.tenantName, this.pinOrKeyringPassword).subscribe({
      next: () => {
        this.requestUpdated.emit(false);
        this.list.get();
      },
      error: error => {
        this.service.handleErrorResponse(error);
      },
    });
  }

  onApprove(id: number, isApproved: boolean) {
    if (!isApproved) {
      this.service.approve(id).subscribe({
        next: () => this.list.get(),
        error: error => {
          this.service.handleErrorResponse(error);
        },
      });
    }
  }

  onDuplicate(id: number) {
    this.service.duplicate(id, this.tenantName, this.pinOrKeyringPassword).subscribe({
      next: () => this.list.get(),
      error: error => {
        this.service.handleErrorResponse(error);
      },
    });
  }

  onClose(id: number) {
    this.service.close(id, this.tenantName, this.pinOrKeyringPassword).subscribe({
      next: () => {
        this.requestUpdated.emit(true);
        this.list.get();
      },
      error: error => {
        this.service.handleErrorResponse(error);
      },
    });
  }
}
