import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APPLICATION_SETTINGS__ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: 'settings/application',
        iconClass: 'fa fa-gear',
        name: 'AdministrationService::Menu:ApplicationSettings',
        parentName: '::Menu:Settings',
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Application',
        order: 1,
      },
      {
        path: 'settings/global-urls',
        iconClass: 'fas fa-qrcode',
        name: 'AdministrationService::Menu:GlobalUrls',
        parentName: '::Menu:Settings',
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.SmartCode',
        order: 2,
      },
    ]);
  };
}
