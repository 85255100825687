import { TreeAdapter } from '@abp/ng.components/tree';
import { PagedResultDto } from '@abp/ng.core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { AddConsumableCount } from '@proxy/register-service/consumables';
import { CostCentreTreeNode } from '@/cost-centre/models/cost-centre-tree-node.model';

@Component({
  selector: 'app-cost-centre-tree',
  templateUrl: './cost-centre-tree.component.html',
  styleUrls: ['./cost-centre-tree.component.scss'],
})
export class CostCentreTreeComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;
  @Input() isMSL = false;
  @Input() checkStrictly: boolean = false;
  @Input() checkable: boolean = false;
  @Input() counts: AddConsumableCount[] = [];
  @Input() data: PagedResultDto<CostCentreTreeNode>;
  @Input() selectedNode: CostCentreTreeNode;
  @Input() readonly: boolean;

  @Output() addRootNode = new EventEmitter<void>();
  @Output() addSubNode = new EventEmitter<CostCentreTreeNode>();
  @Output() deleteNode = new EventEmitter<CostCentreTreeNode>();
  @Output() selectedNodeChange = new EventEmitter<CostCentreTreeNode | null>();
  @Output() checkedKeysChange = new EventEmitter<string[]>();

  expandedKeys: any[] = [];
  show: boolean = true;
  nodes: CostCentreTreeNode[];
  window = window;

  treeAdapter: TreeAdapter<CostCentreTreeNode>;

  private _checkedKeys: string[] = [];

  @Input()
  get checkedKeys(): string[] {
    return this._checkedKeys;
  }

  set checkedKeys(value: string[]) {
    this._checkedKeys = value;
    this.checkedKeysChange.emit(this._checkedKeys);
  }

  ngOnChanges({ data }: SimpleChanges) {
    if (data && !data.firstChange) {
      this.setTreeAdapterData(data.currentValue);
    }
  }

  ngOnInit(): void {
    this.setTreeAdapterData(this.data);
  }

  setTreeAdapterData(list: PagedResultDto<CostCentreTreeNode>) {
    if (!list) return;

    this.data = list;
    this.treeAdapter = new TreeAdapter(list.items);
    this.nodes = this.treeAdapter.getTree();
    this.expandedKeys = [...this.expandedKeys];
  }

  onAddRootNode() {
    this.addRootNode.emit();
  }

  onAddSubNode(node: NzTreeNode, $event: MouseEvent) {
    this.addSubNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onDelete(node: NzTreeNode, $event: MouseEvent) {
    this.deleteNode.emit(this.show ? node.origin.entity : node);
    $event.stopPropagation();
  }

  onSelectedNode(node: CostCentreTreeNode) {
    this.selectedNodeChange.emit(this.selectedNode?.id === node.id ? null : node);
  }
}
