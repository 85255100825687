import { ListService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';

import { assetColumns, eventColumns } from '@/shared/constants';
import { AssetService } from '@/proxy/register-service/assets/asset.service';
import type { AssetDetailsDto } from '@/proxy/register-service/assets/models';

@Component({
  selector: 'app-sub-assets',
  templateUrl: './sub-assets.component.html',
  styleUrls: ['./sub-assets.component.scss'],
})
export class SubAssetsComponent implements OnInit {
  @Input() asset: AssetDetailsDto;
  data: PagedResultDto<AssetDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  assetColumns = assetColumns;
  defaultPreferences = { assets: assetColumns, events: eventColumns };
  isBusy = false;
  selected: AssetDetailsDto[] = [];

  constructor(public readonly list: ListService, public readonly service: AssetService) {}

  ngOnInit() {
    this.service.getSubAssets({ ids: [this.asset.id] }).subscribe({
      next: items => {
        if (items) {
          this.data = { items, totalCount: items.length };
        }
      },
    });
  }
}
