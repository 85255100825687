import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CategoryTreeNode,
  mapCategoriesTreeNode,
} from '../../categories/models/category-tree-node.model';
import { CategoryDto, CategoryService } from '@proxy/register-service/categories';
import { EMPTY, debounceTime, finalize, switchMap } from 'rxjs';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { AssetType } from '@proxy/register-service/assets';

@Component({
  selector: 'app-category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class CategoryPickerComponent implements OnInit {
  @Input() name: string;
  @Input() readonly: boolean = false;
  @Input() knownAsOnly = false;
  @Input() withInput = false;
  @Input() form: FormGroup = new FormGroup({
    term: new FormControl(),
  });

  @Output() nodeSelected = new EventEmitter<CategoryTreeNode>();
  expandedKeys: any[] = [];
  selectedCategory?: CategoryTreeNode = null;
  selectedCategoryTemp?: CategoryTreeNode = null;
  isModalOpen = false;
  isLoading = false;
  data: PagedResultDto<CategoryTreeNode> = {
    items: [],
    totalCount: 0,
  };

  constructor(public readonly list: ListService, public readonly service: CategoryService) {}

  ngOnInit(): void {
    this.form.addControl('term', new FormControl());
    this.form
      .get('term')
      .valueChanges.pipe(
        debounceTime(250),
        switchMap(id => {
          this.list.get();
          return EMPTY;
        })
      )
      .subscribe(data => {});

    if (this.knownAsOnly) {
      this.form.get('assetType')?.valueChanges.subscribe({
        next: () => {
          this.onRemove();
        },
      });
    }
  }

  onSelectedNodeChange($event: CategoryTreeNode) {
    this.selectedCategoryTemp = $event;
  }

  getData = (query: ABP.PageQueryParams, parentId?: string) => {
    this.isLoading = true;
    return this.service
      .getList({
        ...query,
        term: this.form.get('term').value,
        show: true,
        isKnownAs: this.knownAsOnly ? this.isCategoryKnownAs() : null,
        skipCount: 0,
        parentId,
        maxResultCount: 100,
      })
      .pipe(finalize(() => (this.isLoading = false)));
  };

  setData = (list: PagedResultDto<CategoryDto>, isChilds?: boolean) => {
    if (isChilds) {
      this.data = {
        ...this.data,
        totalCount: this.data.totalCount + list.totalCount,
        items: [...this.data.items, ...mapCategoriesTreeNode(list.items)],
      };

      this.expandedKeys.push(list.items[0].parentId);
    } else {
      this.data = new PagedResultDto<CategoryTreeNode>({
        totalCount: list.totalCount,
        items: mapCategoriesTreeNode(list.items),
      });
    }
  };

  onSelectClicked() {
    this.name = null;
    this.isModalOpen = true;

    this.list.hookToQuery(this.getData).subscribe(this.setData);
  }

  toggleNodeExpansion(key: string): void {
    this.list.hookToQuery(q => this.getData(q, key)).subscribe(e => this.setData(e, true));
  }

  private isCategoryKnownAs() {
    return this.form.get('assetType')?.value ===
      (AssetType.HIRED || AssetType.LEASED || AssetType.BORROWED)
      ? true
      : null;
  }

  onSelect() {
    this.selectedCategory = this.selectedCategoryTemp;
    this.selectedCategoryTemp = null;
    this.name = this.selectedCategory.fullName;
    this.isModalOpen = false;
    this.nodeSelected.emit(this.selectedCategory);
  }

  onCancel() {
    this.name = null;
    this.selectedCategoryTemp = null;
    this.isModalOpen = false;
  }

  onRemove() {
    this.selectedCategory = null;
    this.selectedCategoryTemp = null;
    this.name = null;
    this.nodeSelected.emit(this.selectedCategory);
  }
}
