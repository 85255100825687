<div class="flat_file pb-2 flex flex-col">
  <div>
    <button class="btn btn-primary btn-sm" type="button" (click)="toggleSpace()">
      <i class="fa fa-plus" aria-hidden="true"></i>
      {{ 'AdministrationService::UploadEvents' | abpLocalization }}
    </button>
  </div>
  <div class="spaceWrapper" *ngIf="showSpace">
    <flatfile-space [spaceProps]="spaceProps"></flatfile-space>
  </div>
</div>
