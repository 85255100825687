<div class="mb-3 d-flex flex-column">
  <label class="form-label" for="asset-jobsite-location-id">
    {{ 'RegisterService::Jobsites' | abpLocalization }}
    <app-is-required *ngIf="isRequired"></app-is-required>
  </label>
  <input
    *ngIf="editing"
    id="asset-jobsite-id"
    class="form-control jobsite-input"
    maxlength="64"
    readonly
    [value]="asset.jobsiteNames.join(', ')"
  />
  <div class="d-flex" *ngIf="!editing">
    <input
      id="asset-jobsite-ids"
      class="form-control jobsite-input"
      maxlength="64"
      readonly
      [value]="jobsiteNames.length ? jobsiteNames.join(', ') : ''"
    />
    <button
      class="jobsite-input btn btn-primary"
      (click)="isJobsiteModalOpen = !isJobsiteModalOpen; getJobsites()"
    >
      {{ 'RegisterService::PickJobsites' | abpLocalization }}
    </button>
  </div>
</div>

<abp-modal [(visible)]="isJobsiteModalOpen" [options]="{ size: 'lg' }">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::Jobsites' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="col-12 col-sm-auto">
      <div class="gap-2 mb-1">
        <div class="mb-3">
          <abp-tree
            [checkStrictly]="true"
            [checkable]="true"
            [nodes]="nodes"
            [(checkedKeys)]="checkedKeys"
            [(expandedKeys)]="expandedKeys"
            [isNodeSelected]="functionForTree.selectFn"
          >
          </abp-tree>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button
      type="button"
      class="btn btn-primary"
      (click)="formatJobsiteNames()"
      [disabled]="checkedKeys.length === 0"
    >
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
