import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '@proxy/administration-service/settings';
import {
  AssetActionModalType,
  AssetDetailsDto,
  AssetTransferableParts,
} from '@proxy/register-service/assets';

@Component({
  selector: 'app-queue-modals',
  templateUrl: './queue-modals.component.html',
  styleUrls: ['./queue-modals.component.scss'],
})
export class QueueModalsComponent implements OnInit {
  @Input() asset: AssetDetailsDto;
  @Input() assets: AssetDetailsDto[];
  @Input() isModalOpen: boolean = false;
  @Input() showMessage = false;
  @Input() pinOrKeyringPassword: string = '';
  @Input() selected: string[];
  @Input() type: AssetActionModalType | null = null;
  @Output() modalClose = new EventEmitter<boolean>();

  twoStepEnabled = false;
  showTransferModal: boolean = false;
  transferData: AssetTransferableParts | null = null;

  AssetActionModalType = AssetActionModalType;
  constructor(public readonly service: SettingsService) {}

  ngOnInit(): void {
    if (this.asset) {
      this.twoStepEnabled = !!(
        this.asset.hasSubAssets ||
        this.asset.components ||
        this.asset.isParent
      );
      // this.service.getAccountSettings().subscribe({
      //   next: data => {
      //     this.twoStepEnabled = data.twoStepEnabled;
      //   },
      // });
      // this.showTransferModal = true;
    }
  }

  triggerTransferAction(data: AssetTransferableParts | null) {
    this.transferData = data;
  }

  closeModal(isApplied: boolean) {
    this.modalClose.emit(isApplied);
  }
}
