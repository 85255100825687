<abp-modal [(visible)]="openDeallocateModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::DeallocateAssets' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="col-12 col-sm-auto">
      <div class="mb-3">
        <label class="form-label" for="parentJobsitePicker">
          {{ 'RegisterService::Jobsite' | abpLocalization }}
        </label>
        <abp-lookup-select
          cid="parentJobsitePicker"
          displayNameProp="name"
          [getFn]="getJobsiteLookup"
          [(ngModel)]="parentJobsiteId"
          (ngModelChange)="getChildJobsiteList()"
        ></abp-lookup-select>
      </div>
      <div class="mb-3 childJobsites" *ngIf="parentJobsiteId">
        <label class="form-label me-2 mb-2" for="locationList">
          {{ 'RegisterService::JobsiteChildren' | abpLocalization }}
        </label>
        <div class="list d-flex flex-column">
          <div
            type="button"
            *ngFor="let location of data.items"
            class="mb-1 me-1 childs"
            (click)="selectLocation(location)"
            [ngClass]="{ selected: selectedLocation === location }"
          >
            {{ location.name }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" (click)="deallocate()" [disabled]="isBusy">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
