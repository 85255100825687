import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteService } from '@proxy/register-service/sites';
import { LocationDto } from '@proxy/register-service/locations';
import { LocationDefinition } from '@register/proxy/register-service/locations/location-type.enum';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  @Output() handleSelect = new EventEmitter<LocationDto>();

  @Input() isLoading = false;
  @Input() readonly = false;
  @Input() hideSelect = false;
  @Input() getAll = false;
  @Input() selectedLocation: LocationDto = null;
  @Input() checkStrictly: boolean = false;
  @Input() checkable: boolean = false;
  @Input() checkedKeys: string[] = [];
  @Input() restrictedKeys: string[] = [];
  @Output() selectedLocationChange = new EventEmitter<LocationDto>();
  @Output() restrictedKeysChange = new EventEmitter<string[]>();
  @Output() checkedKeysChange = new EventEmitter<any[]>();
  siteId: string;
  locationDefinition = LocationDefinition;
  locationTypeKeys: {
    [key in LocationDefinition]: string[];
  } = {
    [LocationDefinition.HOME]: [],
    [LocationDefinition.SECONDARY]: [],
    [LocationDefinition.JOBSITE]: [],
  };

  constructor(public readonly siteService: SiteService) {}

  ngOnInit(): void {
    Object.keys(LocationDefinition).forEach(e => {
      this.locationTypeKeys[e] = this.restrictedKeys;
    });
  }

  onSiteIdChange(newSiteId: string) {
    this.siteId = newSiteId;
    this.selectedLocationChange.emit(null);
  }

  onCheckClick({ type, items }) {
    this.locationTypeKeys[type] = items;
    const newItems = Array.from(
      new Set([].concat.apply([], Object.values(this.locationTypeKeys)))
    ) as string[];
    this.restrictedKeysChange.emit(newItems);
  }

  selectLocation(location: LocationDto) {
    this.handleSelect.emit(location);
  }
}
