import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '@abp/ng.core';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CustomAuthService } from '@proxy/auth/auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private customAuthService: CustomAuthService,
    private authService: AuthService,
    private router: Router
  ) {}

  private appendUnauthorizedQuery(): void {
    const currentUrl = this.router.url;

    const [urlPath, urlQueryParams] = currentUrl.split('?');
    const queryParams = { currentState: '0' };

    if (urlQueryParams) {
      const params = new URLSearchParams(urlQueryParams);
      params.forEach((value, key) => {
        queryParams[key] = value;
      });
    }

    this.router.navigate([urlPath], { queryParams });
    // this.router.navigate([urlPath], { onSameUrlNavigation:'reload' });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const exp = environment.oAuthConfig.issuer.replace('{0\}.', '(.+\.|)');
    const regex = new RegExp(exp, 'i');
    const isSecureAuthUrl = regex.test(req.url) && req.url.startsWith('https://');
    if (isSecureAuthUrl) {
      req = req.clone({
        headers: req.headers.set('from-ingress', '1'),
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !this.isRefreshing) {
          this.isRefreshing = true;
          console.log('Check for 401 Unauthorized error');

          const refreshToken = localStorage.getItem('refresh_token');

          if (refreshToken) {
            return this.customAuthService.refreshToken(refreshToken).pipe(
              switchMap(newToken => {
                this.isRefreshing = false;
                console.log(`Check for ${this.isRefreshing}`);

                localStorage.setItem('access_token', newToken.access_token);
                localStorage.setItem('refresh_token', newToken.refresh_token);

                const clonedRequest = req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${newToken.access_token}`),
                });

                return next.handle(clonedRequest);
              }),
              catchError(() => {
                this.isRefreshing = false;
                // console.log(`Check for ${this.isRefreshing}  2`);
                // this.appendUnauthorizedQuery();
                // window.location.reload();
                this.authService.navigateToLogin();
                return of(null);
              })
            );
          } else {
            console.log(`Check for ${this.isRefreshing}  3`);
            this.appendUnauthorizedQuery();
            // window.location.reload();
            // this.authService.navigateToLogin();
          }
        }

        return throwError(error);
      })
    );
  }
}
