<div class="card h-100">
  <div class="card-header d-flex justify-content-end align-items-center" *ngIf="!readonly">
    <div class="buttons-container d-flex gap-2" id="buttons-container">
      <button
        *abpPermission="'RegisterService.CostCentres.Create'"
        class="btn btn-sm btn-primary"
        (click)="onAddRootNode()"
      >
        <i class="fas fa-plus me-1"></i>
        {{ 'RegisterService::AddCostCentre' | abpLocalization }}
      </button>
    </div>
  </div>

  <div class="card-body" [abpLoading]="isLoading" *ngIf="show">
    <abp-tree
      [nodes]="nodes"
      [(expandedKeys)]="expandedKeys"
      [selectedNode]="selectedNode"
      [checkable]="checkable"
      [checkStrictly]="checkStrictly"
      [(checkedKeys)]="checkedKeys"
      [draggable]="false"
      (selectedNodeChange)="onSelectedNode($event)"
    >
      <ng-template abpTreeNodeTemplate let-node>
        <div
          class="node-container d-flex"
          [style.background-color]="node.origin.entity.color"
          [class.selected]="node.origin.id === selectedNode?.id"
          [ngClass]="{ 'node-hidden': show === false }"
          (mouseover)="node.isHovered = true"
          (mouseleave)="node.isHovered = false"
        >
          <i class="fas fa-folder fs-15px text-primary me-1" id="node-icon"></i>
          <div class="d-flex justify-content-between w-100 label-icon-container">
            <label for="node-icon" class="me-2 cursor-pointer label-width">
              {{ node.title }}
            </label>
            <div
              *ngIf="(window.innerWidth > 480 ? node.isHovered : true) && !readonly"
              class="d-flex align-items-center"
            >
              <!-- <i
                class="fas fa-plus fa-lg me-2"
                *abpPermission="'RegisterService.Categories.Edit'"
                (click)="onAddSubNode(node, $event)"
              ></i> -->
              <div *ngIf="!node.origin.entity.referenceId" class="">
                <i
                  class="fas fa-trash-alt fa-lg"
                  *abpPermission="'RegisterService.Categories.Delete'"
                  (click)="onDelete(node, $event)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </abp-tree>
    <p *ngIf="!isLoading && !nodes?.length" class="text-muted">
      {{ 'RegisterService::NoCostCentre' | abpLocalization }}
    </p>
  </div>
</div>
