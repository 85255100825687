import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IAccountSettings } from './models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiName = 'AdministrationService';

  getAccountSettings = () =>
    this.restService.request<any, IAccountSettings>(
      {
        method: 'GET',
        url: `/api/account-admin/settings`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
