import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CountryCreateDto, CountryDto, CountryUpdateDto, GetCountriesInput } from './models';
import { LookupDto, LookupRequestDto } from '@proxy/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  apiName = 'AdministrationService';

  create = (input: CountryCreateDto) =>
    this.restService.request<any, CountryDto>({
      method: 'POST',
      url: '/api/admin/countries',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/admin/countries/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, CountryDto>({
      method: 'GET',
      url: `/api/admin/countries/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: GetCountriesInput) =>
    this.restService.request<any, PagedResultDto<CountryDto>>({
      method: 'GET',
      url: '/api/admin/countries',
      params: {
        term: input.term,
        name: input.name,
        isActive: input.isActive,
        sorting: input.sorting,
        skipCount: input.skipCount,
        maxResultCount: input.maxResultCount
      },
    },
      { apiName: this.apiName });

  getLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/admin/countries/lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    ).pipe(map(result => ({
      items: result.items.map(item => ({ displayName: item.displayName, id: item.displayName })),
      totalCount: result.totalCount
    })));

  update = (id: string, input: CountryUpdateDto) =>
    this.restService.request<any, CountryDto>({
      method: 'PUT',
      url: `/api/admin/countries/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
