<div class="col">
  <div class="mb-3">
    <label class="form-label" for="eventTypes">
      {{ 'ServicingService::Menu:EventTypes' | abpLocalization }}
    </label>
    <select
      name="eventTypes"
      id="eventTypes"
      class="form-select form-control"
      [(ngModel)]="filters.eventTypeDefinition"
      [ngModelOptions]="{ standalone: true }"
      (change)="onEventTypeChange()"
    >
      <option [ngValue]="null"></option>
      <option *ngFor="let option of eventTypeDefinitionOptions" [ngValue]="option.value">
        {{ 'ServicingService::' + helpers.normalizeText(option.value) | abpLocalization }}
      </option>
    </select>
  </div>
  <div class="col" *ngIf="filters.eventTypeDefinition">
    <div *ngFor="let item of data.items" class="form-check mb-3">
      <input
        type="checkbox"
        class="form-check-input"
        [id]="item.id"
        (change)="onSelect($event, item.id)"
        [checked]="selected.includes(item.id)"
      />
      <label class="form-check-label" [attr.for]="item.id">{{ item.name }}</label>
    </div>
  </div>
</div>
