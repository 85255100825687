import { EnvironmentService, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CategoryCreateDto,
  CategoryDto,
  CategoryUpdateDto,
  CategoryDetailsDto,
  GetCategoriesInput,
  knownAsInput,
  IKnownAsRes,
} from './models';
import type { LookupDto, LookupRequestDto } from '@proxy/shared/models';
import { ConfirmationService } from '@abp/ng.theme.shared';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/categories';

  create = (input: CategoryCreateDto) => {
    var formdata = new FormData();
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        const val = input[key];
        formdata.append(key, val != null ? val : '');
      }
    }

    return this.restService.request<any, CategoryDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: formdata,
      },
      { apiName: this.apiName }
    );
  };

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getPicture = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/picture`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  getPictureUrl = (id: string) => `${this.urlPrefix}/${id}/picture`;

  deletePicture = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/picture`,
      },
      { apiName: this.apiName }
    );

  getLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getList = (input: GetCategoriesInput) =>
    this.restService.request<any, PagedResultDto<CategoryDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          name: input.name,
          isActive: input.isActive,
          show: input.show,
          knownAs: input.knownAs,
          isKnownAs: input.isKnownAs,
          notes: input.notes,
          picture: input.picture,
          parentId: input.parentId,
          attributeDefinitionGroupId: input.attributeDefinitionGroupId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string) =>
    this.restService.request<any, CategoryDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/details`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CategoryUpdateDto) => {
    return this.restService.request<any, CategoryDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  };

  addManufacturer = (id: string, manufacturerId: string) =>
    this.addManufacturers(id, [manufacturerId]);

  addManufacturers = (id: string, manufacturerIds: string[]) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/manufacturers`,
        body: { manufacturerIds },
      },
      { apiName: this.apiName }
    );

  removeManufacturer = (id: string, manufacturerId: any) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/manufacturers/${manufacturerId}`,
      },
      { apiName: this.apiName }
    );

  deactivate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/deactivate`,
      },
      { apiName: this.apiName }
    );

  activate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/activate`,
      },
      { apiName: this.apiName }
    );

  makeKnownAs = (input: knownAsInput) =>
    this.restService.request<any, IKnownAsRes>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/knownAs`,
        params: {
          id: input.id,
          isKnownAs: input.isKnownAs,
        },
      },
      { apiName: this.apiName }
    );

  checkIfInUse = (id: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/isInUse`,
      },
      { apiName: this.apiName }
    );

  handleErrorResponse(error: any): void {
    this.confirmation.info(error.error.error.message, 'Category Creation', {
      cancelText: 'OK',
      hideYesBtn: true,
    });
    return;
  }

  constructor(
    private restService: RestService,
    private environment: EnvironmentService,
    public confirmation: ConfirmationService
  ) {}
}
