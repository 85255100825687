<abp-modal [(visible)]="openModal" (disappear)="modalClose.emit(false)" [options]="{ size: 'md' }">
  <ng-template #abpHeader>
    <div class="flex flex-col gap-1">
      <h3 class="font-semibold !text-[18px]">
        {{ title | abpLocalization }}
      </h3>
      <span class="text-[16px]">
        {{ 'RegisterService::ConfirmMessage' | abpLocalization }}
      </span>
    </div>
  </ng-template>

  <ng-template #abpBody>
    <div class="gap-3">
      <label class="form-label mb-3 font-medium text-[16px]">
        {{ 'RegisterService::SubAssets' | abpLocalization }}
      </label>
      <div class="flex justify-between gap-3">
        <div class="flex flex-col gap-2" *ngFor="let asset of allAssets">
          <label class="form-label font-medium text-[16px]" for="asset-components">
            {{ 'RegisterService::ParentAsset' | abpLocalization }}:
            <label class="font-medium">
              {{ asset.primaryID }}
            </label>
          </label>
          <div class="flex flex-col gap-1" *ngFor="let item of asset.children">
            <div class="flex gap-4">
              <div class="flex flex-col w-full gap-2">
                <div class="flex gap-2">
                  <input
                    type="checkbox"
                    class="form-check-input min-w-[1.25em]"
                    [id]="item.id"
                    (change)="onSelectSubAssets($event, item)"
                    [checked]="isSelected(item.id)"
                  />
                  <label class="font-medium text-[16px]">
                    {{ item.primaryID }}
                  </label>
                  <!-- <label class="form-label">
                    {{ item.categoryNickname }}
                  </label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" [disabled]="isBusy" (click)="onTransfer()">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
