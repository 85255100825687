import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const COST_CENTRE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/cost-centre',
        iconClass: 'fas fa-coins',
        name: 'RegisterService::CostCentre',
        parentName: 'RegisterService::Menu:RegisterService',
        order: 27,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.CostCentres.Page',
      },
    ]);
  };
}
