import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CostCentreCreateDto, CostCentreDto, GetCostCentreInput } from './models';
import { ConfirmationService } from '@abp/ng.theme.shared';

@Injectable({
  providedIn: 'root',
})
export class CostCentreService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/cost-centre';

  create = (input: CostCentreCreateDto) =>
    this.restService.request<any, CostCentreDto>(
      {
        method: 'POST',
        url: this.urlPrefix,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, CostCentreDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetCostCentreInput) =>
    this.restService.request<any, PagedResultDto<CostCentreDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          name: input.name,
          email: input.email,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CostCentreDto) => {
    return this.restService.request<any, CostCentreDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: { ...input, mode: undefined },
      },
      { apiName: this.apiName }
    );
  };

  handleErrorResponse(error: any): void {
    this.confirmation.info(error.error.error.message, 'Cost Centre Creation', {
      cancelText: 'OK',
      hideYesBtn: true,
    });
    return;
  }

  constructor(private restService: RestService, public confirmation: ConfirmationService) {}
}
