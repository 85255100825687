import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const current = new Date();
const today = convertDateToNgbDatepickerFromat(current);

function convertDateToNgbDatepickerFromat(date: Date): NgbDateStruct {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}

export { today, convertDateToNgbDatepickerFromat };
