import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SelectionType } from '@swimlane/ngx-datatable';
import type {
  GetInspectionTypesInput,
  InspectionTypeDto,
} from '@/proxy/servicing-service/inspection-types/models';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { eventTypeDefinitionOptions } from '@proxy/servicing-service/inspection-types';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { InspectionTypeService } from '@/proxy/servicing-service/inspection-types/inspection-type.service';
import { EventTypeDefinition } from '@proxy/servicing-service/inspection-types';
import Helpers from '@/shared/utils/helpers';

@Component({
  selector: 'app-event-types',
  templateUrl: './event-types.component.html',
  styleUrls: ['./event-types.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class EventTypesComponent implements OnInit {
  @Input() restrictedKeys: string[] = [];
  data: PagedResultDto<InspectionTypeDto> = {
    items: [],
    totalCount: 0,
  };

  helpers = Helpers;
  filters = { eventTypeDefinition: EventTypeDefinition.STANDARD } as GetInspectionTypesInput;
  allKeys: string[] = [];
  isBusy = false;
  selected: string[] = [];
  eventTypeDefinitionOptions = eventTypeDefinitionOptions;
  @Output() restrictedKeysChange = new EventEmitter<string[]>();

  constructor(public readonly list: ListService, public readonly service: InspectionTypeService) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
      });

    const setData = (list: PagedResultDto<InspectionTypeDto>) => {
      this.data = list;
      this.allKeys = list.items.map(e => e.id);
      this.selected = this.allKeys.filter(e => !this.restrictedKeys.includes(e));
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  onEventTypeChange() {
    if (this.filters.eventTypeDefinition) this.list.get();
  }

  onSelect(event: any, id: string) {
    if (event.target.checked) {
      this.selected.push(id);
    } else {
      this.selected = this.selected.filter(selectedId => selectedId !== id);
    }

    const externalRestrictedKeys = this.restrictedKeys.filter(key => !this.allKeys.includes(key));
    const restrictedKeys = this.allKeys
      .filter(key => !this.selected.includes(key))
      .concat(externalRestrictedKeys);

    this.restrictedKeysChange.emit(restrictedKeys);
  }
}
