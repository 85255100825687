import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ASSETS_ASSET_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      // {
      //   path: '/assets',
      //   iconClass: 'fas fa-file-alt',
      //   name: 'RegisterService::Menu:Assets',
      //   parentName: 'RegisterService::Menu:RegisterService',
      //   order: 23,
      //   layout: eLayoutType.application,
      //   requiredPolicy: 'RegisterService.Assets',
      // },
    ]);
  };
}
