import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomAuthService {
  apiName = 'CustomAuthService';

  constructor(private httpClient: HttpClient) {}

  refreshToken(refreshToken: string): Observable<any> {
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refreshToken);
    body.set('client_id', environment.oAuthConfig.clientId);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.httpClient.post(`${environment.oAuthConfig.issuer}connect/token`, body.toString(), {
      headers,
    });
  }
}
