import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-web-link',
  template: `<div class="file-web-link margin-bottom">
                <a
                  href="javascript:;"
                  class="text-muted text-medium"
                  (click)="hideWebLink.emit()"
                >
                  {{ '::Attachments:BackToUpload' | abpLocalization }}
                </a>
                <div class="input-group">
                  <input
                    [(ngModel)]="url"
                    type="text"
                    class="form-control"
                    placeholder="{{ '::Attachments:AttachWebLink' | abpLocalization }}"
                    (change)="webLinkChange.emit($event)"
                  />
                </div>
              </div>
            `,
  styles: [`.file-web-link .input-group {
              margin-top: 10px;
            }`]
  })
export class WebLinkComponent {
  @Output() hideWebLink = new EventEmitter<void>();
  @Output() webLinkChange = new EventEmitter<any>();
  url = '';
}
