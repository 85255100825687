<!-- <div ngbDropdown class="d-inline-block queue-button">
  <a id="dropdownQueue" class="btn position-relative" role="button" ngbDropdownToggle>
    <i class="bi bi-card-list"></i>
    <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-info">
      {{ total }}
      <span class="visually-hidden">Queue size</span>
    </span>
  </a>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-end queue-dropdown"
    data-bs-popper="none"
    aria-labelledby="dropdownQueue"
  >
    <a
      *ngIf="queueSize$ | async as queueSize"
      href="javascript:;"
      class="dropdown-item text-center width-300"
      ngbDropdownItem
      >Empty queue</a
    >
    <a
      *ngFor="let item of queue$ | async"
      href="javascript:;"
      class="dropdown-item pointer text-center"
      ngbDropdownItem
      (click)="onItemClick(item)"
    >
      Asset ID {{ item?.assetPrimaryID }}{{ ' / ' + item?.assetSecondaryID }}</a
    >
    <a
      [routerLink]="['/queue']"
      href="javascript:;"
      class="dropdown-item text-center width-300 pointer"
      ngbDropdownItem
      >View Queue</a
    >
  </div>
</div> -->

<div (click)="toggleCtxMenu()">
  <i class="bi bi-card-list"></i>
  <ng-container>
    <lpx-context-menu #menu="lpx-context-menu" (lpxClickOutside)="menu.close()" [exceptedRefs]="[]">
      <lpx-context-menu-header>
        <div class="lpx-user-ctx-header">
          <!-- <div class="lpx-user-ctx-img">
            <lpx-avatar [avatar]="data.user?.avatar"></lpx-avatar>
          </div> -->
          <!-- <i class="bi bi-card-list"></i> -->
          <div class="lpx-user-ctx-info">
            <!--TODO: muhammed copy lepton's username display logic-->
            <span class="lpx-context-menu-user-name">{{
              'QueueService::Menu:QueueService' | abpLocalization
            }}</span>
            <!-- <span
              *ngIf="data.user?.tenant?.name as tenantName"
              class="lpx-context-menu-user-tenant"
            >
              {{ tenantName }}
            </span> -->
            <!-- <span class="lpx-context-menu-user-email">{{
              data.user?.email
            }}</span> -->
          </div>
        </div>
      </lpx-context-menu-header>

      <ng-container *ngrxLet="queue$ | async as queue">
        <lpx-context-menu-action-group>
          <lpx-navbar-routes [navbarItems]="queue" [routerItem]="false"></lpx-navbar-routes>
          <lpx-navbar-routes
            [navbarItems]="[
              {
                text: 'View All',
                link: '/queue'
              }
            ]"
            [routerItem]="false"
          ></lpx-navbar-routes>
        </lpx-context-menu-action-group>
      </ng-container>
    </lpx-context-menu>
  </ng-container>
</div>
