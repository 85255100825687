import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { columnTranslationKey } from '../constants';

@Component({
  selector: 'app-column-fields',
  templateUrl: './column-fields.component.html',
  styleUrls: ['./column-fields.component.scss'],
})
export class ColumnFieldsComponent {
  @Input() keys: { [x: string]: boolean } = {};
  columnTranslationKey = columnTranslationKey;

  @Output() keysChange = new EventEmitter<{ [x: string]: boolean }>();

  constructor() {}

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  onSelect(event: any, item: { key: string; value: boolean }) {
    const updatedKeys = { ...this.keys };

    updatedKeys[item.key] = event.target.checked;

    this.keysChange.emit(updatedKeys);
  }
}
