import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetManufacturersInput,
  ManufacturerCreateDto,
  ManufacturerDto,
  ManufacturerUpdateDto,
} from './models';
import { LookupDto, LookupRequestDto } from '@proxy/shared';
import { ManufacturerLookupRequestDto } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService {
  apiName: string = 'RegisterService';
  urlPrefix: string = '/api/register/manufacturers';

  create = (input: ManufacturerCreateDto) =>
    this.restService.request<any, ManufacturerDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ManufacturerDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetManufacturersInput) =>
    this.restService.request<any, PagedResultDto<ManufacturerDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          name: input.name,
          isActive: input.isActive,
          categoryId: input.categoryId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: ManufacturerLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
        params: {
          categoryPath: input.categoryPath,
          filter: input.filter,
          isActive: input.isActive,
          categoryId: input.categoryId,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: ManufacturerUpdateDto) =>
    this.restService.request<any, ManufacturerDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
