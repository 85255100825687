import { BaseNode } from '@abp/ng.components/tree';
import { ConsumableDto } from '@proxy/register-service/consumables';

export class ConsumableTreeNode extends BaseNode {
  nickname?: string;
  path?: string;
  hasPicture?: boolean;
  totalCount?: number;
  fullName?: string;
  childCount?: number;
  rrpCost?: number;
  hasUnitCategory?: boolean;
  unit?: number;
}

export function mapConsumablesTreeNode(dtos: ConsumableDto[]): ConsumableTreeNode[] {
  if (!dtos) return [];

  return dtos.map(mapConsumableTreeNode);
}

export function mapConsumableTreeNode(dto: ConsumableDto): ConsumableTreeNode {
  const node = new ConsumableTreeNode(dto.id, dto.parentId);
  node.unit = dto.unit;
  node.totalCount = dto.totalCount;
  node.childCount = dto.childCount;
  node.hasUnitCategory = dto.hasUnitCategory;
  node.name = dto.name;
  node.nickname = dto.nickname;
  node.fullName = dto.fullName;
  node.parentId = dto.parentId;
  node.hasPicture = dto.hasPicture;
  node.rrpCost = dto.rrpCost;
  return node;
}
