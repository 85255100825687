import { BaseNode } from '@abp/ng.components/tree';
import { CategoryDetailsDto, CategoryDto } from '@proxy/register-service/categories';
import { NzTreeNode } from 'ng-zorro-antd/tree';

export class CategoryTreeNode extends BaseNode {
  attributeDefinitionGroupId?: string;
  color?: string;
  fullName?: string;
  hireDayCost?: number;
  hirePickupCost?: number;
  inhouseDayCost?: number;
  inhousePickupCost?: number;
  isActive?: boolean;
  isDefault?: boolean;
  nickname?: string;
  notes?: string;
  hasChildren?: boolean;
  path?: string;
  pictureUrl?: string;
  referenceId?: string;
  rrpCost?: number;
  show?: boolean;
  key?: string;
}

export function mapCategoriesTreeNode(dtos: CategoryDto[]): CategoryTreeNode[] {
  if (!dtos) return [];

  return dtos.map(mapCategoryTreeNode);
}

export function mapCategoryTreeNode(dto: CategoryDetailsDto): CategoryTreeNode {
  const node = new CategoryTreeNode(dto.id, dto.parentId);
  node.attributeDefinitionGroupId = dto.attributeDefinitionGroupId;
  node.color = dto.color;
  node.displayName = dto.displayName;
  node.fullName = dto.fullName;
  node.hireDayCost = dto.hireDayCost;
  node.hirePickupCost = dto.hirePickupCost;
  node.inhouseDayCost = dto.inhouseDayCost;
  node.inhousePickupCost = dto.inhousePickupCost;
  node.isActive = dto.isActive;
  node.isDefault = dto.isDefault;
  node.name = dto.name;
  node.hasChildren = dto.hasChildren;
  node.nickname = dto.nickname;
  node.notes = dto.notes;
  node.parentId = dto.parentId;
  node.path = dto.path;
  node.pictureUrl = dto.pictureUrl;
  node.referenceId = dto.referenceId;
  node.rrpCost = dto.rrpCost;
  node.show = dto.show;
  return node;
}
