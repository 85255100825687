<div class="mb-3">
  <label class="text-black-base font-medium text-[16px]">
    <a [routerLink]="'/assets/view/' + parent.id">
      {{ 'RegisterService::ParentAsset' | abpLocalization }}:</a
    >
  </label>
  <div class="flex gap-4">
    <div class="flex gap-2">
      <label class="text-black-base font-medium"> ID1/ID2: </label>

      <label class="form-label"> {{ parent.primaryID }}/{{ parent.secondaryID || '-' }} </label>
    </div>
    <div class="flex gap-2">
      <label class="form-label text-black-base font-medium">
        {{ 'RegisterService::Nickname' | abpLocalization }}:
      </label>
      <label class="form-label">
        {{ parent.categoryNickname }}
      </label>
    </div>
  </div>
</div>
