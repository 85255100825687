import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const CONSUMABLES_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/consumables',
        iconClass: 'fas fa-box',
        name: 'RegisterService::Menu:Consumables',
        order: 18,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Consumables.Page',
      },
    ]);
  };
}
