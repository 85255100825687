<abp-modal [(visible)]="openModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <div class="flex flex-col gap-1">
      <h3 class="font-semibold !text-[18px]">
        {{ 'RegisterService::' + ((type | titlecase) + 'Assets') | abpLocalization }}:
        <label class="font-medium text-[16px]">
          {{ asset.primaryID }}
        </label>
      </h3>

      <span class="text-[16px]" *ngIf="subAssets.length || asset.components">
        {{ 'RegisterService::ConfirmMessage' | abpLocalization }}
      </span>
    </div>
  </ng-template>
  <ng-template #abpBody>
    <div class="gap-3">
      <label class="form-label mb-3 font-medium text-[16px]" *ngIf="subAssets.length">
        {{ 'RegisterService::SubAssets' | abpLocalization }}
      </label>
      <div class="mb-4" *ngFor="let item of subAssets">
        <div class="flex gap-4">
          <div class="flex flex-col w-full gap-2">
            <div class="flex gap-2">
              <input
                type="checkbox"
                class="form-check-input min-w-[1.25em]"
                [id]="item.id"
                [disabled]="transferData.missingIds.includes(item.id)"
                (change)="selectSubAssets($event, item)"
                [checked]="isSelected(item.id)"
              />
              <label class="font-medium text-[16px]">
                {{ item.primaryID }}
              </label>
              <!-- <label class="form-label">
                {{ item.categoryNickname }}
              </label> -->
            </div>
            <div class="flex flex-col ml-7" *ngIf="item.components">
              <label class="form-label font-medium text-[14px]" for="asset-components">
                {{ 'RegisterService::Components' | abpLocalization }}
              </label>
              <div class="form-check flex items-center gap-2">
                <input
                  type="checkbox"
                  class="form-check-input min-w-[1.25em]"
                  [id]="item.id"
                  [disabled]="transferData.missingIds.includes(item.id)"
                  (change)="selectSubComponents($event, item.id)"
                  [checked]="isSubComponentSelected(item.id)"
                />

                <textarea
                  id="asset-components"
                  [value]="item.components"
                  rows="3"
                  class="form-control"
                  maxlength="5120"
                  readonly
                ></textarea>
              </div>
            </div>
          </div>
          <div class="flex h-max">
            <button
              *ngIf="!isSelected(item.id)"
              type="button"
              [ngClass]="transferData.missingIds.includes(item.id) ? 'btn-secondary' : 'bg-danger'"
              class="btn py-0.5 px-3 text-white"
              (click)="setMissing(item.id)"
            >
              {{
                (transferData.missingIds.includes(item.id)
                  ? 'RegisterService::Discard'
                  : 'RegisterService::Missing'
                ) | abpLocalization
              }}
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col mb-3" *ngIf="asset.components">
        <label class="form-label font-medium text-[16px]" for="asset-components">
          {{ 'RegisterService::Components' | abpLocalization }}
        </label>
        <div class="form-check flex items-center gap-2">
          <input
            type="checkbox"
            class="form-check-input min-w-[1.25em]"
            id="components"
            (change)="selectComponents($event)"
            [checked]="transferData.componentStatus === ComponentStatus.Exist"
          />

          <textarea
            id="asset-components"
            [value]="asset.components"
            rows="3"
            class="form-control"
            maxlength="5120"
            readonly
          ></textarea>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label font-medium text-[16px]" for="asset-notes">
          {{ 'RegisterService::Notes' | abpLocalization }}
        </label>
        <textarea
          id="asset-notes"
          rows="3"
          [(ngModel)]="transferData.notes"
          class="form-control"
          maxlength="5120"
        ></textarea>
      </div>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" [disabled]="isBusy" (click)="onTransfer()">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
