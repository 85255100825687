import { Component, Input } from '@angular/core';
import { AssetDetailsDto } from '@proxy/register-service/assets';

@Component({
  selector: 'app-assets-parent-info',
  templateUrl: './assets-parent-info.component.html',
})
export class AssetsParentInfoComponent {
  @Input() parent: AssetDetailsDto;
}
