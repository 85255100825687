<div class="mb-3">
  <ngx-datatable [rows]="data.items" [count]="data.totalCount" [list]="list" default>
    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::ActionsType' | abpLocalization }}"
      prop="eventType"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.eventType }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'RegisterService::ActionDate' | abpLocalization }}"
      prop="actionDate"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.actionDate | date }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'RegisterService::FromLocation' | abpLocalization }}"
      prop="fromLocationNames"
      [sortable]="false"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.fromLocationNames?.join(', ') }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::ToLocation' | abpLocalization }}"
      prop="toLocationName"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.toLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::TargetLocation' | abpLocalization }}"
      prop="targetLocationName"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.targetLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::Components' | abpLocalization }}"
      prop="componentStatus"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-container *ngIf="row.componentStatus !== 'NONE'; else emptyTemplate">
          <app-yes-no-display [value]="row.componentStatus === 'EXIST'"></app-yes-no-display>
        </ng-container>
        <ng-template #emptyTemplate> - </ng-template>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      name="{{ 'RegisterService::Notes' | abpLocalization }}"
      prop="notes"
      [width]="110"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="flex flex-wrap text-wrap">
          {{ row?.notes }}
        </span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
