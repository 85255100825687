import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttachmentCreateDto } from '@proxy/attachments';
import { WebLinkComponent } from './web-link.component';
import { HttpClient } from '@angular/common/http';
import { ConfigStateService, getShortDateFormat } from '@abp/ng.core';
import { today } from '@proxy/shared/getToday';
function runSerially(tasks) {
  let result = Promise.resolve();
  tasks.forEach(task => {
    if (task) {
      result = result.then ? result.then(task) : Promise.resolve();
    }
  });
  return result;
}

@Component({
  selector: 'app-file-attacher',
  templateUrl: './file-attacher.component.html',
  styleUrls: ['./file-attacher.component.scss'],
})
export class FileAttacherComponent {
  @Input() showUploadButton = true;
  @Input() disableTakePhoto = true;
  @Input() allowMultiple = true;
  @Input() asDataurl = false;
  @Input() attachedToEntity: string = null;
  @Input() attachedToId: string = null;
  @Input() ownerType: string;
  @Input() ownerId: string;
  @Input() folder: string = 'Main';
  @Input() fieldname: string = null;
  @Input() restrictions = {
    maxFileSize: 2097152, // 2048 -> 2KB 200000 -> 2mb
    maxNumberOfFiles: null,
    allowedFileTypes: [], // ['image/*', 'video/*', '.jpg', '.gif', '.pdf']
  };
  @Input() uploadNotes: string = null; // "Images or video, upto 2MB"
  @Input() isModalBusy: boolean;
  @Output() submitted: EventEmitter<AttachmentCreateDto[]> = new EventEmitter<
    AttachmentCreateDto[]
  >();
  // @Input() upload!: Function;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(WebLinkComponent) webLink: WebLinkComponent;
  readonly getShortDateFormat = getShortDateFormat;

  currentlyUploading = -1;
  expirationDate: string;
  fileUrl = '';
  form: FormGroup;
  groupName: string = '';
  isDragging = false;
  showWebLink = false;
  today = today;

  constructor(
    readonly configState: ConfigStateService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.buildForm();
  }

  // get uploadComplete() {
  //   return this.files.length > 0 && this.files.every(file => file.total !== 0 && file.progress === file.total);
  // }

  get allowTakePhoto() {
    return !this.disableTakePhoto && window.navigator.mediaDevices;
  }

  get files(): File[] {
    return this.form.value['attachments'].filter(f => f.file).map(v => v.file);
  }

  private get attachments(): FormArray {
    return this.form.get('attachments') as FormArray;
  }

  buildForm() {
    this.form = this.fb.group({
      attachments: this.fb.array([]),
    });
  }

  watchFiles(newvalue, oldvalue?) {
    // if (!this.allowMultiple && newvalue.length > 1) {
    //   this.files = [newvalue[newvalue.length - 1]];
    // }
  }

  dragover() {
    this.isDragging = true;
  }

  dragleave() {
    this.isDragging = false;
  }

  dropfiles(e) {
    this.isDragging = false;
    this.addFiles(e.dataTransfer.files);
  }

  browseFiles() {
    this.fileInput.nativeElement.click();
  }

  onFileInput(e) {
    this.addFiles(this.fileInput.nativeElement.files);
  }

  buildFormFromFile(file: File) {
    return this.fb.group({
      attachedToEntity: [
        this.attachedToEntity ?? null,
        [Validators.required, Validators.maxLength(64)],
      ],
      attachedToId: [this.attachedToId ?? null, [Validators.required]],
      contentType: [file.type ?? 'application/octet-stream', [Validators.maxLength(128)]],
      expirationDate: ['' ?? null, [Validators.required]],
      file: [file, []],
      fileName: [file.name ?? null, [Validators.maxLength(256)]],
      fileUrl: [null, [Validators.maxLength(512)]],
      folder: [null, [Validators.maxLength(128)]],
      isActive: [true],
      ownerId: [this.ownerId ?? null, [Validators.required, Validators.maxLength(64)]],
      ownerType: [this.ownerType ?? null, [Validators.required]],
      title: [file.name, [Validators.required, Validators.maxLength(128)]],
    });
  }

  buildFormFromUrl(url: string) {
    if (!url) {
      return;
    }

    const [filename, extension] = this.extractFilenameAndExtension(url);
    const fullFileName = extension ? [filename, extension].join('.') : filename;

    return this.fb.group({
      title: [fullFileName, [Validators.maxLength(128)]],
      contentType: [this.getMimeTypeFromExtension(extension), [Validators.maxLength(128)]],
      file: [null, []],
      fileName: [fullFileName, [Validators.maxLength(256)]],
      fileUrl: [url ?? null, [Validators.maxLength(512)]],
      folder: [null, [Validators.maxLength(128)]],
      attachedToEntity: [
        this.attachedToEntity ?? null,
        [Validators.required, Validators.maxLength(64)],
      ],
      attachedToId: [this.attachedToId ?? null, [Validators.required]],
      ownerId: [this.ownerId ?? null, [Validators.required, Validators.maxLength(64)]],
      ownerType: [this.ownerType ?? null, [Validators.required]],
      isActive: [true],
    });
  }

  addFiles(inputFiles: any[]) {
    Array.from(inputFiles).forEach(file => {
      this.attachments.push(this.buildFormFromFile(file));
    });
  }

  onFileUrlChange(url: string) {
    this.attachments.clear();
    this.attachments.push(this.buildFormFromUrl(this.fileUrl));
  }

  removeFile(file) {
    const index = this.attachments.value.findIndex(attachment => attachment.file === file);

    if (index !== -1) {
      this.attachments.removeAt(index);
    } else {
      console.log('File not found in attachments');
    }
  }

  submitAttachments() {
    this.updateAttachments();
    this.submitted.emit(this.attachments.value);
  }

  private updateAttachments(): void {
    this.attachments.controls.forEach(control => {
      control.patchValue({
        title: this.groupName,
        expirationDate: this.expirationDate,
      });
    });
  }

  checkRestrictions(file) {
    let { maxFileSize, allowedFileTypes } = this.restrictions;

    let mimeType = file.type;
    let extension = '.' + file.name.split('.').pop();

    let isCorrectType = true;
    let validFileSize = true;

    if (allowedFileTypes.length) {
      isCorrectType = allowedFileTypes.some(type => {
        // is this is a mime-type
        if (type.includes('/')) {
          if (!file.type) return false;
          return file.type.match(type);
        }

        // otherwise this is likely an extension
        if (type[0] === '.') {
          return file.name.endsWith(type);
        }
        return false;
      });
    }

    if (maxFileSize && file.size != null) {
      validFileSize = file.size < maxFileSize;
    }

    if (!isCorrectType) {
      console.warn('File skipped because of invalid file type', file);
    }
    if (!validFileSize) {
      console.warn('File skipped because of invalid file size', file.size, file);
    }

    return isCorrectType && validFileSize;
  }

  get checkValidity(): boolean {
    return this.attachments.value.some(
      element => element.file.size > this.restrictions.maxFileSize
    );
  }

  urlToFile(url, filename, mimeType) {
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buffer => new File([buffer], filename, { type: mimeType }));
  }

  onExpirationDateSelect($event) {
    const selectedDate = new Date($event.year, $event.month - 1, $event.day);
    this.expirationDate = new Date(
      selectedDate.getTime() + Math.abs(selectedDate.getTimezoneOffset() * 60000)
    ).toISOString();
  }

  extractFilenameAndExtension(url) {
    // Regex pattern to match the last section of a URL's route
    const regex = /\/([^/?#]+)\.([a-zA-Z0-9]+)(?:\?|#|$)/;
    const match = url.match(regex);

    if (match && match.length >= 3) {
      const filename = match[1];
      const extension = match[2];
      return [filename, extension];
    } else {
      return [new Date().toLocalISOString()];
    }
  }

  getMimeTypeFromExtension(extension) {
    const mimeTypes = {
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      png: 'image/png',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      gif: 'image/gif',
    };

    const lowercasedExtension = extension?.toLowerCase();

    return mimeTypes[lowercasedExtension] || 'application/octet-stream';
  }
}
