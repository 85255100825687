import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { SiteComponent } from './components/site.component';
import { SiteRoutingModule } from './site-routing.module';
import { SharedModule } from '@/shared/shared.module';
import { SharedComponentsModule } from '@/components/shared-components.module';

@NgModule({
  declarations: [SiteComponent],
  imports: [
    CommercialUiModule,
    CoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    PageModule,
    SharedComponentsModule,
    SharedModule,
    SiteRoutingModule,
    ThemeSharedModule,
  ],
})
export class SiteModule {}
