import { Flatfile } from '@flatfile/api';
import { SpaceService } from '@flatfile/angular-sdk';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { environment } from 'src/environments/environment';
import { CustomListenerService } from './listener';

@Component({
  selector: 'app-flat-file',
  templateUrl: './flat-file.component.html',
  styleUrls: ['./flat-file.component.scss'],
})
export class FlatFileComponent implements OnInit, OnChanges {
  @Input() displayAsModal = false;
  @Input() workbook: Pick<Flatfile.CreateWorkbookConfig, 'name' | 'labels' | 'sheets' | 'actions'>;
  spaceProps: any;
  showSpace: boolean = false;
  @Output() jobCompleted = new EventEmitter();

  constructor(private spaceService: SpaceService, private listenerService: CustomListenerService) {}
  ngOnInit() {
    this.spaceProps = {
      displayAsModal: this.displayAsModal,
      environmentId: environment.flatfileEnvironmentId,
      publishableKey: environment.flatfilePublishableKey,
      listener: this.listenerService.listener,
      workbook: this.workbook,
      closeSpace: {
        onClose: this.closeSpace.bind(this),
      },

      spaceBody: {
        namespace: 'portal',
      },
    };
    this.listenerService.jobCompleted$.subscribe(() => {
      this.jobCompleted.emit();
    });
    this.listenerService.closeSpace$.subscribe(() => {
      this.closeSpace();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.displayAsModal || changes.workbook) {
      this.spaceProps = {
        ...this.spaceProps,
        displayAsModal: this.displayAsModal,
        workbook: this.workbook,
      };
    }
  }

  toggleSpace() {
    this.spaceService.OpenEmbed();
    this.showSpace = !this.showSpace;
  }

  closeSpace() {
    this.showSpace = false;
  }
}
