<abp-modal [(visible)]="openAllocateModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::AllocateAssets' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="mb-3">
      <abp-tree
        [checkStrictly]="true"
        [checkable]="true"
        [nodes]="nodes"
        [(checkedKeys)]="checkedKeys"
        [(expandedKeys)]="expandedKeys"
        [isNodeSelected]="functionForTree.selectFn"
      >
      </abp-tree>
    </div>
    <div class="flex gap-2" *ngIf="showMessage">
      <label class="text-black-base font-medium">
        {{ 'ServicingService::TotalSelected' | abpLocalization }}:
      </label>

      <label class="form-label"> {{ assets.length }} </label>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" (click)="allocate()" [disabled]="isBusy">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
