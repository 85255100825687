import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EMPTY, debounceTime, finalize, switchMap } from 'rxjs';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import {
  LocationTreeNode,
  mapLocationsTreeNode,
} from '@/locations/models/location-tree-node.model';
import { LocationDefinition, LocationService } from '@register/proxy/register-service/locations';
import { LocationDto } from '@proxy/register-service/locations';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class LocationPickerComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() readonly: boolean = true;
  @Input() displayName: string;
  @Input() type: string;
  @Input() locationDefinition: LocationDefinition;
  @Output() nodeSelected = new EventEmitter<LocationTreeNode>();

  selectedLocation?: LocationTreeNode = null;
  selectedLocationTemp?: LocationTreeNode = null;
  isModalOpen = false;
  isLoading = false;
  data: PagedResultDto<any> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup = new FormGroup({
    term: new FormControl(),
  });

  constructor(public readonly list: ListService, public readonly service: LocationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name.currentValue === null) {
      this.selectedLocation = null;
      this.selectedLocationTemp = null;
    }
  }

  ngOnInit(): void {
    this.form
      .get('term')
      .valueChanges.pipe(
        debounceTime(250),
        switchMap(id => {
          this.service
            .getLookup({
              type: this.displayName,
              maxResultCount: 100,
              filter: this.form.get('term').value,
              locationDefinition: this.locationDefinition,
              skipCount: 0,
            })
            .subscribe(data => {
              this.data = data;
            });
          return EMPTY;
        })
      )
      .subscribe(data => {});

    this.displayName = this.displayName?.charAt(0).toUpperCase() + this.displayName?.slice(1);
  }

  onSelectedNodeChange($event: LocationTreeNode) {
    this.selectedLocationTemp = $event;
  }

  onSelectClicked() {
    this.name = null;
    this.isModalOpen = true;

    const getData = (query: ABP.PageQueryParams) => {
      this.isLoading = true;
      return this.service
        .getLookup({
          ...query,
          filter: this.form.get('term').value,
          maxResultCount: 100,
        })
        .pipe(finalize(() => (this.isLoading = false)));
    };

    const setData = (list: PagedResultDto<LocationDto>) => {
      this.data = new PagedResultDto<LocationTreeNode>({
        totalCount: list.totalCount,
        items: mapLocationsTreeNode(list.items),
      });
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  onSelect() {
    this.selectedLocation = this.selectedLocationTemp;
    this.selectedLocationTemp = null;
    this.name = this.selectedLocation.name;
    this.isModalOpen = false;
    this.nodeSelected.emit(this.selectedLocation);
  }

  onCancel() {
    this.name = null;
    this.selectedLocationTemp = null;
    this.isModalOpen = false;
  }

  onRemove() {
    const selectedLocationDefinition =
      this.selectedLocation?.locationDefinition || this.locationDefinition;
    this.selectedLocation = null;
    this.selectedLocationTemp = null;
    this.name = null;
    this.nodeSelected.emit({
      id: null,
      parentId: null,
      fullName: null,
      isActive: null,
      notes: null,
      path: null,
      pictureUrl: null,
      locationDefinition: selectedLocationDefinition,
    });
  }
}
