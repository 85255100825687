import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from '@proxy/admin/admin.service';

@Component({
  selector: 'app-api-docs',
  templateUrl: './api-docs.component.html',
  styles: [],
})
export class ApiDocsComponent {
  docUrl = null;
  isModalOpen = false;

  constructor(private readonly service: AdminService, private sanitizer: DomSanitizer) {
    this.docUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.service.getApiDocsUrl());
  }

  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }
}
