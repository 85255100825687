<div class="card-body assets-datatable">
  <ngx-datatable
    [rows]="data.items"
    [count]="data.totalCount"
    [list]="list"
    [loadingIndicator]="isBusy"
    [selected]="selected"
    [selectionType]="hideCheckbox ? SelectionType.single : SelectionType.checkbox"
    [selectAllRowsOnPage]="true"
    (select)="onSelectRow($event)"
    (page)="onPageChange()"
    default
  >
    <ngx-datatable-column
      *ngIf="!hideCheckbox"
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template ngx-datatable-header-template let-value="value">
        <label class="datatable-checkbox">
          <input
            type="checkbox"
            [checked]="isAllRowsSelected()"
            (change)="selectAllRows($event.target.checked)"
          />
        </label>
      </ng-template>
      <ng-template ngx-datatable-cell-template let-row="row">
        <label class="datatable-checkbox">
          <input
            type="checkbox"
            [checked]="isSelected(row)"
            (change)="onSelectAssets($event, row)"
          />
        </label>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [width]="80" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="picture-preview">
          <img
            [src]="
              row.pictureUrl
                ? (service.getPictureUrl(row.id) | authImage | async)
                : 'assets/images/no-picture.jpg'
            "
          />
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [width]="20"
      *ngIf="!hideCheckbox"
      cellClass="!flex justify-center"
      [sortable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div
          *ngIf="row.parentId || row.isParent"
          class="flex flex-col items-center justify-center h-4 min-w-4 rounded-full overflow-hidden border-2 border-black"
        >
          <div class="w-full h-1/2" [ngClass]="row.isParent ? 'bg-black' : 'bg-white'"></div>
          <div class="w-full h-1/2 bg-black"></div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [width]="120"
      name="{{ 'RegisterService::PrimaryID' | abpLocalization }}"
      prop="primaryID"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="d-flex">
          <strong class="me-1">{{ service.getAssetTypeLetter(row) }}</strong>
          <p class="me-1">{{ ' | ' }}</p>
          <a [routerLink]="'/assets/view/' + row.id">{{ row.primaryID }}</a>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="window.innerWidth <= 480"
      name="{{ 'RegisterService::Category' | abpLocalization }}"
      prop="categoryName"
      [sortable]="false"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.categoryName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="window.innerWidth > 480"
      name="{{ 'RegisterService::SecondaryID' | abpLocalization }}"
      prop="secondaryID"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="'/assets/view/' + row.id">{{ row.secondaryID }}</a>
      </ng-template>
    </ngx-datatable-column>

    <!-- <ngx-datatable-column
            name="{{ 'RegisterService::Barcode' | abpLocalization }}"
            prop="barcode"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.barcode }}
            </ng-template>
          </ngx-datatable-column> -->

    <ngx-datatable-column
      name="{{ 'RegisterService::Nickname' | abpLocalization }}"
      prop="name"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.categoryNickname }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="window.innerWidth <= 480"
      name="{{ 'RegisterService::SecondaryID' | abpLocalization }}"
      prop="secondaryID"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a [routerLink]="'/assets/view/' + row.id">{{ row.secondaryID }}</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="window.innerWidth > 480"
      name="{{ 'RegisterService::Category' | abpLocalization }}"
      prop="categoryName"
      [width]="180"
      [sortable]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.categoryName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="homeLocation" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.homeLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="secondaryLocation" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.secondaryLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="jobsites" [width]="100" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.isDeallocated ? row.deallocationFullName : row.jobsiteNames.join(', ') }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.availability"
      name="{{ 'RegisterService::Availability' | abpLocalization }}"
      prop="isAvailable"
      [sortable]="false"
      [width]="100"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{
          row.isAvailable
            ? ('RegisterService::Available' | abpLocalization)
            : ('RegisterService::NotAvailable' | abpLocalization)
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.nextEvent"
      [sortable]="false"
      name="{{ 'QueueService::NextEvent' | abpLocalization }}"
      prop="nextInspectionOn"
      [width]="90"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.nextInspectionOn | date }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.compliance"
      name="{{ 'RegisterService::ComplianceRisk' | abpLocalization }}"
      prop="complianceStatus"
      [sortable]="false"
      [width]="125"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span
          [ngStyle]="{
            color:
              ComplianceStatus[row.complianceStatus] === ComplianceStatus.ATRISK
                ? 'var(--lpx-danger)'
                : ComplianceStatus[row.complianceStatus] === ComplianceStatus.COMPLIANT
                ? 'var(--bs-success)'
                : ''
          }"
        >
          {{ ComplianceStatus[row.complianceStatus] }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.lastSeen"
      [sortable]="false"
      name="{{ 'RegisterService::LastSeen' | abpLocalization }}"
      prop="lastSeen"
      [width]="90"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.lastSeen | date }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.rfid"
      name="{{ 'RegisterService::Rfid' | abpLocalization }}"
      prop="rfid"
      [sortable]="false"
      [width]="90"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.rfid }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.barcode"
      name="{{ 'RegisterService::Barcode' | abpLocalization }}"
      prop="barcode"
      [sortable]="false"
      [width]="90"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.barcode }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.manufacturerName"
      name="{{ 'RegisterService::Manufacturer' | abpLocalization }}"
      prop="manufacturerName"
      [sortable]="false"
      [width]="115"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.manufacturerName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.modelName"
      name="{{ 'RegisterService::Model' | abpLocalization }}"
      prop="modelName"
      [sortable]="false"
      [width]="110"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.modelName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.tracker"
      name="{{ 'RegisterService::Tracker' | abpLocalization }}"
      prop="tracker"
      [sortable]="false"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.tracker }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.secondaryTracker"
      name="{{ 'RegisterService::SecondaryTracker' | abpLocalization }}"
      prop="secondaryTracker"
      [sortable]="false"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.secondaryTracker }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.targetLocation"
      name="{{ 'RegisterService::TargetLocation' | abpLocalization }}"
      prop="targetLocationName"
      [sortable]="false"
      [width]="130"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.targetLocationName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.requestNumber"
      name="{{ 'RegisterService::RequestNumber' | abpLocalization }}"
      prop="requestNumber"
      [sortable]="false"
      [width]="130"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.requestNumber }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.runningCost"
      name="{{ 'RegisterService::RunningCost' | abpLocalization }}"
      prop="runningCost"
      [sortable]="false"
      [width]="130"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.runningCost }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.hasAttributes"
      name="{{ 'RegisterService::CustomAttributes' | abpLocalization }}"
      prop="hasAttributes"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <app-yes-no-display [value]="row.hasAttributes"></app-yes-no-display>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="assetColumns.costCentre"
      name="{{ 'RegisterService::CostCentre' | abpLocalization }}"
      prop="costCentreName"
      [sortable]="false"
      [width]="120"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.costCentreName }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'RegisterService::IsActive' | abpLocalization }}"
      prop="isActive"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <app-yes-no-display [value]="row.isActive"></app-yes-no-display>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [maxWidth]="175" [width]="175" [sortable]="false">
      <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
        <div class="d-flex me-3">
          <button type="button" class="btn btn-light btn-sm details-button" (click)="onView(row)">
            <i class="fa fa-info me-1" aria-hidden="true"></i>
            {{ 'AbpUi::Details' | abpLocalization }}
          </button>
          <div class="btn-group" role="group" container="body" ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="btn btn-light btn-sm dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              ngbDropdownToggle
            >
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div ngbDropdownMenu>
              <button
                type="button"
                ngbDropdownItem
                *abpPermission="'ServicingService.Events.Create'"
                (click)="onInspect(row)"
              >
                {{ 'RegisterService::Event' | abpLocalization }}
              </button>
              <button type="button" ngbDropdownItem (click)="onModalOpen('reserve', row)">
                {{ 'RegisterService::Reserve' | abpLocalization }}
              </button>
              <button
                *abpPermission="'RegisterService.Assets.Move'"
                type="button"
                ngbDropdownItem
                (click)="onModalOpen('move', row)"
              >
                {{ 'RegisterService::Move' | abpLocalization }}
              </button>
              <button type="button" ngbDropdownItem (click)="onModalOpen('checkout', row)">
                {{ 'RegisterService::Checkout' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                (click)="onModalOpen('allocate', row)"
                *ngIf="!row.isDeallocated"
              >
                {{ 'RegisterService::Allocate' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                (click)="onModalOpen('deallocate', row)"
                [disabled]="row.jobsiteIds.length === 0"
                *ngIf="row.assetType === assetType.OWNED && !row.isDeallocated"
              >
                {{ 'RegisterService::Deallocate' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                (click)="collectAsset(row)"
                *ngIf="row.isDeallocated"
              >
                {{ 'RegisterService::Collect' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                (click)="OffHireAsset(row)"
                *ngIf="row.assetType === assetType.HIRED"
              >
                {{ 'RegisterService::OffHire' | abpLocalization }}
              </button>
              <button
                *abpPermission="'RegisterService.Assets.ToggleActivity'"
                type="button"
                ngbDropdownItem
                (click)="onModalOpen('status', row)"
              >
                {{ 'RegisterService::ActiveStatus' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                *abpPermission="'RegisterService.Assets.Edit'"
                (click)="update(row)"
              >
                {{ 'AbpUi::Edit' | abpLocalization }}
              </button>
              <button
                type="button"
                ngbDropdownItem
                *abpPermission="'RegisterService.Assets.Delete'"
                (click)="delete(row)"
              >
                {{ 'AbpUi::Delete' | abpLocalization }}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<app-queue-modals
  *ngIf="isModalOpen"
  [type]="modalType"
  [asset]="selectedAsset"
  [isModalOpen]="isModalOpen"
  (modalClose)="onModalClose($event)"
></app-queue-modals>
