import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription, EMPTY, map, tap, Observable, of } from 'rxjs';
import {
  ICON_MAP,
  LpxNavbarItem,
  NavbarService,
  UserProfileService,
  UserProfile,
} from '@volo/ngx-lepton-x.core';
import { AppMobileNavbarService } from './mobile-navbar.service';
import { MobileMenuTranslate } from './mobile-menu-translate.enum';
import { ConfigStateService } from '@abp/ng.core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lpx-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [AppMobileNavbarService],
})
export class AppMobileNavbarComponent implements OnInit, OnDestroy {
  user$: any;

  menuVisible = false;

  navTabs!: LpxNavbarItem[];
  navTabs2!: LpxNavbarItem[];
  selectedMenuItems!: LpxNavbarItem[];
  groupedItems$: Observable<
    {
      group: string;
      items: LpxNavbarItem[];
    }[]
  >;

  navbarMenuItems!: LpxNavbarItem[];
  settingsMenuItems!: LpxNavbarItem[];
  profileMenuItems?: LpxNavbarItem[];
  user: UserProfile;
  isUserSignedIn: boolean;
  activeMenu = '';

  icons = ICON_MAP;

  sub = new Subscription();

  settingsTitle = MobileMenuTranslate.SettingsTitle;

  toggleClass = '';

  protected getGroupedItems() {
    return this.navbarService.groupedNavbarItems$.pipe(
      map(groupItems =>
        groupItems?.map(({ group, items }) => ({
          group,
          items: items.filter(item => !item.showOnMobileNavbar),
        }))
      )
    );
  }
  display = (data: any) => { };

  protected setNavTabs = (items: LpxNavbarItem[]) => {
    const flatItems = items.reduce((acc: LpxNavbarItem[], item: LpxNavbarItem) => {
      if (item.children.length) {
        return acc.concat([item], item.children);
      } else {
        return acc.concat(item);
      }
    }, []);
    this.navTabs = flatItems.filter(item => item.link === '/jobsites' || item.link === '/assets');
    this.navTabs2 = flatItems
      .filter(item => item.link === '/queue' || item.link === '/inspections/completed')
      .map(item => {
        item.showOnMobileNavbar = true;
        return item;
      });
    this.setNavbarMenuItems(items);
  };

  protected setNavbarMenuItems = (items: LpxNavbarItem[]) => {
    this.navbarMenuItems = items?.filter(item => !this.navTabs.includes(item));
  };

  protected setSettingsMenuItems = (items: LpxNavbarItem[]) => {
    this.settingsMenuItems = items;
  };

  protected setProfileActions = (items?: LpxNavbarItem[]) => {
    this.profileMenuItems = items;
  };

  constructor(
    private navbarService: NavbarService,
    private userProfileService: UserProfileService,
    private service: AppMobileNavbarService,
    private configState: ConfigStateService,
  ) {
    this.user$ = this.userProfileService.user$;
    this.groupedItems$ = this.getGroupedItems();
  }

  ngOnInit(): void {
    this.configState.getOne$('currentUser').subscribe(user => {
      this.isUserSignedIn = user.isAuthenticated;
    });
    this.sub.add(this.navbarService.navbarItems$.subscribe(this.setNavTabs));
    this.sub.add(this.service.settings$.subscribe(this.setSettingsMenuItems));
    this.sub.add(this.service.userProfileActions$.subscribe(this.setProfileActions));
    this.sub.add(this.user$.subscribe(user => (this.user = user)));
  }

  onSubnavbarExpand(menuItem: LpxNavbarItem): void {
    if (menuItem.expanded) {
      this.navbarMenuItems
        ?.filter(item => item !== menuItem)
        .forEach(item => (item.expanded = false));
    }
  }

  toggleMenu(type: string, menuItems?: LpxNavbarItem[]) {
    if (!type || !menuItems || !menuItems.length) {
      this.closeMenu();
      return;
    }

    this.toggleClass = '';
    if (type === this.activeMenu) {
      if (this.menuVisible) {
        this.closeMenu();
      }
    } else {
      this.menuVisible = true;
      this.activeMenu = type;
      this.groupedItems$ = type === 'navbar' ? this.getGroupedItems() : EMPTY;
      menuItems = menuItems
        .filter(item => item.text !== 'Queue')
        .map(item => {
          if (item.children && Array.isArray(item.children)) {
            item.children = item.children.filter(child => child.text !== 'Completed Events');
          }
          return item;
        });
      this.selectedMenuItems = menuItems;
    }
  }

  toggleNavbarMenu() {
    // this.toggleMenu('navbar', this.navbarMenuItems);
    this.toggleMenu(
      'navbar',
      this.isUserSignedIn ? this.navbarMenuItems.concat([
        {
          children: this.profileMenuItems,
          text: this.user.userName,
          expanded: false,
          icon: 'fa fa-user',
        },
      ]) : this.navbarMenuItems
    );
    this.toggleClass = this.menuVisible ? 'lpx-mobile-menu-toggle-open' : '';
  }
  closeMenu() {
    this.toggleClass = '';
    this.activeMenu = '';
    this.menuVisible = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
