import { avatar } from '@/shared/constants';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
  EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild
} from '@angular/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePickerComponent implements OnChanges {
  @Input() avatar = avatar;
  @Input() loader: Function;
  @Input() initialSrc: string;

  @Output() imageChanged = new EventEmitter<File>();
  @Output() imageRemoved = new EventEmitter<void>();

  @ViewChild('fileInput') el: ElementRef;

  isLoading: boolean = false;
  imageUrl: string = this.avatar;
  editFile: boolean = true;
  removeImage: boolean = false;

  constructor(
    private readonly cd: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialSrc) {
      this.editFile = true;
      this.removeImage = false;
      this.loadInitialImage(changes.initialSrc.currentValue);
    }
  }

  loadInitialImage(imageUrl?: string) {
    if (!imageUrl) {
      this.imageUrl = this.avatar;
    } else if (imageUrl.toLowerCase().startsWith('http://')) {
      this.imageUrl = imageUrl;
    } else {
      this.loader(imageUrl).subscribe((res) => this.loadImageFromBlob(res));
    }
  }
  
  onChangeFile(event) {
    this.loadImageFromFile(event.target.files[0]);
  }

  private loadImageFromFile(file) {
    const reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result as string;
      this.editFile = false;
      this.removeImage = true;
      this.cd.markForCheck();
      this.imageChanged.emit(file);
    }
    reader.readAsDataURL(file);
  }

  private loadImageFromBlob(blob) {
    if (blob) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result as string;
        this.editFile = false;
        this.removeImage = true;
        this.cd.markForCheck();
      }
      reader.readAsDataURL(blob);
    }
  }

  onRemoveImage() {
    this.clearImage();
    this.imageRemoved.emit();
  }

  private clearImage() {
    this.imageUrl = this.avatar;
    this.editFile = true;
    this.removeImage = false;
    this.cd.markForCheck();
  }

  onDropFile(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.loadImageFromFile(event.dataTransfer.files[0]);
  }

  allowDropFile(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }
}
