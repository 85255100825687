import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { QueueItemCreateDto, QueueItemDto, GetQueueItemsInput } from './models';

@Injectable({
  providedIn: 'root',
})
export class QueueService {
  apiName = 'QueueService';
  private urlPrefix = '/api/queue';

  enqueue = (input: QueueItemCreateDto[]) =>
    this.restService.request<any, { size: number; failedIds?: string[] }>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/`,
        body: input,
      },
      { apiName: this.apiName }
    );

  dequeue = (id: string) =>
    this.restService.request<any, { size: number; failedIds?: string[] }>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  clear = (input: string[]) =>
    this.restService.request<any, { size: number }>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/`,
        body: input,
      },
      { apiName: this.apiName }
    );
  size = () =>
    this.restService.request<any, { size: number }>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/size`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, QueueItemDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getSelectedIds = (input: GetQueueItemsInput) =>
    this.restService.request<any, string[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/select-ids`,
        params: {
          term: input.term,
          assetId: input.assetId,
          assetCode: input.assetCode,
          assetPrimaryID: input.assetPrimaryID,
          assetSecondaryID: input.assetSecondaryID,
          assetBarcode: input.assetBarcode,
          assetDescription: input.assetDescription,
          siteName: input.siteName,
          categoryId: input.categoryId,
          categoryNickname: input.categoryNickname,
          categoryPath: input.categoryPath,
          homeLocationId: input.homeLocationId,
          locationGroupIds: input.locationGroupIds,
          secondaryLocationId: input.secondaryLocationId,
          jobsiteLocationId: input.jobsiteLocationId,
          lastInspectiondOnMin: input.lastInspectiondOnMin,
          lastInspectiondOnMax: input.lastInspectiondOnMax,
          isActive: input.isActive,
          statusName: input.statusName,
          nextInspectionOnMin: input.nextInspectionOnMin,
          nextInspectionOnMax: input.nextInspectionOnMax,
          inspectionTypeName: input.inspectionTypeName,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  getList = (input: GetQueueItemsInput) =>
    this.restService.request<any, PagedResultDto<QueueItemDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/`,
        params: {
          term: input.term,
          assetId: input.assetId,
          assetCode: input.assetCode,
          assetPrimaryID: input.assetPrimaryID,
          assetSecondaryID: input.assetSecondaryID,
          assetBarcode: input.assetBarcode,
          assetDescription: input.assetDescription,
          siteName: input.siteName,
          categoryId: input.categoryId,
          categoryNickname: input.categoryNickname,
          categoryPath: input.categoryPath,
          homeLocationId: input.homeLocationId,
          secondaryLocationId: input.secondaryLocationId,
          jobsiteLocationId: input.jobsiteLocationId,
          lastInspectiondOnMin: input.lastInspectiondOnMin,
          lastInspectiondOnMax: input.lastInspectiondOnMax,
          isActive: input.isActive,
          statusName: input.statusName,
          nextInspectionOnMin: input.nextInspectionOnMin,
          nextInspectionOnMax: input.nextInspectionOnMax,
          inspectionTypeName: input.inspectionTypeName,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
