<abp-page-toolbar-container class="col"> </abp-page-toolbar-container>

<div class="row page-container">
  <div class="col-6">
    <div class="card d-block d-md-none" *ngIf="pendingInspections.items.length">
      <div class="card-body">
        <div class="mb-3">
          <h3 class="card-title">{{ 'ServicingService::CurrentEvents' | abpLocalization }}</h3>
          <div *ngFor="let ins of pendingInspections.items; let i = index; trackBy: track.by('id')">
            <div class="d-flex justify-content-between pb-3">
              <label class="form-check-label" for="inspection-type-{{ i }}">
                {{ ins.inspectionTypeName }}
              </label>
              <label class="card-title">
                {{ ins.eventTypeDefinition === 'JOBSITE' ? 'Jobsite' : 'Other' }} Event
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div *ngIf="parentAsset" class="pb-3">
          <app-assets-parent-info [parent]="parentAsset" />
          <hr />
        </div>
        <div class="d-flex justify-content-between">
          <h3 class="card-title mb-3">
            {{ 'RegisterService::Asset:Summary' | abpLocalization }}
          </h3>
          <div *ngIf="!isPageTemporary && !asset?.parentId">
            <button
              *abpPermission="'RegisterService.Assets.Create'"
              class="btn btn-primary btn-sm"
              type="button"
              (click)="create()"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{ 'RegisterService::NewSubAsset' | abpLocalization }}
            </button>
          </div>
        </div>

        <div class="mt-2 fade-in-top">
          <div class="mb-3 picture-upload">
            <div class="picture-preview">
              <img
                [src]="
                  service.getPictureUrl(asset.id, isPageTemporary, tenantPassword)
                    | authImage : 'assets/images/no-picture.jpg'
                    | async
                "
              />
            </div>
          </div>
          <div class="mb-3">
            <app-category-picker [name]="asset.categoryFullName" [readonly]="true" />
          </div>
          <div class="card-body" *ngIf="asset.assetType != AssetTypes.OWNED">
            <h3 class="card-title mb-3">
              {{ 'RegisterService::AssetNextHire' | abpLocalization }}
            </h3>
            <div class="next-hire-wrapper mb-3">
              <div class="type-header-wrapper d-flex align-items-center gap-3">
                <label class="form-label">{{
                  'RegisterService::AssetType' | abpLocalization
                }}</label>
                <label>{{
                  asset.assetType
                    ? ('RegisterService::Hired' | abpLocalization)
                    : ('RegisterService::Owned' | abpLocalization)
                }}</label>
              </div>
            </div>
            <div
              class="mb-3 d-flex align-items-center gap-3"
              *ngIf="asset.assetType == AssetTypes.HIRED"
            >
              <label class="form-label" for="asset-hire-date">
                {{ 'RegisterService::DateHired' | abpLocalization }}
              </label>
              <label for="form-label">{{
                asset.dateHired | date : getShortDateFormat(configState)
              }}</label>
            </div>
            <div
              class="mb-3 d-flex align-items-center gap-3"
              *ngIf="asset.assetType == AssetTypes.HIRED"
            >
              <label class="form-label">
                {{ 'RegisterService::HirePeriod' | abpLocalization }}
              </label>
              <label>{{ asset.hireType }}</label>
            </div>
            <div
              class="mb-3 d-flex align-items-center gap-3"
              *ngIf="asset.assetType == AssetTypes.HIRED"
            >
              <label class="form-label" for="next-hire-date">
                {{ 'RegisterService::NextHirePeriod' | abpLocalization }}
              </label>
              <label for="form-label">{{
                asset.nextHireDate | date : getShortDateFormat(configState)
              }}</label>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label" for="targetLocation">
              {{ 'ServicingService::EventLocation' | abpLocalization }}
            </label>
            <input
              id="targetLocation"
              [value]="asset.targetLocationName"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3" *ngIf="asset.categoryNickname">
            <label class="form-label" for="asset-category-nickname">
              {{ 'RegisterService::Nickname' | abpLocalization }}
            </label>
            <input
              id="asset-category-nickname"
              [value]="asset.categoryNickname ?? 'None'"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="asset-code">
              {{ 'RegisterService::SitePlantCode' | abpLocalization }}
            </label>
            <input
              id="asset-code"
              [value]="asset.code"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="asset-primary-i-d">
              {{ 'RegisterService::PrimaryID' | abpLocalization }}
            </label>
            <input
              id="asset-primary-i-d"
              [value]="asset.primaryID"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="asset-secondary-i-d">
              {{ 'RegisterService::SecondaryID' | abpLocalization }}
            </label>
            <input
              id="asset-secondary-i-d"
              [value]="asset.secondaryID"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3" *ngIf="asset.orderNumber">
            <label class="form-label" for="asset-order-number-id">
              {{ 'RegisterService::OrderNumber' | abpLocalization }}
            </label>
            <input
              id="asset-order-number-id"
              [value]="asset.orderNumber"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column">
              <label class="form-label">
                {{ 'RegisterService::AssetQRCode' | abpLocalization }}
              </label>
              <img
                [width]="150"
                [height]="150"
                [src]="qrImageSrc"
                alt="QR Code"
                *ngIf="qrImageSrc"
              />
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label" for="asset-tracker-primary">
              {{ 'RegisterService::Tracker' | abpLocalization }}
            </label>
            <input
              placeholder="Trackers List"
              id="asset-tracker-primary"
              [value]="asset.deviceNames.join(', ') ?? ''"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="asset-standart-compliance">
              {{ 'RegisterService::StandardCompliance' | abpLocalization }}
            </label>
            <input
              id="asset-standart-compliance"
              [value]="ComplianceStatus[asset.standartComplianceStatus] || '-'"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="asset-jobsite-compliance">
              {{ 'RegisterService::JobsiteCompliance' | abpLocalization }}
            </label>
            <input
              id="asset-jobsite-compliance"
              [value]="ComplianceStatus[asset.jobsiteComplianceStatus] || '-'"
              class="form-control"
              maxlength="64"
              readonly
            />
          </div>

          <div class="mb-3">
            <label class="form-label" for="asset-tracker-secondary">
              {{ 'RegisterService::SecondaryTracker' | abpLocalization }}
            </label>
            <input
              id="asset-tracker-secondary"
              [value]="''"
              class="form-control"
              maxlength="64"
              readonly
              disabled
            />
          </div>

          <div class="mb-3">
            <label class="form-label" for="asset-notes">
              {{ 'RegisterService::Notes' | abpLocalization }}
            </label>
            <textarea
              id="asset-notes"
              [value]="asset.notes"
              rows="3"
              class="form-control"
              maxlength="5120"
              readonly
            ></textarea>
          </div>

          <div class="mb-3">
            <label class="form-label" for="asset-components">
              {{ 'RegisterService::Components' | abpLocalization }}
            </label>
            <textarea
              id="asset-components"
              [value]="asset.components"
              rows="3"
              class="form-control"
              maxlength="5120"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="inspections.items.length">
      <div class="card-body">
        <div class="mb-3">
          <ngx-datatable
            [rows]="inspections.items"
            [count]="inspections.totalCount"
            [list]="list"
            default
          >
            <ngx-datatable-column [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.inspectionTypeName }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'ServicingService::Date' | abpLocalization }}"
              prop="doneOn"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.doneOn | date }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="false"
              name="{{ 'ServicingService::State' | abpLocalization }}"
              prop="state"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ 'ServicingService::' + row.state | abpLocalization }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="false"
              name="{{ 'ServicingService::ReportNumber' | abpLocalization }}"
              prop="siteName"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.reportNumber }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'ServicingService::DueOn' | abpLocalization }}"
              prop="dueOn"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.dueOn | date }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [sortable]="false">
              <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
                <button type="button" class="btn btn-light btn-sm" (click)="onView(row)">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card d-none d-md-block" *ngIf="pendingInspections.items.length">
      <div class="card-body">
        <h3 class="card-title">
          {{ 'ServicingService::CurrentEvents' | abpLocalization }}
        </h3>
        <div class="mt-2">
          <ngx-datatable
            [rows]="pendingInspections.items"
            [count]="pendingInspections.totalCount"
            [list]="list"
            default
          >
            <ngx-datatable-column
              [sortable]="false"
              name="{{ 'ServicingService::EventType' | abpLocalization }}"
              prop="inspectionTypeName"
              [width]="100"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.inspectionTypeName }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'ServicingService::Date' | abpLocalization }}"
              prop="doneOn"
              [width]="100"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.doneOn | date }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'ServicingService::DueOn' | abpLocalization }}"
              prop="dueOn"
              [width]="100"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.kmLimit || row.hourLimit ? '-' : (row.dueOn | date) }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="
                ('ServicingService::DueOn' | abpLocalization) +
                ' : ' +
                ('ServicingService::Km' | abpLocalization)
              "
              prop="dueOn"
              [sortable]="false"
              [width]="100"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.kmLimit || '-' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="
                ('ServicingService::DueOn' | abpLocalization) +
                ' : ' +
                ('RegisterService::Hours' | abpLocalization)
              "
              prop="dueOn"
              [sortable]="false"
              [width]="100"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.hourLimit || '-' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="false"
              name="{{ 'ServicingService::Status' | abpLocalization }}"
              prop="inspectionStatusName"
              [width]="100"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.inspectionStatusName }}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h3 class="card-title mb-3">
          {{ 'RegisterService::Asset:Ownership' | abpLocalization }}
        </h3>
        <div class="mb-3">
          <label class="form-label" for="asset-home-location-id">
            {{ 'RegisterService::HomeLocation' | abpLocalization }}
          </label>
          <input
            id="asset-home-location-id"
            [value]="asset.homeLocationName"
            class="form-control"
            maxlength="64"
            readOnly
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="asset-secondary-location-id">
            {{ 'RegisterService::SecondaryLocation' | abpLocalization }}
          </label>
          <input
            id="asset-secondary-location-id"
            [value]="asset.secondaryLocationName"
            class="form-control"
            maxlength="64"
            readOnly
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="asset-jobsite-id">
            {{ 'RegisterService::Jobsite' | abpLocalization }}
          </label>
          <input
            id="asset-jobsite-id"
            class="form-control"
            maxlength="64"
            readonly
            [value]="asset.jobsiteNames.join(', ')"
          />
        </div>
      </div>
    </div>

    <div class="card">
      <h3 class="card-title mt-3 ms-3">
        {{ 'RegisterService::AssetAttributes' | abpLocalization }}
      </h3>
      <div class="card-body">
        <div class="mb-3" *ngIf="asset.hours">
          <label class="form-label" for="asset-hours">
            {{ 'RegisterService::Hours' | abpLocalization }}
          </label>
          <input id="asset-hours" [value]="asset.hours" class="form-control" readonly />
        </div>
        <div class="mb-3" *ngIf="asset.kilometers">
          <label class="form-label" for="asset-kilometers">
            {{ 'RegisterService::KM' | abpLocalization }}
          </label>
          <input id="asset-kilometers" [value]="asset.kilometers" class="form-control" readonly />
        </div>
        <div class="mb-3">
          <label class="form-label" for="asset-barcode">
            {{ 'RegisterService::Barcode' | abpLocalization }}
          </label>
          <input
            id="asset-barcode"
            [value]="asset.barcode"
            class="form-control"
            maxlength="64"
            readonly
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="asset-rfid">
            {{ 'RegisterService::Rfid' | abpLocalization }}
          </label>
          <input
            id="asset-rfid"
            [value]="asset.rfid"
            class="form-control"
            maxlength="64"
            readonly
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="asset-manufacturer-id">
            {{ 'RegisterService::Manufacturer' | abpLocalization }}
          </label>
          <input
            id="asset-manufacturer-id"
            [value]="asset.manufacturerName"
            class="form-control"
            maxlength="64"
            readOnly
          />
        </div>
        <div class="mb-3">
          <label class="form-label" for="asset-model-id">
            {{ 'RegisterService::Model' | abpLocalization }}
          </label>
          <input
            id="asset-model-id"
            [value]="asset.modelName"
            class="form-control"
            maxlength="64"
            readOnly
          />
        </div>

        <div class="mb-3" *ngIf="consumptions?.length">
          <ngb-accordion #accAddress="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <div class="d-flex w-100 justify-content-between me-2">
                  <label>{{ 'RegisterService::Consumed' | abpLocalization }}</label>
                  <label>{{ getTotalCount() | number : '1.2-2' | currency : 'EUR' }}</label>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <ngx-datatable [rows]="consumptions" default>
                  <ngx-datatable-column
                    [sortable]="false"
                    name="{{ 'RegisterService::Category' | abpLocalization }}"
                    [width]="120"
                  >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span class="table-column-text">
                        {{ row.consumableName }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'RegisterService::Quantity' | abpLocalization }}"
                    [sortable]="false"
                    [width]="80"
                  >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.count }}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    [sortable]="false"
                    name="{{ 'ServicingService::Permission:Inspections' | abpLocalization }}"
                    [width]="100"
                  >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span class="table-column-text">
                        {{ row.event }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'RegisterService::Date' | abpLocalization }}"
                    [width]="110"
                  >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.date | date }}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    [sortable]="false"
                    name="{{ 'ServicingService::Cost' | abpLocalization }}"
                    [width]="70"
                  >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row.cost * row.count | number : '1.2-2' }}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    [sortable]="false"
                    [width]="100"
                    name="{{ 'ServicingService::Operator' | abpLocalization }}"
                  >
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <span class="table-column-text">
                        {{ row.operatorName }}
                      </span>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <h3 *ngIf="attributes.length" class="card-title mb-3">
          {{ 'RegisterService::CustomAttributes' | abpLocalization }}
        </h3>
        <div>
          <div
            *ngFor="let attribute of attributes; let i = index; trackBy: track.by('name')"
            class="mb-3"
          >
            <label class="form-label" for="asset-attributes-{{ i }}">{{ attribute.name }}</label>
            <input id="asset-attributes-{{ i }}" class="form-control" [value]="attribute.value" />
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="asset.runningCost">
      <div class="card-body">
        <h3 class="card-title mb-3">
          {{ 'RegisterService::Financial' | abpLocalization }}
        </h3>
        <div class="mb-3">
          <label class="form-label" for="asset-running-cost">
            {{ 'RegisterService::RunningCost' | abpLocalization }}
          </label>
          <input
            id="asset-running-cost"
            [value]="asset.runningCost"
            class="form-control"
            maxlength="64"
            readOnly
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <label class="card-title mb-3">{{ 'RegisterService::Meters' | abpLocalization }}</label>
        <div>
          <div *ngFor="let meter of meters; let i = index; trackBy: track.by('name')" class="mb-3">
            <label class="form-label" for="asset-meters-{{ i }}">
              {{ meter.name }}
            </label>
            <input id="asset-meters-{{ i }}" class="form-control" [value]="meter.startReading" />
          </div>
        </div>
        <div class="form-check mb-3">
          <input
            type="checkbox"
            id="asset-is-active"
            [checked]="asset.isActive"
            class="form-check-input"
            (click)="$event.preventDefault()"
            readonly
          />
          <label for="asset-is-active" class="form-check-label">
            {{ 'RegisterService::IsActive' | abpLocalization }}
          </label>
        </div>
        <div class="d-flex gap-3 mb-3 align-items-center" *ngIf="costCentreName">
          <div class="d-flex align-items-center gap-1">
            <label class="form-label availability-label">
              {{ 'RegisterService::CostCentre' | abpLocalization }}
            </label>
            <label
              ><p class="availability-text">{{ costCentreName }}</p></label
            >
          </div>
          <div class="form-check col-1">
            <input
              type="checkbox"
              id="asset-is-known-as"
              (change)="changeStatus($event.target.checked)"
              [checked]="isCostCentreFixed"
              class="form-check-input"
            />
            <label for="asset-is-known-as" class="form-check-label">
              {{ 'RegisterService::IsFixed' | abpLocalization }}
            </label>
          </div>
        </div>

        <div class="mb-3 d-flex align-items-center gap-1">
          <label class="form-label availability-label">
            {{ 'RegisterService::Availability:' | abpLocalization }}
          </label>
          <label
            ><p class="availability-text">
              {{
                asset.isAvailable
                  ? ('RegisterService::Available' | abpLocalization)
                  : ('RegisterService::NotAvailable' | abpLocalization)
              }}
            </p></label
          >
        </div>
      </div>
    </div>
  </div>
</div>
