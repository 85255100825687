import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AttachmentDto } from '@proxy/attachments';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @ViewChild('accordionButton', { static: true }) accordionButton: ElementRef;
  @ViewChild('accordionCollapse', { static: true }) accordionCollapse: ElementRef;
  @Input() allowDelete: boolean = true;
  @Input() data;

  @Output() getDocument: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.data.forEach(element => {
      if (element.expirationDate) {
        element.expirationDate = new Date(element.expirationDate).toISOString().split('T')[0];
      }
    });
  }

  toggleAccordion() {
    const button = this.accordionButton.nativeElement;
    const collapse = this.accordionCollapse.nativeElement;

    const isExpanded = button.getAttribute('aria-expanded') === 'true';

    if (isExpanded) {
      this.renderer.removeClass(collapse, 'show');
      this.renderer.addClass(button, 'collapsed');
      this.renderer.setAttribute(button, 'aria-expanded', 'false');
    } else {
      this.renderer.addClass(collapse, 'show');
      this.renderer.removeClass(button, 'collapsed');
      this.renderer.setAttribute(button, 'aria-expanded', 'true');
    }
  }

  onDelete(doc: AttachmentDto) {
    this.delete.emit(doc);
  }

  isHttpLink(doc: AttachmentDto) {
    return doc.fileUrl && doc.fileUrl.startsWith('http');
  }

  onGetDocument(item) {
    this.getDocument.emit(item);
  }
}
