export enum RequestPeriodRange {
  WEEKLY = 'WEEKLY',
  TWO_WEEKS = 'TWO_WEEKS',
  MONTHLY = 'MONTHLY',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
}

export enum RequestNotifyTypes {
  OnArrival = 'OnArrival',
  InTransit = 'InTransit',
  None = 'None',
}

export enum RequestStatus {
  OPEN = 'Open',
  ACTIONED = 'Actioned',
  CLOSED = 'Closed',
}

export enum RequestActionType {
  Matched = 'Matched',
  HireRequired = 'HireRequired',
  Refused = 'Refused',
  Open = 'Open',
}
