import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const INSPECTION_TYPES_INSPECTION_TYPE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/inspection-types',
        iconClass: 'fas fa-tools',
        name: 'ServicingService::Menu:EventTypes',
        parentName: 'ServicingService::Menu:ServicingService',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.InspectionTypes.Page',
        order: 50
      },
    ]);
  };
}
