export const avatar = 'assets/images/no-picture.jpg';

export const locationTypesForConsumables = [
  'Main Storage',
  'FieldStorage/Container (In Use)',
  'FieldStorage/Container (Not In Use)',
  'Loft Store',
  'A Vehicle',
  'Jobsite Storage',
];

export const consumableLocationTypes = [
  'A Person',
  'A Infoganic User',
  'Third Party',
  'A Vehicle',
  'Jobsite',
];

export const notConsumableLocationTypes = ['Quarantine', 'Jobsite AC'];

export const assetColumns: { [x: string]: boolean } = {
  availability: true,
  nextEvent: true, //dueOn
  compliance: true,
  lastSeen: true, //doneOn
  rfid: false,
  manufacturerName: false,
  modelName: false,
  tracker: false,
  secondaryTracker: false,
  requestNumber: false,
  runningCost: false,
  hasAttributes: false,
};

export const eventColumns: { [x: string]: boolean } = {
  eventType: true,
  doneOn: true,
  dueOn: false,
  status: true,
  reportNumber: false,
  notes: false,
  consumables: false,
  eventBy: false,
  // defectQuestions: false,
  runningCost: false,
};

export const columnTranslationKey = {
  availability: 'RegisterService::Availability',
  nextEvent: 'QueueService::NextEvent',
  compliance: 'RegisterService::Compliance',
  lastSeen: 'RegisterService::LastSeen',
  rfid: 'RegisterService::Rfid',
  manufacturerName: 'RegisterService::Manufacturer',
  modelName: 'RegisterService::Model',
  tracker: 'RegisterService::Tracker',
  secondaryTracker: 'RegisterService::SecondaryTracker',
  requestNumber: 'RegisterService::RequestNumber',
  runningCost: 'RegisterService::RunningCost',
  hasAttributes: 'RegisterService::CustomAttributes',

  eventType: 'ServicingService::EventType',
  doneOn: 'ServicingService::DoneOn',
  dueOn: 'ServicingService::DueOn',
  status: 'ServicingService::Status',
  reportNumber: 'ServicingService::ReportNumber',
  notes: 'ServicingService::Notes',
  consumables: 'RegisterService::Menu:Consumables',
  eventBy: 'ServicingService::Examiner',
  defectQuestions: 'ServicingService::IfFailure',
};
