import {
  ABP,
  ConfigStateService,
  ListService,
  PagedResultDto,
  TrackByService,
  getShortDateFormat,
} from '@abp/ng.core';
import { mergeMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AssetDetailsDto, AssetService, AssetType } from '@proxy/register-service/assets';
import { AttributeDefinitionGroupDto } from '@proxy/register-service/attribute-definitions';
import { LocationService } from '@proxy/register-service/locations';
import { ManufacturerService } from '@proxy/register-service/manufacturers';
import { ModelService } from '@proxy/register-service/models';
import { SiteService } from '@proxy/register-service/sites';
import { AttributeDefinitionDto } from '@proxy/register-service/attribute-definitions/attributes/models';
import { MeterDefinitionDto } from '@proxy/register-service/attribute-definitions/meters/models';
import {
  InspectionDetailsDto,
  GetInspectionsInput,
  InspectionState,
} from '@proxy/servicing-service/inspections';
import { IEventConsumable } from '@proxy/register-service/consumables';
import { InspectionService } from '@proxy/servicing-service/inspections/inspection.service';
import { ComplianceStatus } from '@proxy/register-service/assets';

@Component({
  selector: 'app-asset-infos',
  providers: [ListService],
  templateUrl: './asset-infos.component.html',
  styleUrls: ['./asset-infos.component.scss'],
})
export class AssetInfosComponent implements OnInit, OnChanges {
  @Input() asset: AssetDetailsDto;
  @Input() isPageTemporary: boolean = false;
  @Input() tenantPassword: string = '';

  @ViewChild('fileInput') el: ElementRef;

  getShortDateFormat = getShortDateFormat;
  ComplianceStatus = ComplianceStatus;
  isModalBusy = false;
  templateGroup: AttributeDefinitionGroupDto;
  inspections: PagedResultDto<InspectionDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  pendingInspections: PagedResultDto<InspectionDetailsDto> = {
    items: [],
    totalCount: 0,
  };
  costCentreName = '';
  isCostCentreFixed = false;
  parentAsset: AssetDetailsDto;
  consumptions: IEventConsumable[] = [];
  filters = {} as GetInspectionsInput;
  AssetTypes = AssetType;
  removePicture: boolean = false;
  qrImageSrc: string | ArrayBuffer;

  constructor(
    public readonly configState: ConfigStateService,
    public readonly router: Router,
    public readonly list: ListService,
    public readonly service: AssetService,
    public readonly manufacturerService: ManufacturerService,
    public readonly modelService: ModelService,
    public readonly siteService: SiteService,
    public readonly locationService: LocationService,
    public readonly toasterService: ToasterService,
    public readonly track: TrackByService,
    private readonly inspectionService: InspectionService
  ) {}

  ngOnInit() {
    this.parentAsset = this.asset.parent ? { ...this.asset.parent, id: this.asset.parentId } : null;

    this.filters.state = InspectionState.Draft;
    const getData = (query: ABP.PageQueryParams) =>
      this.inspectionService.getList(
        {
          ...query,
          ...this.filters,
          isGroup: true,
          assetPrimaryID: this.asset.primaryID,
          assetId: this.asset.id,
          maxResultCount: 50,
          term: query.filter,
        },
        this.isPageTemporary
      );

    this.loadImage();
    this.getConsumables();
    this.getCostCentre();
    const setData = (list: PagedResultDto<InspectionDetailsDto>) => {
      this.inspections = list;
    };

    this.isCostCentreFixed = !!this.asset.isFixed;
    const getPendingInspectionsData$ = () =>
      this.inspectionService
        .getList({
          ...this.filters,
          assetId: this.asset.id,
          isHistorical: false,
          maxResultCount: 100,
          state: InspectionState.Approved,
        })
        .pipe(tap(d => (this.pendingInspections = d)));

    this.list
      .hookToQuery(getData)
      .pipe(
        tap(setData),
        mergeMap(() => getPendingInspectionsData$())
      )
      .subscribe();
  }

  get attributes() {
    return this.asset.attributes;
  }

  get meters() {
    return this.asset.meters;
  }

  get attributeDefinitions(): AttributeDefinitionDto<any>[] {
    return this.templateGroup.attributes;
  }

  get meterDefinitions(): MeterDefinitionDto<any>[] {
    return this.templateGroup.meters;
  }

  get siteId() {
    return this.asset.siteId;
  }

  ngOnChanges({ asset }: SimpleChanges) {
    if (asset?.currentValue && !asset.firstChange) {
      this.parentAsset = this.asset.parent
        ? { ...this.asset.parent, id: this.asset.parentId }
        : null;
      this.getCostCentre();
    }
  }

  create() {
    this.router.navigate(['/assets/create'], { queryParams: { assetId: this.asset.id } });
  }

  changeStatus(val: boolean) {
    if (!this.asset) return;

    const request = this.service.updateStatus(this.asset.id);
    this.isModalBusy = true;

    request.subscribe({
      next: () => {
        this.isCostCentreFixed = val;

        if (!val) this.getCostCentre();
        this.toasterService.success('ServicingService::SuccessfullySaved');
        return;
      },
      error: error => {
        this.service.handleErrorResponse(error);
      },
    });
  }

  getTotalCount() {
    return this.consumptions
      ? this.consumptions.reduce((sum, item) => sum + item.count * item.cost, 0)
      : 0;
  }

  getCostCentre(): void {
    this.service.getCostCenter(this.asset.id).subscribe({
      next: data => {
        this.costCentreName = data?.name || '';
      },
    });
  }

  getConsumables(): void {
    this.service.getConsumptions(this.asset.id).subscribe({
      next: data => {
        this.consumptions = data;
      },
    });
  }

  loadImage(): void {
    if (this.asset.qrImageUrl) {
      this.service.getQRPictureUrl(this.asset.id).subscribe((response: Blob) => {
        const reader = new FileReader();
        reader.onload = () => (this.qrImageSrc = reader.result);
        reader.readAsDataURL(response);
      });
    }
  }

  onView(record: InspectionDetailsDto) {
    this.router.navigate(['/inspections/details'], {
      queryParams: { id: record.id, assetId: record.assetId, typeId: record.typeId },
    });
  }
}
