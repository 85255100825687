import { EnvironmentService, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  StaffCreateDto,
  StaffDto,
  StaffUpdateDto,
  StaffDetailsDto,
  GetStaffsInput,
} from './models';
import type { LookupDto, LookupRequestDto } from '@proxy/shared/models';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  apiName = 'ServicingService';
  urlPrefix = '/api/servicing/staffs';

  create = (input: StaffCreateDto) =>
    this.restService.request<any, StaffDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, StaffDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetStaffsInput) =>
    this.restService.request<any, PagedResultDto<StaffDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          firstName: input.firstName,
          lastName: input.lastName,
          role: input.role,
          code: input.code,
          email: input.email,
          phone: input.phone,
          notes: input.notes,
          isActive: input.isActive,
          userId: input.userId,
          supervisorId: input.supervisorId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getSignature = () =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/signature`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  getSignatureUrl = (path: string) =>
    this.environment.getApiUrl(this.apiName) + '/api/servicing/' + path;

  deleteSignature = () =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/signature`,
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string) =>
    this.restService.request<any, StaffDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/details`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: StaffUpdateDto) =>
    this.restService.request<any, StaffDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  credentialsExport = (input: GetStaffsInput) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/staffCredentialsExport`,
        responseType: 'blob',
        params: {
          term: input.term,
          firstName: input.firstName,
          lastName: input.lastName,
          role: input.role,
          code: input.code,
          email: input.email,
          phone: input.phone,
          notes: input.notes,
          isActive: input.isActive,
          userId: input.userId,
          supervisorId: input.supervisorId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          getFromUsers: input.getFromUsers,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getKeyringPasswordQr = (keyringPassword: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/getKeyringPasswordQr`,
        responseType: 'blob',
        params: {
          keyringPassword,
        },
      },
      { apiName: this.apiName }
    );

  getQRPictureUrl = (keyringPassword: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/getKeyringPasswordQr`,
        params: {
          keyringPassword: keyringPassword,
        },
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService, private environment: EnvironmentService) {}
}
