import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { LocationDto, LocationService } from '@proxy/register-service/locations';
import { consumableLocationTypes, notConsumableLocationTypes } from '@/shared/constants';

@Component({
  selector: 'app-locations-modal',
  templateUrl: './locations-modal.component.html',
  styleUrls: ['./locations-modal.component.scss'],
})
export class LocationsModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() handleSelect = new EventEmitter<{ id: string; isTransfer: boolean }>();

  selectedLocation: LocationDto = null;
  isBusy: boolean = true;
  isTransfer: boolean = true;
  isAdmin: boolean = true;

  constructor(
    public readonly service: LocationService,
    private readonly permissionService: PermissionService,
    private readonly toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.isAdmin =
      this.permissionService.getGrantedPolicy('AbpIdentity.Roles.Page') &&
      !this.permissionService.getGrantedPolicy('SitePlant.SmartCode');
  }

  selectLocation(location: LocationDto) {
    this.isBusy = false;
    if (notConsumableLocationTypes.includes(location.locationTypeName)) {
      this.selectedLocation = null;
      return this.toasterService.info('The selected type is not available');
    }
    this.isTransfer = !consumableLocationTypes.includes(location.locationTypeName);
    this.selectedLocation = location;
  }

  submit(isConsume?: boolean) {
    this.isBusy = true;
    this.handleSelect.emit({
      id: this.selectedLocation.id,
      isTransfer: isConsume ? false : this.isTransfer,
    });
  }
}
