import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '',
        name: '::Menu:Home',
        order: 11,
        layout: eLayoutType.application,
      },
      {
        path: '/qr-not-found',
        name: '::QR',
        order: 999,
        layout: eLayoutType.application,
        invisible: true,
      },
      {
        path: '/home',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-chart-line',
        order: 11,
        layout: eLayoutType.application,
      },
      /*   */
      /*  { */
      /*    path: '/home', */
      /*    name: '::Menu:Dashboard', */
      /*    iconClass: 'fas fa-map', */
      /*    order: 12, */
      /*    layout: eLayoutType.application, */
      /*    requiredPolicy: 'SitePlant.Dashboard.Host || SitePlant.Dashboard.Jobsite', */
      /*  }, */

      {
        path: '/dashboard',
        name: '::GPS Tracking',
        iconClass: 'fas fa-map-marker-alt',
        order: 12,
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Dashboard.Host || SitePlant.Dashboard.Tenant',
      },
      {
        path: '/job',
        name: 'AdministrationService::Permission:Jobsite',
        iconClass: 'fas fa-city',
        order: 13,
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Jobsite',
      },
      {
        path: '/queue',
        name: 'QueueService::Menu:QueueService',
        iconClass: 'fas fa-list',
        order: 14,
        layout: eLayoutType.application,
        requiredPolicy: 'SitePlant.Queue',
      },
      {
        path: '/assets',
        name: 'RegisterService::Menu:Assets',
        iconClass: 'fas fa-tools',
        order: 15,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Assets.Page',
      },
      {
        path: '/servicing',
        name: 'ServicingService::Menu:ServicingService',
        iconClass: 'fas fa-toolbox',
        order: 18,
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.Events.Page || ServicingService.InspectionTypes.Page',
      },
      {
        path: '/register',
        name: 'RegisterService::Menu:RegisterService',
        iconClass: 'fas fa-book',
        order: 19,
        layout: eLayoutType.application,
        requiredPolicy:
          'RegisterService.Categories.Page || RegisterService.Locations.Page || RegisterService.Sites.Page',
      },
      {
        path: '/accounts',
        name: '::Menu:Accounts',
        iconClass: 'fas fa-user',
        order: 20,
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.Staffs.Page',
      },
      {
        path: '/settings',
        name: '::Menu:Settings',
        iconClass: 'fas fa-cogs',
        order: 21,
        layout: eLayoutType.application,
        requiredPolicy:
          'RegisterService.Manufacturers.Page || RegisterService.AttributeDefinitions.Page || ServicingService.InspectionStatuses.Page || SitePlant.Application',
      },
      {
        path: '/inspections',
        name: '::Menu:Events',
        iconClass: 'fas fa-list',
        order: 101,
        layout: eLayoutType.application,
        invisible: true,
      },
    ]);

    routes.patch('AbpIdentity::Roles', {
      requiredPolicy: 'AbpIdentity.Roles.Page',
    });
    routes.patch('AbpIdentity::OrganizationUnits', {
      requiredPolicy: 'AbpIdentity.OrganizationUnits.Page',
    });
    routes.patch('AbpIdentity::Menu:IdentityManagement', {
      requiredPolicy:
        'AbpIdentity.Roles.Page || AbpIdentity.Users || AbpIdentity.ClaimTypes || AbpIdentity.OrganizationUnits.Page',
    });
    // const predicate = (item: TreeNode<{ name: string }>) => item.name === 'AbpIdentity::Menu:IdentityManagement';
    // const identityMenu = routes.find(predicate);
  };
}
