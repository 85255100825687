<div class="selector-container">
  <label class="form-label">
    {{ displayName }} {{ 'ServicingService::Location' | abpLocalization }}
  </label>

  <div>
    <button
      *ngIf="!name"
      type="button"
      class="btn btn-outline-secondary"
      (click)="onSelectClicked()"
    >
      {{ 'AbpUi::Select' | abpLocalization }}
    </button>

    <div *ngIf="name">
      <span>{{ name }}</span>
      <i class="fas fa-times fa-lg ms-2" (click)="onRemove()"></i>
    </div>
  </div>
</div>

<abp-modal [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h5>{{ displayName }} {{ 'RegisterService::Locations' | abpLocalization }}</h5>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form">
      <div class="mb-3">
        <input
          [placeholder]="'AbpUi::Search' | abpLocalization"
          formControlName="term"
          class="form-control"
        />
      </div>
    </form>

    <app-locations-tree
      [isLoading]="isLoading"
      [data]="data"
      [readonly]="readonly"
      [selectedNode]="selectedLocation"
      (selectedNodeChange)="onSelectedNodeChange($event)"
      [filters]="filters"
      [locationDefinition]="locationDefinition"
      [displayHeaders]="false"
    >
    </app-locations-tree>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" (click)="onSelect()" [disabled]="!selectedLocationTemp">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
