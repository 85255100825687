<div>
  <div class="card">
    <app-assets-table
      [assetColumns]="assetColumns"
      [(selected)]="selected"
      [list]="list"
      [hideCheckbox]="true"
      [isBusy]="isBusy"
      [data]="data"
    ></app-assets-table>
  </div>
</div>
