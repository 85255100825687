import api from '@flatfile/api';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { isAfter, isFuture } from 'date-fns';
import { autocast } from '@flatfile/plugin-autocast';
import { FlatfileListener } from '@flatfile/listener';
import { bulkRecordHook, recordHook } from '@flatfile/plugin-record-hook';
import { InspectionService } from '@proxy/servicing-service/inspections/inspection.service';
import { AssetService } from '@/proxy/register-service/assets/asset.service';

@Injectable({
  providedIn: 'root',
})
export class CustomListenerService {
  jobCompleted$: Subject<void> = new Subject<void>();
  closeSpace$: Subject<void> = new Subject<void>();
  errorDetails: { id: string; locations: { id: string; fullName: string }[] }[] = [];
  constructor(private service: InspectionService, private assetService: AssetService) {}

  validateDates(record: any) {
    const doneOnStr = record.get('doneOn');
    const dueOnStr = record.get('dueOn');

    const adjustToUserOffset = (dateStr: string) => {
      if (!dateStr) return null;
      const localDate = new Date(dateStr);
      if (isNaN(localDate.getTime())) return null;

      // Adjust the year if it's less than the current year
      const currentYear = new Date().getFullYear();
      if (localDate.getFullYear() < currentYear) {
        localDate.setFullYear(currentYear);
      }

      const userOffsetMillis = new Date().getTimezoneOffset() * 60 * 1000;

      const adjustedDate = new Date(localDate.getTime() - userOffsetMillis);
      return adjustedDate;
    };

    const doneOnDate = adjustToUserOffset(doneOnStr);
    const dueOnDate = adjustToUserOffset(dueOnStr);

    if (doneOnDate) {
      record.set('doneOn', doneOnDate.toUTCString());

      if (isFuture(doneOnDate)) {
        record.addError('doneOn', 'The "doneOn" date cannot be in the future.');
      }
    }

    if (dueOnDate) {
      record.set('dueOn', dueOnDate.toUTCString());

      if (doneOnDate && isAfter(doneOnDate, dueOnDate)) {
        record.addError('dueOn', 'The "dueOn" date must be greater than "doneOn".');
      }
    }
  }

  public listener = FlatfileListener.create(listener => {
    // listener.on('**', event => {
    //   console.log(`Received event: ${event.topic}`, event);
    // });

    listener.use(autocast('events', ['doneOn', 'dueOn']));

    listener.use(
      recordHook('events', record => {
        const matchingError = this.errorDetails.find(e => e.id === record.rowId);
        if (matchingError) {
          const selectedLocation = matchingError.locations.find(
            e => e.id === record.get('targetLocationId')
          );
          !selectedLocation &&
            record.addError(
              'targetLocationId',
              `Selected location not available for this asset. Available locations: ${matchingError.locations
                .map(e => `(${e.fullName})`)
                .join(', ')}`
            );
        }

        this.validateDates(record);
        return record;
      })
    );

    listener.on('job:outcome-acknowledged', event => {
      this.closeSpace$.next();
    });

    listener.use(
      bulkRecordHook('events', records => {
        records.map(record => {
          record.compute('pdfDocument', (pdfDocument, record) => {
            const value1 = record.get('assetPrimaryID');
            const value2 = record.get('reportNumber');
            return `cert-${value1}-${value2}.pdf`;
          });
          return record;
        });
      })
    );

    listener.filter({ job: 'workbook:submitActionFg' }, configure => {
      configure.on('job:ready', async ({ context: { jobId, workbookId } }) => {
        try {
          await api.jobs.ack(jobId, {
            info: 'Getting started.',
            progress: 10,
          });
          const sheets = await api.sheets.list({ workbookId }).catch(err => {
            throw new Error(`Failed to fetch sheets: ${err.message}`);
          });
          // Fetch data from all sheets and process it
          const allData = await Promise.all(
            sheets.data.map(async sheet => {
              try {
                const records = await api.records.get(sheet.id);
                return records.data;
              } catch (err) {
                throw new Error(`Failed to fetch records for sheet ${sheet.id}: ${err.message}`);
              }
            })
          );
          const records = allData[0]?.records || [];
          if (!records.length) {
            throw new Error('No records found in the workbook.');
          }
          this.assetService.validateLocations(records).subscribe({
            next: async data => {
              this.errorDetails = data;

              if (data.length) {
                await api.jobs.fail(jobId, {
                  outcome: {
                    message: 'Bulk upload failed. Please check the data and try again.',
                  },
                });
                api.sheets.validate(sheets.data[0].id);
              } else {
                await api.jobs.ack(jobId, {
                  info: 'Validation successful. Starting bulk upload.',
                  progress: 50,
                });

                this.service.bulkUpload(records).subscribe({
                  next: async () => {
                    await api.jobs.complete(jobId, {
                      outcome: {
                        message: 'Action completed successfully.',
                        acknowledge: true,
                      },
                    });
                    this.jobCompleted$.next();
                  },
                  error: async () => {
                    await api.jobs.fail(jobId, {
                      outcome: {
                        message: 'Bulk upload failed. Please check the data and try again.',
                      },
                    });
                  },
                });
              }
            },
          });
        } catch (error: any) {
          console.error('Error:', error.stack);
          // Mark job as failed in case of error
          await api.jobs.fail(jobId, {
            outcome: {
              message: 'This job encountered an error.',
            },
          });
        }
      });
    });
  });
}
