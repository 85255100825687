import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetLocationsInput,
  LocationCreateDto,
  LocationDto,
  LocationUpdateDto,
  LocationDetailsDto,
  GetimmediateLocationsInput,
  JobsitePagedResultDto,
  JobsiteDetailsDto,
  GetJobsiteInput,
} from './models';
import type { LookupDto, LookupRequestDto } from '@proxy/shared/models';
import { LocationLookupRequestDto } from '../shared';
import { AddConsumableCount } from '../consumables';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/locations';
  defaultGUID: string = '00000000-0000-0000-0000-000000000000';

  create = (input: LocationCreateDto) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'POST',
        url: this.urlPrefix,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  setConsumableCount = (body: AddConsumableCount) => {
    return this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/configureConsumable`,
        body,
      },
      { apiName: this.apiName }
    );
  };

  setMSL = (body: AddConsumableCount) => {
    return this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/configureMinStockLevel`,
        body,
      },
      { apiName: this.apiName }
    );
  };

  getLookup = (input: LocationLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
        params: {
          assetId: input.assetId,
          filter: input.filter,
          groupIds: input.groupIds,
          jobsiteIds: input.jobsiteIds,
          locationDefinition: input.locationDefinition,
          maxResultCount: input.maxResultCount,
          parentId: input.parentId,
          skipCount: input.skipCount,
        },
      },
      { apiName: this.apiName }
    );

  getLocationType = (id: string) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/types/${id}`,
      },
      { apiName: this.apiName }
    );

  locationTypesLookup = (input: GetLocationsInput) =>
    this.restService.request<any, PagedResultDto<any>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/types`,
        params: {
          forDeallocation: input.forDeallocation,
          jobsite: input.jobsite,
          homeLocation: input.homeLocation,
          secondaryLocation: input.secondaryLocation,
        },
      },
      { apiName: this.apiName }
    );

  getImmediateChildLocations = (input: GetimmediateLocationsInput) =>
    this.restService.request<any, PagedResultDto<LocationDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/immediate`,
        params: {
          locationId: input.locationId,
          locationPath: input.locationPath,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getList = (input: GetLocationsInput) =>
    this.restService.request<any, PagedResultDto<LocationDetailsDto>>(
      {
        method: 'GET',
        url: this.urlPrefix,
        params: {
          addressCity: input.addressCity,
          addressPostCode: input.addressPostCode,
          addressStreet1: input.addressStreet1,
          assetId: input.assetId,
          email: input.email,
          forDeallocation: input.forDeallocation,
          isActive: input.isActive,
          locationDefinition: input.locationDefinition,
          locationTypeId: input.locationTypeId,
          maxResultCount: input.maxResultCount,
          name: input.name,
          pinOrKeyringPassword: input.pinOrKeyringPassword,
          parentId: input.parentId,
          positionLatitudeMax: input.positionLatitudeMax,
          positionLatitudeMin: input.positionLatitudeMin,
          positionLongitudeMax: input.positionLongitudeMax,
          positionLongitudeMin: input.positionLongitudeMin,
          siteId: input.siteId,
          skipCount: input.skipCount,
          sorting: input.sorting,
          term: input.term,
        },
      },
      { apiName: this.apiName }
    );

  getJobsiteList = (input: GetJobsiteInput) =>
    this.restService.request<any, JobsitePagedResultDto<JobsiteDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/jobsites`,
        params: {
          available: input.available,
          barcode: input.barcode,
          categoryId: input.categoryId,
          categoryPath: input.categoryPath,
          code: input.code,
          daysOnJobsite: input.daysOnJobsites,
          homeLocationId: input.homeLocationId,
          isActive: input.isActive,
          isDeallocated: input.isDeallocated,
          isFixed: input.isFixed,
          jobsiteLocationId: input.jobsiteLocationId,
          jobsites: input.jobsites,
          locationPath: input.locationPath,
          manufacturerId: input.manufacturerId,
          maxResultCount: input.maxResultCount,
          modelId: input.modelId,
          name: input.name,
          nickname: input.nickname,
          notes: input.notes,
          origCertNumber: input.origCertNumber,
          parentId: input.parentId,
          primaryID: input.primaryID,
          rfid: input.rfid,
          rrp: input.rrpPercent,
          secondaryID: input.secondaryID,
          secondaryLocationId: input.secondaryLocationId,
          siteId: input.siteId,
          skipCount: input.skipCount,
          sorting: input.sorting,
          term: input.term,
          type: input.assetType,
          unnecessaryHire: input.unnecessaryHire,
        },
      },
      { apiName: this.apiName }
    );

  getSiteLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/site-lookup`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string) =>
    this.restService.request<any, LocationDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/details`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: LocationUpdateDto) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deactivate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/deactivate`,
      },
      { apiName: this.apiName }
    );

  activate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/activate`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
