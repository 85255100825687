import { TreeAdapter } from '@abp/ng.components/tree';
import { PagedResultDto } from '@abp/ng.core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { AttributeTreeNode } from '@/attribute-definitions/models/attribute-tree-node.model';

@Component({
  selector: 'app-attributes-tree',
  templateUrl: './attributes-tree.component.html',
  styleUrls: ['./attributes-tree.component.scss'],
})
export class AttributesTreeComponent implements OnChanges {
  @Input() isLoading: boolean;
  @Input() checkStrictly: boolean = false;
  @Input() checkable: boolean = false;
  @Input() selectedNode: AttributeTreeNode;
  @Input() treeTitle: string;
  @Input() isAllSelected: boolean = false;
  @Output() toggleActiveNode = new EventEmitter<AttributeTreeNode>();
  @Output() selectedNodeChange = new EventEmitter<AttributeTreeNode>();
  @Output() checkedKeysChange = new EventEmitter<string[]>();

  @Input() data: PagedResultDto<AttributeTreeNode> = {
    items: [],
    totalCount: 0,
  };
  expandedKeys: any[] = [];
  allKeys: string[] = [];
  nodes: AttributeTreeNode[];
  treeAdapter: TreeAdapter<AttributeTreeNode>;

  private _checkedKeys: string[] = [];

  @Input()
  get checkedKeys(): string[] {
    return this._checkedKeys;
  }

  set checkedKeys(value: string[]) {
    this._checkedKeys = value;
    this.checkedKeysChange.emit(this._checkedKeys);
  }

  constructor(public readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && (!changes.data.firstChange || !changes.data.previousValue)) {
      this.setTreeAdapterData(changes.data.currentValue);
    }
  }

  setTreeAdapterData(list: PagedResultDto<AttributeTreeNode>) {
    if (!list) return;

    this.data = list;
    this.treeAdapter = new TreeAdapter(list.items);
    this.nodes = this.treeAdapter.getTree();
    this.expandedKeys = [...this.expandedKeys];
    if (this.isAllSelected && !this.checkedKeys.length) {
      this.allKeys = this.data.items.map(e => e.id);
      this.checkedKeys = this.allKeys;
    }
  }

  onToggleActive(node: NzTreeNode, $event: MouseEvent) {
    this.toggleActiveNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  isNodeSelected(node: NzTreeNode) {
    return node.origin.id === this.selectedNode?.id;
  }

  onSelectedNode(node: AttributeTreeNode) {
    this.selectedNodeChange.emit(node);
  }
}
