import { Injectable } from '@angular/core';
import { Rest, RestService } from '@abp/ng.core';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IAssetFields } from '../models';
import { receiveDownload } from '../../shared/receive-download';
import { GetInspectionsInput, InspectionState } from '@proxy/servicing-service/inspections';

@Injectable({
  providedIn: 'root',
})
export class CertService {
  apiName = 'CertReportService';
  urlPrefix = '/api/cert-report/certs';

  get = (inspectionId: string) =>
    this.restService
      .request<any, HttpResponse<Blob>>(
        {
          method: 'GET',
          url: `${this.urlPrefix}/${inspectionId}`,
          responseType: 'blob',
        } as Rest.Request<void>,
        { apiName: this.apiName, observe: Rest.Observe.Response }
      )
      .pipe(map(resp => receiveDownload(resp)));

  bundleCerts = (input: GetInspectionsInput, listType: string, format = 'pdf') => {
    let states = input.state !== null && input.state !== undefined ? [input.state] : [];
    switch (listType) {
      case 'pending':
        states.push(InspectionState.Draft, InspectionState.Cancelled);
        break;
      case 'completed':
        states.push(InspectionState.Approved);
        break;
      case 'upcoming':
        states.push(InspectionState.Approved);
        break;
      case 'past':
        states.push(InspectionState.Approved, InspectionState.Archived);
        break;
      default:
        states = null;
        break;
    }

    return this.restService
      .request<any, HttpResponse<Blob>>(
        {
          method: 'GET',
          url: `${this.urlPrefix}/bundle`,
          responseType: 'blob',
          params: {
            ...input,
            states: states,
            format: format,
          },
        },
        { apiName: this.apiName, observe: Rest.Observe.Response }
      )
      .pipe(map(resp => receiveDownload(resp)));
  };

  bundleCertsFromIds = (ids: string[]) =>
    this.restService
      .request<any, HttpResponse<Blob>>(
        {
          method: 'POST',
          url: `${this.urlPrefix}/bundle-from-ids`,
          responseType: 'blob',

          body: ids,
        },
        { apiName: this.apiName, observe: Rest.Observe.Response }
      )
      .pipe(map(resp => receiveDownload(resp)));

  getAssetsReport = (body: IAssetFields) =>
    this.restService
      .request<any, HttpResponse<Blob>>(
        {
          method: 'POST',
          url: `${this.urlPrefix}/assets`,
          responseType: 'blob',
          body,
        },
        { apiName: this.apiName, observe: Rest.Observe.Response }
      )
      .pipe(map(resp => receiveDownload(resp)));

  constructor(private restService: RestService) {}
}
