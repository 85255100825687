<abp-modal [(visible)]="openCheckoutModal" (disappear)="modalClose.emit(false)">
  <ng-template #abpHeader>
    <h3>
      {{ 'RegisterService::CheckoutAssets' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <div class="mb-3">
      <app-locations-tree
        [isLoading]="isLoading"
        [readonly]="true"
        [selectedNode]="selectedLocation"
        [pinOrKeyringPassword]="pinOrKeyringPassword"
        (selectedNodeChange)="selectLocation($event)"
        [locationDefinition]="locationDefinition"
        [displayHeaders]="false"
      >
      </app-locations-tree>
    </div>
    <div class="flex gap-2" *ngIf="showMessage">
      <label class="text-black-base font-medium">
        {{ 'ServicingService::TotalSelected' | abpLocalization }}:
      </label>

      <label class="form-label"> {{ assets.length }} </label>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-primary" (click)="checkout()" [disabled]="isBusy">
      {{ 'AbpUi::Apply' | abpLocalization }}
    </button>
    <button
      *abpPermission="'RegisterService.Assets.Return'"
      type="button"
      class="btn btn-outline-secondary"
      (click)="return()"
      [disabled]="!asset?.secondaryLocationId"
    >
      {{ 'AbpUi::Return' | abpLocalization }}
    </button>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
