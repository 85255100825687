import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-yes-no-display',
  templateUrl: './yes-no-display.component.html',
  styleUrls: [],
})
export class YesNoDisplayComponent {
  @Input() value: boolean;
}
