import { Component, Input, OnInit } from '@angular/core';
import { AssetService, AssetUtilizationDto, AssetDetailsDto } from 'src/app/proxy/register-service/assets';

@Component({
  selector: 'app-asset-utilization',
  templateUrl: './utilization.component.html',
  styleUrls: ['./utilization.component.scss'],
})
export class AssetUtilizationComponent implements OnInit {
  @Input() asset: AssetDetailsDto;
  data: AssetUtilizationDto | null = null; // To store the response
  error: string | null = null; // To store any error
  
  // Array to hold the months' data
  months: any[] = [];
  // Array for the days of the week headers
  weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  constructor(private assetService: AssetService) {}

  ngOnInit() {

    this.generateMonths();
    this.fetchUtilizationData()

  }

  fetchUtilizationData(): void {
    this.assetService.getAssetUtilization(this.asset.id)
      .subscribe({
        next: (response) => {
          this.data = response;
          this.updateUtilizationData(response); // Update calendar with the API data
          console.log('Asset Utilization Data:', this.data); // Log to console
        },
        error: (err) => {
          this.error = 'Error loading asset utilization data';
          console.error('Error loading asset utilization data:', err); // Log error to console
        }
      });
  } 

   // Generate month panels
  generateMonths(): void {
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      // Get the date for the month
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      const month = {
        name: date.toLocaleString('default', { month: 'long', year: 'numeric' }),
        days: this.generateDays(date)
      };
      // Add the month to the months array
      this.months.push(month);
    }
  }
  generateDays(date: Date): any[] {
    const days = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
  
    // Determine the first Monday to display
    let firstDayToDisplay = new Date(firstDayOfMonth);
    while (firstDayToDisplay.getDay() !== 1) {
      firstDayToDisplay.setDate(firstDayToDisplay.getDate() - 1);
    }
  
    // Determine the last Sunday to display
    let lastDayToDisplay = new Date(lastDayOfMonth);
    while (lastDayToDisplay.getDay() !== 0) {
      lastDayToDisplay.setDate(lastDayToDisplay.getDate() + 1);
    }
  
    // Generate days for the entire calendar view from firstDayToDisplay to lastDayToDisplay
    for (let current = new Date(firstDayToDisplay); current <= lastDayToDisplay; current.setDate(current.getDate() + 1)) {
      const isCurrentMonth = current.getMonth() === month;
      const isWeekend = current.getDay() === 0 || current.getDay() === 6;
  
      if (isCurrentMonth) {
        const day = {
          date: current.getDate(),
          isWorkingDay: !isWeekend,
          class: isWeekend ? 'non-working-day' : 'working-day',
          status: 'Non-Working' // Default status
        };
        days.push(day);
      } else {
        // Add placeholder for alignment, no date information
        days.push(null);
      }
    }
  
    return days;
  } 
 
  updateUtilizationData(data: any): void {
    const statusData = data.status;
    let lastStatus = null;
    let lastStatusDate = null;
    const today = new Date();
  
    // Log the initial state of the months array
    console.log('Initial months data:', this.months);
  
    statusData.forEach((record: any) => {
      const currentDate = new Date(record.date);
      const isInUse = record.inUse;
  
      // Set the status from lastStatusDate up to the day before the current record's date
      if (lastStatusDate) {
        for (let d = new Date(lastStatusDate); d < currentDate; d.setDate(d.getDate() + 1)) {
          const isWeekend = d.getDay() === 0 || d.getDay() === 6;
          const month = this.months.find(m => {
            const monthDate = new Date(m.name);
            return monthDate.getMonth() === d.getMonth() && monthDate.getFullYear() === d.getFullYear();
          });
  
          if (month) {
            const day = month.days.find(day => day && day.date === d.getDate()); // Check for null entries
            if (day) {
              if (isWeekend) {
                day.status = 'Non-Working';
                day.class = 'non-working-day';
              } else {
                day.status = lastStatus ? 'In-Use' : 'Not In-Use';
                day.class = lastStatus ? 'in-use' : 'not-in-use';
              }
            } else {
              console.warn(`Day ${d.getDate()} not found in month ${month.name}`);
            }
          } else {
            console.warn(`Month ${d.getMonth() + 1}-${d.getFullYear()} not found`);
          }
        }
      }
  
      // Set the current record date status
      const month = this.months.find(m => {
        const monthDate = new Date(m.name);
        return monthDate.getMonth() === currentDate.getMonth() && monthDate.getFullYear() === currentDate.getFullYear();
      });
  
      if (month) {
        const day = month.days.find(day => day && day.date === currentDate.getDate()); // Check for null entries
        if (day) {
          day.status = isInUse ? 'In-Use' : 'Not In-Use';
          day.class = isInUse ? 'in-use' : 'not-in-use';
        } else {
          console.warn(`Day ${currentDate.getDate()} not found in month ${month.name} for current record date`);
        }
      } else {
        console.warn(`Month ${currentDate.getMonth() + 1}-${currentDate.getFullYear()} not found for current record date`);
      }
  
      // Update the last status and date
      lastStatus = isInUse;
      lastStatusDate = new Date(currentDate);
      lastStatusDate.setDate(lastStatusDate.getDate() + 1); // Start from the next day
    });
  
    // Apply the last known status only up to today
    if (lastStatusDate && lastStatusDate <= today) {
      for (let d = new Date(lastStatusDate); d <= today; d.setDate(d.getDate() + 1)) {
        const isWeekend = d.getDay() === 0 || d.getDay() === 6;
        const month = this.months.find(m => {
          const monthDate = new Date(m.name);
          return monthDate.getMonth() === d.getMonth() && monthDate.getFullYear() === d.getFullYear();
        });
  
        if (month) {
          const day = month.days.find(day => day && day.date === d.getDate()); // Check for null entries
          if (day) {
            if (isWeekend) {
              day.status = 'Non-Working';
              day.class = 'non-working-day';
            } else {
              day.status = lastStatus ? 'In-Use' : 'Not In-Use';
              day.class = lastStatus ? 'in-use' : 'not-in-use';
            }
          } else {
            console.warn(`Day ${d.getDate()} not found in month ${month.name}`);
          }
        } else {
          console.warn(`Month ${d.getMonth() + 1}-${d.getFullYear()} not found`);
        }
      }
    }
  
    // Log the final state of the months array
    console.log('Final months data:', this.months);
  }
}