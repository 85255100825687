import { ABP, ListService, PagedResultDto, PermissionService, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, switchMap } from 'rxjs/operators';
import { resultTypeOptions } from '@/proxy/servicing-service/inspection-statuses/result-type.enum';
import type {
  GetInspectionsInput,
  InspectionDetailsDto,
} from '@/proxy/servicing-service/inspections/models';
import { InspectionService } from '@/proxy/servicing-service/inspections/inspection.service';
import { CertService } from '@proxy/cert-report-service/certs/cert.service';
import { Router } from '@angular/router';
import { InspectionState, inspectionStateOptions } from '@proxy/servicing-service/inspections';
import fileDownload from 'js-file-download';
import { AssetDetailsDto, AssetService } from '@proxy/register-service/assets';
import { CategoryService } from '@proxy/register-service/categories';

@Component({
  selector: 'app-asset-details-event-history',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.scss'],
})
export class AssetEventHistoryComponent implements OnInit {
  @Input() asset: AssetDetailsDto;

  data: PagedResultDto<InspectionDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetInspectionsInput;
  form: FormGroup;
  isFiltersHidden = true;
  isModalBusy = false;
  isModalOpen = false;
  selected?: InspectionDetailsDto;
  resultTypeOptions = resultTypeOptions;
  inspectionStateOptions = inspectionStateOptions;
  status: any;

  constructor(
    public readonly router: Router,
    public readonly list: ListService,
    public readonly service: InspectionService,
    public readonly assetService: AssetService,
    public readonly categoryService: CategoryService,
    private readonly certService: CertService,
    private readonly permissionService: PermissionService,
    private readonly confirmation: ConfirmationService,
    private readonly toasterService: ToasterService
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        isGroup: false,
        assetId: this.asset.id,
        isHistorical: true,
        maxResultCount: 50,
        term: query.filter,
      });

    const setData = (list: PagedResultDto<InspectionDetailsDto>) => {
      this.data = list;
      this.data.items
        .filter(q => q.assetPictureUrl || q.categoryPictureUrl)
        .forEach(i =>
          this.getPicture(
            i.assetPictureUrl ? i.assetId : i.categoryId,
            i.assetPictureUrl ? 'asset' : 'category'
          )
        );
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  onGetReport(record: InspectionDetailsDto) {
    this.certService.get(record.id).subscribe(resp => {
      fileDownload(resp.data, resp.fileName, resp.contentType);
    });
  }

  onApprove(record: InspectionDetailsDto) {
    this.service.approve(record.id).subscribe(() => {
      this.toasterService.success('ServicingService::SuccessfullyCompleted');
      this.list.get();
    });
  }

  onRepeat(record: InspectionDetailsDto) {
    this.selected = undefined;
    this.router.navigate(['/inspections/create'], {
      queryParams: { assetId: record.assetId, typeId: record.typeId },
    });
  }

  onDelete(record: InspectionDetailsDto) {
    this.confirmation
      .warn('ServicingService::DeleteConfirmationMessage', 'ServicingService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(record.id))
      )
      .subscribe(this.list.get);
  }

  onView(record: InspectionDetailsDto) {
    this.router.navigate(['/inspections/details'], {
      queryParams: { id: record.id, assetId: record.assetId, typeId: record.typeId },
    });
  }

  isDeletable(record: InspectionDetailsDto) {
    return (
      record.state === InspectionState.Draft &&
      record.isActive &&
      this.permissionService.getGrantedPolicy('ServicingService.Inspections.Delete')
    );
  }

  getPicture(id: string, type: 'asset' | 'category') {
    if (type === 'asset') {
      this.assetService.getPicture(id).subscribe(image => this.loadPictureFromBlob(id, image));
    } else if (type === 'category') {
      this.categoryService.getPicture(id).subscribe(image => this.loadPictureFromBlob(id, image));
    }
  }

  private loadPictureFromBlob(id, blob) {
    if (blob) {
      if (blob.size > 102400) {
        this.confirmation.info(`RegisterService::InvalidImageSize`, 'Image Upload', {
          cancelText: 'OK',
          hideYesBtn: true,
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          this.data.items.find(q => q.assetId == id || q.categoryId == id).pictureUrl =
            reader.result as string;
        };
        reader.readAsDataURL(blob);
      }
    }
  }
}
