<div class="card">
  <div class="card-body p-0">
    <div class="d-flex justify-content-between align-items-center mb-3" *ngIf="treeTitle">
      <h4 for="create-location-button" class="m-0">
        {{ treeTitle }}
      </h4>
    </div>
    <abp-tree
      [nodes]="nodes"
      [checkable]="checkable"
      [checkStrictly]="checkStrictly"
      [(checkedKeys)]="checkedKeys"
      [(expandedKeys)]="expandedKeys"
      [selectedNode]="selectedNode"
      [draggable]="false"
      (selectedNodeChange)="onSelectedNode($event)"
    >
      <ng-template abpTreeNodeTemplate let-node>
        <div
          class="node-container d-flex"
          [class.selected]="isNodeSelected(node)"
          (mouseover)="node.isHovered = true"
          (mouseleave)="node.isHovered = false"
        >
          {{ node.title }}
        </div>
      </ng-template>
    </abp-tree>
    <div class="card-body" *ngIf="!nodes?.length">
      <p class="text-muted">
        {{ 'RegisterService::Notavailable' | abpLocalization }}
      </p>
    </div>
  </div>
</div>
