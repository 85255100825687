import { RequestPeriodRange } from '@proxy/register-service/requests';

export default class Helpers {
  static normalizeText(text: string) {
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static normalizeTextDate(date: Date): string {
    return date.toISOString().split('T')[0].replace(/-/g, '.');
  }

  static getDisplayText(value: RequestPeriodRange): string {
    const displayMap: { [key in RequestPeriodRange]: string } = {
      [RequestPeriodRange.WEEKLY]: '1 Week',
      [RequestPeriodRange.TWO_WEEKS]: '2 Weeks',
      [RequestPeriodRange.MONTHLY]: '1 Month',
      [RequestPeriodRange.THREE_MONTHS]: '3 Months',
      [RequestPeriodRange.SIX_MONTHS]: '6 Months',
    };

    return displayMap[value] || value;
  }
}
