import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AssetDetailsDto,
  AssetService,
  AssetTransferableParts,
  ComponentStatus,
} from '@proxy/register-service/assets';

@Component({
  selector: 'app-transfer-sub-assets-modal',
  templateUrl: './transfer-sub-assets-modal.component.html',
})
export class TransferSubAssetsModalComponent implements OnInit {
  @Input() openModal: boolean;
  @Input() asset: AssetDetailsDto;
  @Input() pinOrKeyringPassword: string = '';
  @Input() type: string;
  @Output() modalClose = new EventEmitter<boolean>();
  @Output() transfer = new EventEmitter<AssetTransferableParts>();

  subAssets: AssetDetailsDto[];
  transferData: AssetTransferableParts = {
    subAssets: [],
    notes: '',
    missingIds: [],
    componentStatus: ComponentStatus.None,
  };
  ComponentStatus = ComponentStatus;
  isBusy: boolean = false;

  constructor(public readonly service: AssetService) {}

  ngOnInit() {
    this.service
      .getSubAssets({ ids: [this.asset.id], pinOrKeyringPassword: this.pinOrKeyringPassword })
      .subscribe({
        next: items => {
          if (items) {
            this.subAssets = items.filter(e => e.isActive);
            this.transferData = {
              subAssets: this.subAssets.map(e => ({
                id: e.id,
                componentStatus: e.components ? ComponentStatus.Exist : ComponentStatus.None,
              })),
              missingIds: [],
              notes: '',
              componentStatus: this.asset.components ? ComponentStatus.Exist : ComponentStatus.None,
            };
          }
        },
      });
  }

  isSelected(id: string): boolean {
    return this.transferData.subAssets.some(e => e.id === id);
  }

  isSubComponentSelected(id: string): boolean {
    return (
      this.transferData.subAssets.find(e => e.id === id)?.componentStatus === ComponentStatus.Exist
    );
  }

  selectComponents(event: any) {
    this.transferData.componentStatus = event.target.checked
      ? ComponentStatus.Exist
      : ComponentStatus.Missing;
  }

  selectSubComponents(event: any, id: string) {
    const status = event.target.checked ? ComponentStatus.Exist : ComponentStatus.Missing;

    const index = this.transferData.subAssets.findIndex(subAsset => subAsset.id === id);

    if (index !== -1) {
      this.transferData.subAssets[index].componentStatus = status;
    } else {
      this.transferData.subAssets.push({ id, componentStatus: status });
    }
  }

  setMissing(id: string) {
    const index = this.transferData.missingIds.indexOf(id);

    if (index === -1) {
      this.transferData.missingIds.push(id);
    } else {
      this.transferData.missingIds.splice(index, 1);
    }
  }

  selectSubAssets(event: any, item: AssetDetailsDto) {
    const index = this.transferData.subAssets.findIndex(subAsset => subAsset.id === item.id);

    if (event.target.checked) {
      if (index === -1) {
        this.transferData.subAssets.push({
          id: item.id,
          componentStatus: item.components ? ComponentStatus.Exist : ComponentStatus.None,
        });
      }
    } else {
      if (index !== -1) {
        this.transferData.subAssets.splice(index, 1);
      }
    }
  }

  onTransfer() {
    this.isBusy = true;
    this.transfer.emit(this.transferData);
  }
}
