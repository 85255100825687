import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file-preview',
  template: `
    <div
      [ngClass]="
        file.size > restrictions.maxFileSize ? ' file-preview border-danger' : 'file-preview'
      "
    >
      <div class="file-icon">
        <img *ngIf="isImage; else notImage" [src]="src" [alt]="file.name" />
        <ng-template #notImage>
          <div class="fallback"><img [src]="avatar" [alt]="file.name" /></div>
        </ng-template>
      </div>
      <div class="ms-1">
        <div>
          <a
            *ngIf="file.fileUrl; else internDoc"
            [href]="file.fileUrl"
            target="_blank"
            class="flex"
          >
            <span class="file-name">{{ file.name }}</span>
          </a>
          <ng-template #internDoc>
            <span class="flex">
              <span class="file-name">{{ file.name }}</span>
            </span>
          </ng-template>
        </div>
        <div>
          <span class="file-size">
            {{ file.size | filesize }}
          </span>
        </div>
      </div>
      <div class="file-actions">
        <app-progress-ring
          [hidden]="!(file.uploading && !uploaded)"
          primary="var(--bs-primary)"
          secondary="var(--bs-gray-200)"
          [radius]="24"
          [progress]="progress"
          [stroke]="3"
        ></app-progress-ring>
        <span *ngIf="uploaded">
          <i class="fas fa-check fa-lg not-at-risk"></i>
        </span>
        <span *ngIf="file.failed">
          <i class="fas fa-times fa-lg at-risk"></i>
        </span>
        <button
          [disabled]="isModalBusy"
          *ngIf="!uploaded && !file.uploading"
          class="btn"
          (click)="remove.emit()"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .file-preview {
        align-items: center;
        border: 1px solid transparent;
        display: flex;
        padding: 0.75rem;
      }

      .border-danger {
        border-color: var(--bg-danger);
        border-radius: var(--bs-border-radius);
      }

      .file-preview + .file-preview {
        border-top-color: var(--bs-border-color);
      }

      .file-preview:hover {
        background-color: var(--bs-bg-color);
        border-color: var(--bs-dark-border-color);
        border-radius: var(--bs-border-radius);
      }

      .file-preview:hover + .file-preview {
        border-top-color: transparent;
      }

      .file-icon {
        border-radius: var(--bs-border-radius);
        flex-shrink: 0;
        height: 2.625rem;
        margin-right: var(--bs-margin-md);
        overflow: hidden;
        width: 2.625rem;
      }

      .file-icon img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      .file-icon .fallback {
        align-items: center;
        border-radius: var(--bs-border-radius);
        border: 1px solid var(--bs-border-color);
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      .file-name {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: var(--bs-text-color);
        display: -webkit-box;
        font-size: var(--bs-text-base);
        font-weight: var(--bs-text-bold);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 300px;
      }

      .file-size {
        color: var(--bs-text-light);
        font-size: var(--bs-text-sm);
      }

      .file-actions {
        flex-shrink: 0;
        margin-left: auto;
        text-align: center;
        width: 3rem;
      }

      .file-actions .btn {
        box-shadow: none;
        padding: var(--bs-padding-xs);
      }
    `,
  ],
})
export class FilePreviewComponent implements OnInit {
  @Input() file: any; //File;
  @Input() restrictions;
  @Input() isModalBusy: boolean;
  @Output() remove = new EventEmitter();

  src = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.isImage) {
      if (window.FileReader) {
        const reader = new FileReader();
        reader.onload = () =>
          (this.src = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string));
        reader.readAsDataURL(this.file);
      }
    }
  }

  get avatar() {
    return encodeURI('https://ui-avatars.com/api/?name=' + this.file?.name);
  }

  get uploaded() {
    return this.file.total && this.file.total === this.file.progress && !this.file.failed;
  }

  get isImage() {
    return this.file.type.startsWith('image');
  }

  get progress() {
    let value = Math.round((this.file.progress * 100) / this.file.total);
    if (isNaN(value)) {
      value = 0;
    }
    return value;
  }
}
