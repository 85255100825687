<div class="lpx-mobile-navbar-container">
  <div class="lpx-mobile-navbar">
    <div class="lpx-mobile-nav-tab-left">
      <ul class="lpx-mobile-nav-tabs">
        <li
          class="lpx-mobile-nav-tab"
          *ngFor="let tab of navTabs"
          [routerLink]="tab.link"
          [routerLinkActive]="tab.link ? 'selected' : ''"
          [routerLinkActiveOptions]="{ exact: tab.link === '/' }"
          [class.selected]="tab.expanded"
          (click)="closeMenu()"
        >
          <a class="lpx-mobile-nav-item">
            <lpx-icon class="menu-item-icon" *ngIf="tab.icon" [iconClass]="tab.icon"></lpx-icon>
            <span class="mobile-item-text">
              {{ tab.text }}
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div class="lpx-mobile-nav-tab-center">
      <a class="lpx-mobile-hamburger" [ngClass]="toggleClass" (click)="toggleNavbarMenu()">
        <span
          class="hamburger-icon"
          aria-hidden="true"
          [class.lpx-mobile-menu-opn]="activeMenu === 'navbar'"
        >
          <span class="icon-part"></span>
          <span class="icon-part"></span>
          <span class="icon-part"></span>
          <span class="icon-part"></span>
          <span class="icon-part"></span>
          <span class="icon-part"></span>
        </span>
      </a>
    </div>

    <div class="lpx-mobile-nav-tab-right">
      <ul class="lpx-mobile-nav-tabs">
        <!-- <li
          class="lpx-mobile-nav-tab"
          (click)="toggleMenu('settings', settingsMenuItems)"
          [class.selected]="activeMenu === 'settings'"
        >
          <a class="lpx-mobile-nav-item">
            <lpx-icon class="menu-item-icon" iconClass="gearConnected"></lpx-icon>
            <span class="mobile-item-text">{{ settingsTitle | lpxTranslate | async }}</span>
          </a>
        </li>
        <li
          class="lpx-mobile-nav-tab"
          (click)="profileMenuItems && toggleMenu('profile', profileMenuItems)"
          [class.selected]="activeMenu === 'profile'"
        >
          <a class="lpx-mobile-nav-item" *ngIf="user$ | async as userProfile">
            <lpx-avatar [avatar]="userProfile.avatar"></lpx-avatar>
            <span class="mobile-item-text">{{ userProfile.userName }}</span>
          </a>
        </li> -->
        <li
          class="lpx-mobile-nav-tab"
          *ngFor="let tab of navTabs2"
          [routerLink]="tab.link"
          [routerLinkActive]="tab.link ? 'selected' : ''"
          [routerLinkActiveOptions]="{ exact: tab.link === '/' }"
          [class.selected]="tab.expanded"
          (click)="closeMenu()"
        >
          <a class="lpx-mobile-nav-item">
            <lpx-icon class="menu-item-icon" *ngIf="tab.icon" [iconClass]="tab.icon"></lpx-icon>
            <span class="mobile-item-text">
              {{ tab.text }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="lpx-mobile-menu" [hidden]="!menuVisible">
    <div class="lpx-logo-container"><lpx-brand-logo>SitePlant</lpx-brand-logo></div>
    <lpx-navbar-routes
      [navbarItems]="selectedMenuItems"
      [groupedItems]="groupedItems$ | async"
      (routeClick)="closeMenu()"
    >
    </lpx-navbar-routes>
  </div>
</div>
