import { mapEnumToOptions } from '@abp/ng.core';

export enum InspectionState {
  Draft = 'DRAFT',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Archived = 'ARCHIVED',
}

export enum InspectionPageStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  UPCOMING = 'upcoming',
  JOBSITE = 'jobsite',
  LIVE = 'live',
}

export enum InspectionTypes {
  ALL = 'All',
  CURRENT = 'Current',
  HISTORICAL = 'Historical',
}

export const inspectionStateOptions = mapEnumToOptions(InspectionState);
