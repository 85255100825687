import type {
  AssetCreateDto,
  AssetDetailsDto,
  AssetDto,
  AssetStatusDto,
  AssetUpdateDto,
  AssetsUpdateDto,
  AttributeCreateDto,
  AttributeDto,
  AttributeUpdateDto,
  GetAssetsInput,
  GetAttributesInput,
  GetMetersInput,
  MeterCreateDto,
  MeterDto,
  MeterUpdateDto,
} from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  AttachmentCreateDto,
  AttachmentDto,
  AttachmentUpdateDto,
  GetAttachmentsInput,
} from '../../attachment/models';
import type { FileStreamResult } from '../../microsoft/asp-net-core/mvc/models';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  apiName = 'Default';
  urlPrefix = '/api/register/assets';

  changeState = (input: AssetsUpdateDto) => {
    return this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/activate`,
        body: input,
      },
      { apiName: this.apiName }
    );
  };

  addAttribute = (id: string, input: AttributeCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/attributes`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  addMeter = (id: string, input: MeterCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/meters`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  attach = (id: string, input: AttachmentCreateDto<string>, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto<string>>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/attachments`,
        body: input.file,
      },
      { apiName: this.apiName, ...config }
    );

  create = (input: AssetCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssetDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  createWithPicture = (input: AssetCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssetDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: input.picture,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  deleteAttribute = (id: string, name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
      },
      { apiName: this.apiName, ...config }
    );

  deleteMeter = (id: string, name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
      },
      { apiName: this.apiName, ...config }
    );

  downloadAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileStreamResult>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attachments/${attachmentId}/download`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssetDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAssetStatus = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssetStatusDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/status`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto<string>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attachments/${attachmentId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttachments = (
    id: string,
    input: GetAttachmentsInput<string>,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, PagedResultDto<AttachmentDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attachments`,
        params: {
          term: input.term,
          title: input.title,
          contentType: input.contentType,
          fileName: input.fileName,
          fileUrl: input.fileUrl,
          folder: input.folder,
          ownerType: input.ownerType,
          ownerId: input.ownerId,
          isActive: input.isActive,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getAttribute = (id: string, name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttributeList = (id: string, input: GetAttributesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AttributeDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attributes`,
        params: {
          term: input.term,
          name: input.name,
          description: input.description,
          valueType: input.valueType,
          value: input.value,
          assetId: input.assetId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getDetails = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssetDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/details`,
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetAssetsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AssetDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          code: input.code,
          primaryID: input.primaryID,
          secondaryID: input.secondaryID,
          barcode: input.barcode,
          rfid: input.rfid,
          name: input.name,
          origCertNumber: input.origCertNumber,
          isFixed: input.isFixed,
          isActive: input.isActive,
          tag: input.tag,
          notes: input.notes,
          picture: input.picture,
          manufacturerId: input.manufacturerId,
          modelId: input.modelId,
          categoryId: input.categoryId,
          siteId: input.siteId,
          locationId: input.locationId,
          parentId: input.parentId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getMeter = (id: string, name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
      },
      { apiName: this.apiName, ...config }
    );

  getMeterList = (id: string, input: GetMetersInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MeterDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/meters`,
        params: {
          term: input.term,
          name: input.name,
          description: input.description,
          readingType: input.readingType,
          reading: input.reading,
          startReading: input.startReading,
          assetId: input.assetId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getPicture = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileStreamResult>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/picture`,
      },
      { apiName: this.apiName, ...config }
    );

  removeAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/attachments/${attachmentId}`,
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: AssetUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssetDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateAttachment = (
    id: string,
    attachmentId: string,
    input: AttachmentUpdateDto<string>,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, AttachmentDto<string>>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/attachments/${attachmentId}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateAttribute = (
    id: string,
    name: string,
    input: AttributeUpdateDto,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateMeter = (id: string, name: string, input: MeterUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/assets/${id}/meters/${name}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}
}
